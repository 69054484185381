import API, { graphqlOperation } from '@aws-amplify/api';
import { updateParts } from '../../../../graphql/mutations';
import ACTION_TYPES from '../../../actionTypes';
import { IPart } from '../../../Interfaces';
import { NotificationManager } from "react-notifications";
const onUpdatePartInit = () => {
    return {
        type: ACTION_TYPES.PART.UPDATE.INIT,
        payload: null,
    };
};

const onUpdatePartSuccess = (updatedPart: IPart) => {
    return {
        type: ACTION_TYPES.PART.UPDATE.SUCCESS,
        payload: updatedPart,
    };
};

const onUpdatePartFail = (errorMessage: string) => {
    //NotificationManager.error('Failure on Update Part');
    console.error('Failure on Update Part - ', errorMessage);
    return {
        type: ACTION_TYPES.PART.UPDATE.FAIL,
        payload: errorMessage,
    };
};

const onUpdatePartDone = () => {
    return {
        type: ACTION_TYPES.PART.UPDATE.DONE,
        payload: null,
    };
};
function countOccurrences(target: { name: string }, array: { name: string }[]): number {
    return array.reduce((count, item) => item.name === target.name ? count + 1 : count, 0);
}
const onUpdatePartAfterNewPlate = (partToBeUpdated, addedPlate, noOfPartInstance) => {

    return async (dispatch, getState) => {

        dispatch(onUpdatePartInit());

        try {
            const state = getState();
            const stringfiedAddedPlate = JSON.stringify({ 'name': addedPlate.id });
            const strigifiedPlates = partToBeUpdated.plates && partToBeUpdated.plates.length > 0 ? partToBeUpdated.plates : [];
            let parsedPlates = strigifiedPlates.map(plate => JSON.parse(plate));
            let occurrence = countOccurrences(addedPlate.id, parsedPlates);
            const actualOccurance = noOfPartInstance -occurrence
            const newPlatesArray = Array(actualOccurance).fill(stringfiedAddedPlate);
            const updatedStrigifiedPlates = strigifiedPlates.concat(newPlatesArray);
           
            
          //  const updatedStrigifiedPlates = strigifiedPlates.concat(stringfiedAddedPlate);
            const hasPlates = updatedStrigifiedPlates && updatedStrigifiedPlates.length > 0;

            partToBeUpdated.hasPlates = hasPlates;
            partToBeUpdated.plates = hasPlates ? updatedStrigifiedPlates : null;

            delete partToBeUpdated._deleted;
            delete partToBeUpdated._lastChangedAt;
            delete partToBeUpdated.createdAt;
            delete partToBeUpdated.updatedAt;
            partToBeUpdated.modifier = state.creator as string;
            partToBeUpdated.modified_at = new Date().toISOString();

            const variables = {
                input: partToBeUpdated,
            };
            const result = await API.graphql(graphqlOperation(updateParts, variables));
            const updatedPart = ((result as any)?.data?.updateParts) as IPart;
            dispatch(onUpdatePartSuccess(updatedPart));
        }
        catch (graphqlError) {
            const errorMessage = `Update part failed: ${Object((graphqlError as any)?.errors?.[0]?.message).toString()}`;
            dispatch(onUpdatePartFail(errorMessage));
        }

        dispatch(onUpdatePartDone());
    };
};

export default onUpdatePartAfterNewPlate;