import { IState } from "../../../Interfaces";

const onUnselectBuildReducer = (state: IState, action) => {
    const buildId = action.payload as string;

    const newState = { ...state } as IState;
    const index = newState.data.builds.selectedBuildIds.indexOf(buildId);
    const updatedSelectedBuilds = newState.data.builds.selectedBuilds.filter((build)=> build.id !== buildId);
    newState.data.builds.selectedBuildIds.splice(index,1);
    newState.data.builds.selectedBuilds=updatedSelectedBuilds;

    
  

    return newState;
};

export default onUnselectBuildReducer;