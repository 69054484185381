import React, { useEffect, useState } from "react";
import LoadingComponent from "../../../LoadingComponent/LoadingComponent";
import { Button } from "react-bootstrap";
import { DataGrid } from "@material-ui/data-grid";
import API, { graphqlOperation } from "@aws-amplify/api";
import { createRole, updateRole } from "../../../graphql/mutations";
import { NotificationManager } from "react-notifications";

const CreateRole = (props) => {
  const [roleSubmit, setRoleSubmit] = useState(false); // Validation state value
  const [showLoader, setShowLoader] = useState(false); // Loader visible state

  const [permissionGridRows, setPermissionGridRows] = useState([
    {
      id: 1,
      permission: false,
      feature: "Role Change",
      key: "roleChange",
    },
    {
      id: 2,
      permission: false,
      feature: "User Management",
      key: "userManagement",
    },
    {
      id: 3,
      permission: false,
      feature: "Advanced features",
      key: "advancedFeatures",
    },
  ]);

  const permissionGridColumns = [
    {
      field: "feature",
      headerName: "Feature",
      sortable: false,
      width: 300,
    },
    {
      field: "permission",
      headerName: "Permission",
      sortable: false,
      clickable: false,
      width: 200,
      renderCell: (params) => (
        <div className="grid-switch">
          <span>No</span>
          <label className="switch">
            <input
              type="checkbox"
              checked={params.row.permission}
              onChange={() => gridPermissionChange(params.row)}
            />
            <span className="slider"></span>
          </label>
          <span>Yes</span>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (props.editRoleData) {
      setRoleName(props.editRoleData.roleName);
      const updatedPermission = permissionGridRows;
      updatedPermission.map((dataItem) => {
        dataItem.permission = props.editRoleData.permissions[dataItem["key"]];
        return dataItem;
      });
      setPermissionGridRows((prevState) => {
        const updatedPermission = [...prevState];
        return updatedPermission;
      });
    }
  }, [props.editRoleData]);

  const gridPermissionChange = (data) => {
    const updatedPermission = permissionGridRows;
    updatedPermission.map((dataItem) => {
      if (dataItem.id === data.id) {
        dataItem.permission = !dataItem.permission;
      }
      return dataItem;
    });
    setPermissionGridRows((prevState) => {
      const updatedPermission = [...prevState];
      return updatedPermission;
    });
  };

  const handleRoleSave = async () => {
    setRoleSubmit(true);
    const roleInput = {
      roleName: roleName.replace(/ /g,''),
      id: roleName.replace(/ /g,''),
      permissions: {
        roleChange: permissionGridRows[0].permission,
        userManagement: permissionGridRows[1].permission,
        advancedFeatures: permissionGridRows[2].permission,
      },
    };

    // Save role mutation
    if (roleName !== "") {
      setShowLoader(true);
      try {
        let response;
        if (props.editRoleData) {
          roleInput["id"] = props.editRoleData?.id;
          roleInput["_version"] = props.editRoleData?._version;
          response = await API.graphql(
            graphqlOperation(updateRole, { input: roleInput })
          );
        } else {
          response = await API.graphql(
            graphqlOperation(createRole, { input: roleInput })
          );
        }
        if (response && props.editRoleData) {
          NotificationManager.success("Role updated successfuly");
        } else {
          NotificationManager.success("Role created successfuly");
        }
        setShowLoader(false);
        props.handleCancel();
      } catch (error) {
        NotificationManager.error("Failed");
        setShowLoader(false);
      }
    }
  };

  const handleRoleNameChange = (e) => {
    setRoleName(e.target.value);
  };

  // Input State values
  const [roleName, setRoleName] = useState("");

  return (
    <div className="create-role-container">
      <LoadingComponent visible={showLoader} />
      <div className="tab-bar create-user-header">
        <div className="header-section mb-5">
          <div
            className="backIcon"
            onClick={() => {
              props.handleCancel();
            }}
          ></div>
          <label className="heading">User Role</label>
        </div>
      </div>
      <div className="row user-data-section flex-row">
        <div className="col-md-6">
          <div className="role-name mb-5">
            <label className="w-100">Role Name*</label>
            <input
              className={
                !roleName && roleSubmit
                  ? "w-100 input-box required-error"
                  : "w-100 input-box"
              }
              type="text"
              value={roleName}
              onChange={(e) => {
                handleRoleNameChange(e);
              }}
            />
            {!roleName && roleSubmit && (
              <span className="mandate-error">Role name is required</span>
            )}
          </div>
          <div className="existing-features-list">
            <label className="w-100">Permissions</label>
            <div className="permission-grid role-permission-grid">
              <DataGrid
                rows={permissionGridRows}
                columns={permissionGridColumns}
                autoHeight
                autoPageSize
                disableSelectionOnClick
                disableColumnMenu
                className="list-view-grid"
                hideFooterPagination
              />
            </div>
          </div>
        </div>
        {/* Features table column */}
        <div className="col-md-5">
          {/* <label className="w-100">Feature List</label> */}
        </div>
      </div>
      <div className="user-action-btn d-flex">
        <Button
          className="btn-secondary btn-cancel"
          onClick={() => {
            props.handleCancel();
          }}
        >
          Cancel
        </Button>
        <Button
          className="btn-primary btn-save"
          onClick={() => {
            handleRoleSave();
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default CreateRole;
