import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DisplayPartCardView, DisplayPlateCardView, DisplayFolder, DisplayPartListView, DisplayPartOptions } from '../..';

import { IState } from '../../../Interfaces';
import React from 'react';
import DisplayDashboardPartCardView from './DisplayDashboardPartCardView';
import DisplayDashboardFolder from '../Common/DisplayDashboardFolder';
import { DataGrid } from '@material-ui/data-grid';
import { onUnselectPart } from '../../../Actions/Parts';


const Props = (state: IState, ownProps: any) => {

    const hasDisplayItems =
        Boolean(ownProps.displayFolders && ownProps.displayFolders.length > 0) ||
        Boolean(ownProps.displayParts && ownProps.displayParts.length > 0);

    const props = {
        hasDisplayItems: hasDisplayItems as boolean,
        isLoadingComplete: state.data.parts.isLoadingComplete as boolean,
        label: ownProps.label,
        displayFolders: ownProps.displayFolders,
        displayParts: ownProps.displayParts,
        showOptions: ownProps.showOptions,
        showItems: ownProps.showItems,
        onShowItems: ownProps.onShowItems,
        onHideItems: ownProps.onHideItems,
        isSelected: ownProps.isSelected,
        onUnselect: ownProps.onUnselect,
        onSelect: ownProps.onSelect,
        hasPlates: ownProps.hasPlates,
        hasBuilds: ownProps.hasBuilds,
        displayPlates: ownProps.displayPlates,
        showListView: state.data.info.showListView as boolean,
        searchText: state.data.parts.searchText as string,
        selectedPartIds: state.data.parts.selectedPartIds
    };

    return props;
};

const Actions = {
    onUnselectPart: onUnselectPart
};

class Component extends React.Component<any, any> {
    
    handleCheckBoxUnSelect = (id) => {
        this.props.onUnselectPart(id);
    }
    public render() {
        const listViewColumns = [
            {
              field: 'type',
              headerName: 'Type',
              width: 150,
              sortable: false,
              renderCell: (params) => {
                      return <img className='icon part' alt='part' />
              }
            },
            {
              field: 'name',
              headerName: 'Name',
              width: 400,
              sortable: true,
            },
            {
              field: 'createdDate',
              headerName: 'Imported Date',
              width: 250,
              sortable: true,
            },
            {
              field: 'creator',
              headerName: 'Owner',
              width: 350,
              sortable: false,
            },
            {
              field: 'plates',
              headerName: 'No of Plates',
              width: 150,
              sortable: false,
              renderCell: (params) => {

                      return ( params.row.plates ? params.row.plates.length : 0 )
              }
            },
            {
              field: 'folder',
              headerName: 'Folder',
              width: 250,
              sortable: false,
            },
            {
              field: 'actions',
              headerName: 'Actions',
              width: 200,
              sortable: false,
              renderCell: (params) => (
                <DisplayPartOptions displayPart={params.row} />
              )
            },
          ];
        return (
            <>
                <>
                    {
                        this.props.hasDisplayItems ?
                            (
                                <>
                                    <dl className={`${this.props.showListView ? "grid-view" : ""}`}>

                                        <>
                                            {
                                                this.props.showItems ?
                                                    (
                                                        <dd>
                                                            {
                                                                <>
                                                                    {/* {
                                                                        this.props.displayFolders.map((df, i) => (
                                                                            <DisplayFolder
                                                                                key={i}
                                                                                displayFolder={df}
                                                                                showParts={true}
                                                                                showPlates={false}
                                                                                showBuilds={false}
                                                                            />
                                                                        ))
                                                                    } */}
                                                                </>
                                                            }
                                                        </dd>
                                                    )
                                                    :
                                                    (
                                                        null
                                                    )
                                            }
                                        </>
                                        <>
                                            {
                                                <dt>
                                                    <div className='options left-justify'>
                                                        <div
                                                            className={`${this.props.showItems ? 'expand' : 'collapse'} option`}
                                                        >
                                                            <img
                                                                className={`icon ${this.props.showItems ? 'down-arrow' : 'right-arrow'}`}
                                                                alt=''
                                                                onClick={() =>
                                                                    this.props.showItems
                                                                        ? this.props.onHideItems()
                                                                        : this.props.onShowItems()
                                                                }
                                                            />
                                                            <label>{this.props.label}</label>
                                                            <span>
                                                                {/* {` listing ${this.props.displayParts.length} parts, listing ${this.props.displayFolders.length} folders`} */}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* <DisplayPartsOptions showOptions={this.props.showOptions} /> */}
                                                </dt>
                                            }
                                        </>
                                        <>
                                            {
                                                this.props.showItems ?
                                                    (
                                                        <dd className="dashboard-selected-parts">
                                                            {
                                                                <>
                                                                    {
                                                                        this.props.showListView ?
                                                                            (
                                                                                <>

                                                                                {/* <DataGrid 
                                                                                    columns={listViewColumns}
                                                                                    rows={this.props.displayParts} 
                                                                                    autoHeight
                                                                                    autoPageSize
                                                                                    disableSelectionOnClick
                                                                                    disableColumnMenu                                    
                                                                                    className="list-view-grid"
                                                                                    hideFooterPagination
                                                                                    checkboxSelection
                                                                                    onSelectionModelChange={(id) => { this.handleCheckBoxSelect(id) }}
                                                                                    getRowClassName={() => 'enable-checkbox-row'}
                                                                                    selectionModel={[...this.props.selectedPartIds]}
                                                                                /> */}
                                                                
                                                                                    <div className='list-view-selected-parts display-parts-list'>
                                                                                        <div className='display-list-row'>
                                                                                            <div className='display-list-header'>
                                                                                            </div>
                                                                                            <div className='display-list-header'>
                                                                                                <label>Type</label>
                                                                                            </div>
                                                                                            <div className='display-list-header'>
                                                                                                <label>Name</label>
                                                                                            </div>
                                                                                            <div className='display-list-header'>
                                                                                                <label>Imported Date</label>
                                                                                            </div>
                                                                                            <div className='display-list-header'>
                                                                                                <label>Owner</label>
                                                                                            </div>
                                                                                            <div className='display-list-header'>
                                                                                                <label>No of Plates</label>
                                                                                            </div>
                                                                                            <div className='display-list-header'>
                                                                                                <label>Folder</label>
                                                                                            </div>
                                                                                            <div className='display-list-header'>
                                                                                                <label>Actions</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {this.props.displayParts.map((item, index) => {
                                                                                        return (
                                                                                            <div className="selected-parts-row" key={item.id+index}>
                                                                                        <div className="selected-part-item" onClick={(e) => { this.handleCheckBoxUnSelect(item.id); e.stopPropagation(); }}>
                                                                                            <img src="/img/mui-checked.png" className="mui-checked-icon" alt="mui-checked-icon" />
                                                                                        </div>
                                                                                        <div  className="selected-part-item">
                                                                                            <div className='options left-justify'>
                                                                                                <div className='part option'>
                                                                                                    <img className='icon part' alt='part' />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div  className="selected-part-item">
                                                                                            <label>{item.name}</label>
                                                                                        </div>
                                                                                        <div  className="selected-part-item">
                                                                                            <label>{item.createdDate}</label>
                                                                                        </div>
                                                                                        <div  className="selected-part-item">
                                                                                            <label>{item.creator}</label>
                                                                                        </div>
                                                                                        <div  className="selected-part-item">
                                                                                            <label>{item.plates ? item.plates.length : 0}</label>
                                                                                            
                                                                                        </div>
                                                                                        <div  className="selected-part-item">
                                                                                            <label>{item.folder}</label>
                                                                                        </div>
                                                                                        <div  className="selected-part-item">
                                                                                            <DisplayPartOptions {...{ displayPart: item }} />
                                                                                        </div>
                                                                                    </div>
                                                                                        )
                                                                                    })}
                                                                                </>
                                                                            )
                                                                            :
                                                                            (
                                                                                null
                                                                            )
                                                                    }
                                                                    {console.log( this.props.displayParts)}

                                                                    {
                                                                        !this.props.showListView && this.props.displayParts.map((dp, i) => (<DisplayDashboardPartCardView key={i} displayPart={dp} isSelected={this.props.isSelected} />))
                                                                    }
                                                                </>
                                                            }
                                                        </dd>
                                                    )
                                                    :
                                                    (
                                                        null
                                                    )
                                            }
                                        </>
                                    </dl>
                                </>
                            )
                            :
                            (
                                null
                            )
                    }
                </>
            </>
        );
    }
}

const DisplayDashboardParts = withRouter(connect(Props, Actions)(Component));

export default DisplayDashboardParts;
