import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { Storage } from "aws-amplify";
import { IState } from "../Beta/Interfaces";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import LoadingComponent from "../LoadingComponent/LoadingComponent"; 

const Props = (state: IState, ownProps: any) => {
	const props = {
		// loadedBuild: state.data.builds.loadedBuilds.filter(e => e.id === ownProps.displayBuild.id)[0],
    // displayBuilds: ownProps.displayBuilds,
	};

	return props;
};
const Actions = {

};
const  SideMenuComponentMethod  = (props:any) => {
    const {
        setPercentage,
        setTotalLayer,
        handleClose,
        secondsToDhms,
        displayPrintFeedbackModal,
        printerAction,
        selectedJob,
        printStatus,
        printOwner,
        buildName,
        isConfigDisabled,
        gCodeID,
        printID,
        layersCompleted,
        imageTypes,
        displayPrintFeedbackButton,
        selectedBuild,
        activeBundle,
      } = props
      
      /* section: ActualImagesComponent - end */
      /* section: components - end */
      
      const [percentage, SetOwnPercentage] = React.useState<Number>(0)
      const [totalLayer, SetOwnTotalLayer] = React.useState<any>('..')
      const [pasteConsum,setPasteConsum] = useState(undefined)
      const [totalTime, setTotalTime] = useState("");
      const [loading, setLoading] = useState<boolean>(false);
      useEffect(()=> {
          console.log(layersCompleted, imageTypes, "imageTypes")
          if(typeof layersCompleted.endLayer === 'number' && typeof totalLayer === 'number' ){
              SetOwnPercentage( Number(layersCompleted.endLayer) / Number(totalLayer) * 100)
          }
          SetOwnTotalLayer(imageTypes?.BuildVoids
            ? Object.keys(imageTypes?.BuildVoids).length
      : 0)
}, [layersCompleted, imageTypes])

useEffect(() => {

  if(activeBundle){
    loadResult(activeBundle)
  }
}, [activeBundle]);

const loadResult = async (rBuild) => {
  setLoading(true)
  console.log(activeBundle, "rb")
  let key = `${activeBundle}result.json`;
  if (key.indexOf("public/") === 0) {
    key = key.substr(7);
  }

  let resultJson = null;
  try {
    let result = await Storage.get(key, {
      download: true,
    });

    resultJson = await new Response((result as any).Body).json();
    console.log("loadResult~~~resultJson", (resultJson as any)?.Times['All Print']?.extruded);
    setPasteConsum((resultJson as any)?.Times['All Print']?.extruded + (resultJson as any)?.Times['All Print']?.purged);
    setTotalTime((resultJson as any)?.Times['All Print']?.['Total'].time);
    console.log("All Print", (resultJson as any)?.Times['All Print']?.['Total'].time);
    await calculateTimes(resultJson);
    setLoading(false)
  } catch (e) {
    console.error(e);
    setLoading(false)
  }
};

const calculateTimes = async (bundle) => {
  console.log(bundle, "rb")
  let layers = new Array(bundle.LayersCount);

  let startPoint = {};
  startPoint["layer"] = 1;
  startPoint["stage"] = "PRG";
  startPoint["currentStartTime"] = Date.now();
  let timeSpent = 0;
  let pasteSpent = 0.0;

  for (let i = 0; i < startPoint["layer"]; i++) {
    pasteSpent += layers[i]["extruded"] + layers[i]["purged"];
  }
};

    return (
        <>
         <LoadingComponent visible={loading} />
         <div className="bp-l">
          <div className="bp-l-h pl-3">
            <div className="back-icon" onClick={handleClose}></div>
            <div className="back-btn-heading" title={selectedBuild.name}>
              {selectedBuild.name}
            </div>
          </div>
          {selectedJob !== 'Running' &&
            (<div className="bp-l-b height-auto">
              <div className="bp-l-b-h">
                <label className="p-16 c-666">Print details</label>
                <div className="bp-l-b-br"></div>
              </div>
              <div className="bp-l-b-pd">
                <div className="bp-l-b-e">
                  <label className="p-14">Printer Name</label>
                  <label className="p-14">
                    {selectedBuild.id ? selectedBuild.id : ''}
                  </label>
                </div>
                <div className="bp-l-b-e">
                  <label className="p-14">Print Owner</label>
                  <label className="p-14">
                    {printOwner ? printOwner : ''}
                  </label>
                </div>
                <div className="bp-l-b-e">
                  <label className="p-14">Build Name</label>
                  <label className="p-14">
                    {buildName}
                  </label>
                </div>

                <div className="bp-l-b-e">
                  <label className="p-14">Total Time Taken</label>
                  <label className="p-14">
                  {totalTime ? totalTime + 'hrs' : ''}
                </label>
                </div>
                <div className="bp-l-b-e">
                  <label className="p-14">Total Paste Consumption</label>
                  <label className="p-14">
                    {pasteConsum ? parseInt(pasteConsum).toFixed(2) + " ml" : ''}
                  </label>
                </div>
                {!isConfigDisabled &&
                  <>
                    <div className="bp-l-b-e w-100">
                      <label className="p-14">Print ID</label>
                      <label className="p-14">
                      {printID ? printID : ''}
                      </label>
                    </div>
                    <div className="bp-l-b-e w-100">
                      <label className="p-14">GCode UUID</label>
                      <label className="p-14">
                        {gCodeID ? gCodeID : ''}
                      </label>
                    </div>
                  </>}
              </div>
            </div>)}
          {selectedJob === 'Running' &&
            (<div className="bp-l-b height-auto">
              <div className="bp-l-b-h">
                <label className="p-16 c-666">Printer current status</label>
                <div className="bp-l-b-br"></div>
              </div>
              <div className="bp-l-b-pd">
                <div className="bp-l-b-e">
                  <label className="p-14">Printer Status</label>
                  <label className="p-14">
                    {selectedJob ? selectedJob : ''}
                  </label>
                </div>
                <div className="bp-l-b-e">
                  <label className="p-14">Print Job Status</label>
                  <label className="p-14">
                    {(totalLayer == layersCompleted.endLayer) ? 'Completed' : 'Running'}
                  {/* {printStatus ? printStatus : ''} */}
                  </label>
                </div>
                <div className="bp-l-b-e w-100">
                  <label className="p-14">Build Name</label>
                  <label className="p-14">
                    {buildName}
                  </label>
                </div>

                <div className="bp-l-b-e">
                  <label className="p-14">Layers Completed</label>
                  <label className="p-14">
                    {layersCompleted.endLayer ? layersCompleted.endLayer : ''}
                  </label>
                </div>
                <div className="bp-l-b-e">
                  <label className="p-14">Total Layers</label>
                  <label className="p-14">{totalLayer}</label>
                </div>

                <div className="bp-l-b-e">
                  <label className="p-14">Printer Name</label>
                  <label className="p-14">
                    {selectedBuild.id ? selectedBuild.id : ''}
                  </label>
                </div>
                <div className="bp-l-b-e">
                  <label className="p-14">Print Owner</label>
                  <label className="p-14">
                    {printOwner ? printOwner : ''}
                  </label>
                </div>
              {(totalLayer != layersCompleted.endLayer) ?
                (
                  <>
                    <div className="bp-l-b-e">
                      <label className="p-14">Remaining Print Time</label>
                      <label className="p-14">
                        {selectedBuild.remainingPrintTime ? selectedBuild.remainingPrintTime === "0" ? "00:00:00 hrs" : secondsToDhms(selectedBuild.remainingPrintTime) : ''}
                      </label>
                    </div>
                    <div className="bp-l-b-e">
                      <label className="p-14">Remaining Paste needed for Print</label>
                      <label className="p-14">
                        {selectedBuild.remainingPasteConsumption ? `${selectedBuild.remainingPasteConsumption} mL` : ''}
                      </label>
                    </div>

                    <div className="bp-l-b-e">
                      <label className="p-14">Percent Complete</label>
                      <label className="p-14">
                        {layersCompleted.endLayer && totalLayer ? `${(Number(layersCompleted.endLayer) / Number(totalLayer) * 100).toFixed(2)} %` : 'N/A'}
                      </label>
                    </div>
                  </>) :
                (null)}

                <div className="bp-l-b-e w-100">
                  <label className="p-14">Print ID</label>
                  <label className="p-14">
                  {printID ? printID : ''}
                  </label>
                </div>
                <div className="bp-l-b-e w-100">
                  <label className="p-14">GCode UUID</label>
                  <label className="p-14">
                  {gCodeID ? gCodeID : ''}
                  </label>
                </div>
              </div>
            </div>)
          }
          {(displayPrintFeedbackButton && (selectedJob === "Stopped" || selectedJob === "Running")) ?
            (<Button
              disabled={props.isFeedbackDisabled}
              className="btn-primary feedback-btn mt-3"
              onClick={displayPrintFeedbackModal}
              autoFocus
            >
              Print Feedback
            </Button>) : null
          }
          {(selectedJob === "Running") ?
            (<Button
              className="btn btn-secondary feedback-btn mt-4"
              onClick={() => printerAction(selectedBuild.GrafanaBundle, 'stop')}
              autoFocus
            >
              <img src="/pause-btn.svg" alt="Pause Icon" className="pr-2" /> Pause Printer
            </Button>) : null
          }
        </div>
        </>
    )
}

const SideMenuComponent = withRouter(connect(Props, Actions)(SideMenuComponentMethod));

export default SideMenuComponent;