import { Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { DataGrid } from "@material-ui/data-grid";
import API, { graphqlOperation } from "@aws-amplify/api";
import { createUser, updateUser } from "../../../graphql/mutations";
import { NotificationManager } from "react-notifications";
import LoadingComponent from "../../../LoadingComponent/LoadingComponent";
import { listRoles } from "../../../graphql/queries";

const CreateUser = (props) => {
  const [client, setClient] = useState("cus2");
  const [userSubmit, setUserSubmit] = useState(false); // Validation state value
  const [showLoader, setShowLoader] = useState(false); // Loader visible state
  const isMountedRef = useRef(true);

  // Input State values
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [roles, setRoles] = useState("");
  const [phone, setPhone] = useState();
  const [editUserId, setEditUserId] = useState("");
  const [status, setStatus] = useState("ACTIVE");
  const [permission, setPermission] = useState("default");
  const [roleOptions, setRoleOptions] = useState<any>([]);

  const permissionGridColumns = [
    {
      field: "feature",
      headerName: "Feature",
      sortable: false,
      width: 350,
    },
    {
      field: "permission",
      headerName: "Permission",
      sortable: false,
      width: 350,
      renderCell: (params) => (
        <Typography className="table-date-format">{"Not available"}</Typography>
      ),
    },
  ];

  useEffect(() => {
    if (props.editUserData) {
      setFirstName(props.editUserData.firstName);
      setLastName(props.editUserData.lastName);
      setEmail(props.editUserData.email);
      setPhone(props.editUserData.phoneNo);
      setRoles(props.editUserData.roleId);
      setStatus(props.editUserData.status);
      setEditUserId(props.editUserData.id);
    }
  }, [props.editUserData]);

  useEffect(() => {
    getRoleLists();
  }, []);

  const getRoleLists = async () => {
    try {
      const response = (await API.graphql(graphqlOperation(listRoles))) as any;
      if (isMountedRef.current && response?.data && response.data) {
        const roleItems: any = [];
        if (response.data?.listRoles?.items.length) {
          response.data?.listRoles?.items.forEach((roleItem) => {
            // if (userRole === "SuperAdmin"  && roleItem.roleName === "SuperAdmin") {
            //   roleItems.push(roleItem);
            // }
            // else if (userRole !== "SuperAdmin" && roleItem.roleName === "SuperAdmin") {
            //   return;
            // }
            // else {
            //   roleItems.push(roleItem);
            // }
            if (roleItem.roleName !== "SuperAdmin") {
              roleItems.push(roleItem);
            }
          });
        }
        setRoleOptions(roleItems || []);
      }
    } catch (error) {
      NotificationManager.error("Error");
    }
  };

  // Input event handler methods

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePhonenoChange = (e) => {
    setPhone(e.target.value);
  };

  const handleClientChange = (event) => {
    setClient(event.target.value);
  };

  const handleRoleChange = (event) => {
    setRoles(event.target.value);
  };

  const handlePermissionChange = (event) => {
    setPermission(event.target.value);
  };

  const validateEmail = (email) => {
    const regux = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return regux.test(String(email).toLowerCase());
  };
  

  const handleAddUser = async () => {
    setUserSubmit(true);
    let userInput = {
      id: email,
      email: email,
      firstName: firstName,
      lastName: lastName,
      phoneNo: phone,
      roleId: roles,
      status: status,
    };
    try {
      if (firstName && lastName && email && roles && validateEmail(email) && phone) {
        setShowLoader(true);
        let response;
        if (editUserId) {
          userInput["_version"] = props.editUserData._version;
          response = await API.graphql(
            graphqlOperation(updateUser, { input: userInput })
          );
        } else {
          response = await API.graphql(
            graphqlOperation(createUser, { input: userInput })
          );
        }
        if (response) {
          setShowLoader(false);
          NotificationManager.success(
            editUserId
              ? "User updated Successfully"
              : "User created Successfully"
          );
          props.handleCancel();
        }
      } else {
        setShowLoader(false);
        NotificationManager.error("Mandate fields are required");
      }
    } catch (error: any) {
      // if (!error?.data?.createUser) {
      //   NotificationManager.error("User already exists");
      //   setShowLoader(false);
      // }
      // else {
      //   NotificationManager.error("Error");
      //   setShowLoader(false);
      // }
      NotificationManager.error("Error");
      setShowLoader(false);
    }
  };

  return (
    <div className="create-user-container">
      <LoadingComponent visible={showLoader} />
      <div className="tab-bar create-user-header">
        <div className="header-section mb-5">
          <div
            className="backIcon"
            onClick={() => {
              props.handleCancel();
              // setEnableCreateUser(false);
              // setEditUserData(null);
            }}
          ></div>
          <label className="heading">
            {props.editUserData ? "Update User" : "Create User"}
          </label>
        </div>
      </div>
      <div className="row">
        <div className="col-md-7 row">
          <div className="col-md-6 pr-5">
            <h6>Profile</h6>
            {/* Profile Pic Upload Section */}
            {/* <div className="profile-upload">
              <h6>Profile Photo</h6>
              <div className="upload-section">
                <div className="drop-zone mb-3">
                  <input
                    type="file"
                    // onChange={(event) => onAddPartFileChange(event)}
                    multiple={false}
                  />
                  <img src="/upload-icon.svg" alt="upload-file" />
                  <p className="upload-text">
                    <span className="clickToUpload">Click to Upload</span> or
                    Drag and Drop.
                  </p>
                </div>
              </div>
            </div> */}
            <div className="user-data-section">
              <div className="first-name">
                <label className="w-100">First Name*</label>
                <input
                  className={
                    !firstName && userSubmit
                      ? "w-100 input-box required-error"
                      : "w-100 input-box"
                  }
                  type="text"
                  onChange={handleFirstNameChange}
                  value={firstName}
                />
                {!firstName && userSubmit && (
                  <span className="mandate-error">First name is required</span>
                )}
              </div>
              <div className="last-name">
                <label className="w-100">Last Name*</label>
                <input
                  className={
                    !lastName && userSubmit
                      ? "w-100 input-box required-error"
                      : "w-100 input-box"
                  }
                  type="text"
                  onChange={handleLastNameChange}
                  value={lastName}
                />
                {!lastName && userSubmit && (
                  <span className="mandate-error">Last name is required</span>
                )}
              </div>
              <div className={editUserId ? "disabled email-id" : "" }>
                <label className="w-100">Email ID*</label>
                <input
                  className={
                    !email && userSubmit
                      ? "w-100 input-box required-error"
                      : "w-100 input-box"
                  }
                  type="text"
                  onChange={handleEmailChange}
                  value={email}
                  disabled={editUserId !== ""}
                />
                {!email && userSubmit && (
                  <span className="mandate-error">Email ID is required</span>
                )}
                {email && !validateEmail(email) && userSubmit && (
                  <span className="mandate-error">Invalid Email ID</span>
                )}
              </div>
              <div className="email-id">
                <label className="w-100">Phone No*</label>
                <input
                  className={
                    !phone && userSubmit
                      ? "w-100 input-box required-error"
                      : "w-100 input-box"
                  }
                  type="number"
                  onChange={handlePhonenoChange}
                  value={phone || ""}
                />
                {!phone && userSubmit && (
                  <span className="mandate-error">Phone number is required</span>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6 pr-5">
            <h6>Authorisation Details</h6>
            {/* Client dropdown section */}
            {/* <div className="client-dropdown-section">
              <label className="client-head">Client</label>
              <div className="client-dropdown">
                <select
                  value={client}
                  onChange={handleClientChange}
                  className="w-70"
                >
                  <option value={"cus1"}>X Customer</option>
                  <option value={"cus2"}>Y Customer</option>
                  <option value={"cus3"}>Z Customer</option>
                </select>
              </div>
            </div> */}
            <div className="role-dropdown-section mt-4">
              <label className="role-head">Role*</label>
              <div className="role-dropdown">
                <button
                  className={
                    !roles && userSubmit
                      ? "btn btn-secondary roles-list-dropdown select-box required-error"
                      : "btn btn-secondary roles-list-dropdown"
                  }
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {" "}
                  {roles ? roles : "Select Role"}
                  <span
                    className="btn btn-secondary dropdown-toggle dropdown-toggle-split"
                    aria-haspopup="true"
                    aria-expanded="false"
                  ></span>
                </button>

                <div className="dropdown-menu">
                  {roleOptions.length ? (
                    roleOptions.map((roleItem, idx) => (
                      <div
                        key={idx}
                        id={roleItem.id}
                        className="dropdown-item"
                        onClick={() => {
                          setRoles(roleItem.id);
                        }}
                      >
                        <label>{roleItem.roleName}</label>
                      </div>
                    ))
                  ) : (
                    <div
                      style={{
                        height: "150px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                      className="dropdown-item"
                    >
                      <img width="50px" src="/img/loader.svg" alt="loading" />
                    </div>
                  )}
                </div>
                {/* <select
                  value={roles}
                  onChange={handleRoleChange}
                  className={
                    !roles && userSubmit
                      ? "w-70 select-box required-error"
                      : "w-70"
                  }
                >
                  <option value={""}>--Select role--</option>
                  {props.rolesList.map((item) => {
                    return (
                      <option value={item.id}>{item.roleName}</option>
                    )
                  })}
                </select> */}
              </div>
              {!roles && userSubmit && (
                <span className="mandate-error">Role is required</span>
              )}
            </div>
            <div className="status-section mt-3">
              <label className="role-head">Status</label>
              <div className="status-btn tab-bar">
                <div className="options left-justify">
                  <div
                    className={
                      status === "ACTIVE"
                        ? "active-btn tab active"
                        : "active-btn tab"
                    }
                    onClick={() => {
                      setStatus("ACTIVE");
                    }}
                  >
                    <label className="active-btn tab">Active</label>
                  </div>
                  <div
                    className={
                      status === "INACTIVE"
                        ? " inActive-btn tab active"
                        : "inActive-btn tab"
                    }
                    onClick={() => {
                      setStatus("INACTIVE");
                    }}
                  >
                    <label>InActive</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-md-6">
          <h6>Manage Permission</h6>
          <div className="permission-dropdown-section mt-4">
            <label className="role-head">Permission</label>
            <div className="permission-dropdown">
              <select
                value={permission}
                onChange={handlePermissionChange}
                className="w-40"
              >
                <option value={"default"}>Use default</option>
              </select>
            </div>
          </div>
          <div className="mt-5">
            <DataGrid
              rows={[
                {
                  id: 1,
                  permission: "true",
                  feature: "Upload part",
                },
              ]}
              columns={permissionGridColumns}
              autoHeight
              autoPageSize
              disableSelectionOnClick
              disableColumnMenu
              className="list-view-grid"
              hideFooterPagination
            />
          </div>
        </div> */}
      </div>
      <div className="user-action-btn d-flex">
        <Button
          className="btn-secondary btn-cancel"
          onClick={() => {
            props.handleCancel();
          }}
        >
          Cancel
        </Button>
        <Button
          className="btn-primary btn-save"
          onClick={() => {
            handleAddUser();
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default CreateUser;
