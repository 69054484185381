import { IState } from "../../../Interfaces";

const onEnableSentToPrinterReducer = (state: IState, action) => {
    const details = action.payload as any;

    const newState = { ...state } as IState;
    newState.data.info.sendToPrinter = {
        isEnabled: details.isEnabled,
        selectedGrafanaBundle:details.selectedGrafanaBundle
    }

    return newState;
};

export default onEnableSentToPrinterReducer;