import { API } from "aws-amplify";
import { json } from "node:stream/consumers";
import React, { useState } from "react";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ProgressBar from "react-bootstrap/ProgressBar";
import LoadingComponent from "../LoadingComponent/LoadingComponent";

interface Tool {
  ToolId: string;
  ToolName: string;
  TLM: string;
  TLR: string;
  TLU: string;
  Status:string
}
function getToolName(toolNumber) {
  switch (toolNumber) {
    case 1:
      return "Chip Fan";
    case 3:
      return '3/8" end mill';
    case 5:
      return '1/4" end mill';
    case 7:
      return '1/16" end mill';
    case 9:
      return '0.020" ball end mill';
    case 11:
      return '0.020" end mill';
    case 13:
      return '1/8" end mill';
    case 15:
      return "Reference tool";
    case 17:
      return '0.006" ball end mill';
    case 19:
      return '0.010" ball end mill';
    default:
      return "-";
  }
}
function MVPToolsDetailsModal(props: any) {
  const { showModal, hideModal, printerId, selectedPrinterTools } = props; //
  const [toolsDetails, setToolsDetails] = useState([] as any);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        let requestData = {
          body: props.primaryInfoToFetchToolsDetails
        };
        const response = await API.post("lavaRestApi", "/getToolState", requestData);
        console.log("👊 ~ fetchData ~ response:", response)

        if (response[0]?.statusCode !== 200) {
          setHasError(true)
          throw new Error('Network response was not ok');
        }
        const responseJSON = response[0].body as any
        if (responseJSON.length === 0) {
          console.log("The array is empty");
          setHasError(true)
        }
        else {
          setHasError(false)
          setToolsDetails(responseJSON);
          setIsLoading(false);
        }

      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
        setHasError(true)
      }
    };
    fetchData();
    return () => {
      setToolsDetails([]);
    };
  }, [props.primaryInfoToFetchToolsDetails]);

  return (

    <Modal centered show={showModal} onHide={hideModal}>
      <Modal.Header
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Modal.Title>Print status</Modal.Title>
      </Modal.Header>

      <Modal.Body
        style={{ display: "flex", flexDirection: "column",minHeight :'600px' }}
        className="modal-body"
      >
        {(selectedPrinterTools !== "") && (
          <>
            <h3 className="printer-name">
              <label>Printer name</label>
              <label> {printerId} </label>
            </h3>
            {(!isLoading && toolsDetails?.length !== 0) && (<h2 className="configuration-heading"> Tool configuration</h2>)}

          </>
        )}
        <LoadingComponent visible={
					isLoading
				} />
        {!isLoading && ((toolsDetails?.length !== 0) ? (
          <ul className="printer-tool-configuration-list">
            <li
              style={{
                background: "none",
                marginBottom: "0px",
                paddingBottom: "0px",
              }}
            >
              <div className="list-values heading">
                <label>Tool#</label>
                <label>Used Tool Name</label>
                <label>Health Status</label>
              </div>
            </li>
            {toolsDetails.map((tool: Tool, idx: number) => {          
              let toolListRemining =          
              ((parseFloat(tool.TLR) / parseFloat(tool.TLM)) * 100);
              let toolListUsed = parseFloat((100 - toolListRemining).toFixed(2))
              const toolNotInstalled = tool.Status === "Not installed" ? true : false
              const popover = (
                <Popover id="popover-basic">
                  <Popover.Content>
                    <div className="tool-tip">
                      <div>
                        <label>Used</label>
                        <label>{parseFloat(tool.TLU)}</label>
                      </div>
                      <div>
                        <label>Remaining</label>
                        <label>{parseFloat(tool.TLR)}</label>
                      </div>
                      <div style={{marginLeft:'10px'}}>
                        <label>Total</label>
                        <label>{parseFloat(tool.TLM)}</label>
                      </div>
                    </div>
                  </Popover.Content>
                </Popover>
              );

              return (
                <li key={idx}>
                  <div className="list-values">
                    <label>{tool.ToolId.substring(1)}</label>
                    <label>
                      {getToolName(parseInt(tool.ToolId.substring(1)))}
                    </label>
                    {toolNotInstalled &&(<label className="text-danger">Not Installed</label>)}
                    {!toolNotInstalled &&(   <label>
                      Remaining{" "}
                      <span
                        style={
                          toolListUsed <= 25
                            ? { color: "#198754" }
                            : toolListUsed <= 75
                              ? { color: "#fd7e14" }
                              : { color: "#dc3545" }
                        }
                      >
                        {toolListRemining.toFixed(2) + "%"}
                      </span>
                    </label>)}
                 
                  </div>
                  <div>
                    {!toolNotInstalled &&(
                    <OverlayTrigger placement="top" overlay={popover}>
                      <ProgressBar
                        variant={
                          toolListUsed <= 25
                            ? "success"
                            : toolListUsed <= 75
                              ? "warning"
                              : "danger"
                        }
                        now={toolListUsed}
                        label={`${toolListUsed}%`}
                      />
                    </OverlayTrigger>)}
                  </div>
                </li>
              );
            })}
          </ul>
        ) : (
         <></>
        ))}
        {
          (!isLoading && toolsDetails?.length === 0 && hasError) && (<h5 style={{ textAlign: "center", margin: "50px" }}>
          Tool configuration data isn’t available right now.
        </h5>)
        }
       {
        (!isLoading && (toolsDetails?.length !== 0)) && <p className="notes"><sup>*</sup> Hover over the bars to see the used and remaining lifespan.</p>
       }
      
      </Modal.Body>
      <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
      
        <Button variant="secondary" onClick={hideModal}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MVPToolsDetailsModal;
