import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import { IState, INewBuildModalStatus } from "../Interfaces";
import {
	onNewBuildCloseDialog,
	onNewBuildNameChange,
	onNewBuild,
	onEnableSendToPrinter
} from "../Actions/Builds";
import { API, graphqlOperation } from "aws-amplify";
import { buildSearchByCreatedAt, getPrinter, listBuildSearches } from "../../graphql/queries";
import LoadingComponent from "../../LoadingComponent/LoadingComponent";

const Props = (state: IState, ownProps: any) => {
	const newBuildModalStatus = state.data.modals
		.newBuildModalStatus as INewBuildModalStatus;

	const props = {
		plateName: state.data.plates.highlightedPlateId ? state.data.plates.highlightedPlateId : state.data.search.highlightedPlateData.id  as string,
		isModalOpen: newBuildModalStatus.isOpen,
		inputText: newBuildModalStatus.inputText,
		hasError: newBuildModalStatus.hasError,
		errorMessage: newBuildModalStatus.errorMessage,
		isValid: newBuildModalStatus.isValid,
		isSendToPrinterChecked :state.data.info.sendToPrinter.isEnabled,
		plate:state.data.plates.loadedPlates.find((el)=> el.id === state.data.plates.highlightedPlateId ? state.data.plates.highlightedPlateId : state.data.search.highlightedPlateData.id  as string)
	};
	return props;
};

const Actions = {
	onNewBuildCloseDialog: onNewBuildCloseDialog,
	onNewBuildNameChange: onNewBuildNameChange,
	onNewBuild: onNewBuild,
	onEnableSendToPrinter: onEnableSendToPrinter
};

class Component extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = {
			plateName: this.props.plateName,
			sendToPrinter:{
				isEnabled:false as boolean,
                selectedGrafanaBundle:'' as string
			},
			isPrinterAvailable :null as any,
			isLoading:false
		};
	}
	async componentDidMount() {
		// const result = (await API.graphql(
		// 	graphqlOperation(listBuildSearches)
		// )) as any;
		// const buildSearchResults = (result as any)?.data?.listBuildSearches?.items;
		// this.setState({ buildSearchResults: buildSearchResults });
		this.setState({ plateName: this.props.plateName + '__Build' });	
	}
	
	async componentDidUpdate(prevProps) {
		if (this.props.plateName !== prevProps.plateName) {
			this.setState({ plateName: this.props.plateName+ '__Build' });	
		}
	}


	private onNewBuildNameChange(event) {
		const value = event.target.value.split(/[^a-zA-Z0-9._-]/).join("")	
			this.setState({ plateName: value, isAvailable: true });
			this.props.onNewBuildNameChange(this.state.plateName);
	}

	async onBeginSlicing (){
		
		await this.props.onNewBuildNameChange(this.state.plateName)
		console.log("🚀 ~ file: NewBuild.tsx:52 ~ Component ~ onBeginSlicing ~ this.state.plateName:", this.state.plateName)
		await this.props.onNewBuild(this.props.history)
	}
	async onSendToPrinterChecked() {
		if (this.state.sendToPrinter.isEnabled) {
			try {
				this.setState({ isLoading: true })
				const getSelectedPrinter = await API.graphql(
					graphqlOperation(getPrinter, {
						id: JSON.parse(this.props.plate.machineConfig)?.resultJson?.id,
					})
				);
				const selectedPrinter = (getSelectedPrinter as any).data.getPrinter;
				if (selectedPrinter !== null) {
					this.setState((prevState) => ({
						sendToPrinter: {
							isEnabled: prevState.sendToPrinter.isEnabled as boolean,
							selectedGrafanaBundle: selectedPrinter.GrafanaBundle
						}
					}), () => {
						this.props.onEnableSendToPrinter(this.state.sendToPrinter)
					});
				}
				this.setState({ isLoading: false })
				this.setState({ isPrinterAvailable: selectedPrinter !== null });

			} catch (ex: any) {
				console.log(`Exception ${ex.toString()} getting Printers`);
			}
		}
		else{
			let sendToPrinter={
				isEnabled:false,
				selectedGrafanaBundle:''
			  }
			this.props.onEnableSendToPrinter(sendToPrinter)
		}
	}
	onCloseModal() {
		this.setState({
			sendToPrinter: {
				isEnabled: false as boolean,
				selectedGrafanaBundle: ""
			}
		})
		this.props.onNewBuildCloseDialog()


	}
	public render() {
		return (
			<>
				{this.props.isModalOpen ? (
					<>
					<LoadingComponent visible={this.state.isLoading} />
						<Modal
							show={this.props.isModalOpen}
							onHide={() => this.onCloseModal()}
							backdrop="static"
							keyboard={false}
							dialogClassName="partsDialog"
							aria-labelledby="contained-modal-title-vcenter"
							centered
						>
							<Modal.Header>
								<Modal.Title>This plate will be sliced and turned into a build</Modal.Title>
								<span className="close-btn"
									onClick={() => this.onCloseModal()}>
								</span>
							</Modal.Header>
							<Modal.Body>
								<div className="each-dialog-content">
									<div className="each-d-section">
									Name Your New Build
									</div>
									<div className='each-d-section'>
										<input className='f-w'
											type='text'
											value={this.state.plateName}
											onChange={(event) => this.onNewBuildNameChange(event)}
										/>
									</div>
									<div className="hint-msg">
										Name can contain only a-z, A-Z, 0-9, ._-
									</div>
									<div
										className="invalid-feedback d-block"
										hidden={!this.props.hasError}
									>
										{this.props.errorMessage}
									</div>
									<div className="each-d-section">
											<img
												className={this.state.sendToPrinter.isEnabled ? 'icon check' : 'icon uncheck'}
												alt=''
												onClick={(e) => {
													this.setState((prevState) => ({ sendToPrinter: {
														isEnabled:!prevState.sendToPrinter.isEnabled  as boolean,
														selectedGrafanaBundle:prevState.sendToPrinter.selectedGrafanaBundle
													}}),()=> this.onSendToPrinterChecked())
												}}
											/>
											<span className="field">Send to Printer</span>
											{this.state.isLoading && (
												<img src="/loader-h.gif" className='pl-3' alt="Loader" />
											)}
											
										</div>
									{(this.state.sendToPrinter.isEnabled && this.state.isPrinterAvailable === false) && (
										<div
											className="invalid-feedback d-block"										
										>
											Selected printer is not available
										</div>
									)}
										

								</div>
							</Modal.Body>
							<Modal.Footer className="justify-content-center">
								<Button variant="secondary" onClick={() => this.onCloseModal()}>
									Cancel
								</Button>
								<Button
									className="btn-primary"
									onClick={() => { this.onBeginSlicing() }}
									autoFocus
								>
									Continue
								</Button>
							</Modal.Footer>
						</Modal>
					</>
				) : null}
			</>
		);
	}
}

const NewBuild = withRouter(connect(Props, Actions)(Component));

export default NewBuild;
