import API, { graphqlOperation } from '@aws-amplify/api';
import { SortDirection } from "../../../Enums";
import { union } from 'lodash';

import { partByCreatedAt, getParts } from '../../../../graphql/queries';
import * as subscriptions from '../../../../graphql/subscriptions';
import subscriptionsSet from "../../Subscriptions/subscriptionsSet";

import ACTION_TYPES from '../../../actionTypes';
import { IPart } from '../../../Interfaces';

const getAvailableParts = (parts: IPart[] = []) => {
    return parts.filter(p => Boolean(p && !p._deleted));
};

const onLoadPartsStarted = () => {
    return {
        type: ACTION_TYPES.HOME.LOAD.PARTS.STARTED,
        payload: null,
    };
};

const onLoadPartsInProgress = (newlyLoadedParts, nextToken, type = 'load') => {
    return {
        type: ACTION_TYPES.HOME.LOAD.PARTS.IN_PROGRESS,
        payload: {parts: newlyLoadedParts, nextToken: nextToken, type: type},
    };
};

const onLoadPartsCompleted = () => {
    return {
        type: ACTION_TYPES.HOME.LOAD.PARTS.COMPLETED,
        payload: null,
    };
};

const onLoadPartsUpdated = (newlyLoadedParts) => {
    return {
        type: ACTION_TYPES.HOME.LOAD.PARTS.UPDATED,
        payload: newlyLoadedParts,
    };
};

const onLoadPartsCreated = (newlyLoadedParts) => {
    return {
        type: ACTION_TYPES.HOME.LOAD.PARTS.CREATED,
        payload: newlyLoadedParts,
    };
};

const onLoadPartsDeleted = (deletedParts) => {
    return {
        type: ACTION_TYPES.HOME.LOAD.PARTS.DELETED,
        payload: deletedParts,
    };
};

const loadParts = async (dispatch, getState, newRequest) => { 
    try {
        const state = getState();
        var   loadedParts = newRequest ? [] : state.data?.parts?.loadedParts || [];
        const nextToken = newRequest ? undefined : state.data?.parts?.nextToken;
        const sortDirection = state.data?.parts?.sortDirection === SortDirection.ASC ? 'ASC' : 'DESC';
        const searchText = state.data?.parts?.searchText;
        const currentUser = state.creator;
        const showOwnedByFilter = state.data?.info.showOwnedByParts

        if (!newRequest && ! nextToken) {
          return;
        }

        const variables = {
            limit: 50,
            dumb: 1,
            sortDirection: sortDirection,
            nextToken: nextToken,
        };

        var useFilter = (searchText && searchText !== "");
        if (useFilter) {
            (variables as any).filter = {
                parts_search_string: { contains: searchText },
            };
        }
        if(showOwnedByFilter && currentUser !==""){
            (variables as any).filter = {
                creator: {eq: currentUser},
            };

        }

        dispatch(onLoadPartsStarted());
        do {
					const result = await API.graphql(graphqlOperation(partByCreatedAt, variables)) as any;
					const newlyLoadedParts = getAvailableParts((result as any)?.data?.partByCreatedAt?.items || [] as IPart[]);
					const newNextToken = (result as any)?.data?.partByCreatedAt?.nextToken || undefined;

          if (!newNextToken || (newlyLoadedParts.length && !useFilter)) {
						dispatch(onLoadPartsInProgress(union(loadedParts, newlyLoadedParts), newNextToken));
            break;
					}
          else {
						loadedParts = union(loadedParts, newlyLoadedParts);
						dispatch(onLoadPartsInProgress(loadedParts, undefined));
            variables.nextToken = newNextToken;
          }
        } while(true);
    } catch (graphqlError) {
        console.error(graphqlError);
    }
		dispatch(onLoadPartsCompleted());
		return Promise.resolve();
};

const onLoadPartIds = () => {
    return async (dispatch, getState) => {
        const state = getState();
        const stateParts = state.data.parts;
        const partIds = state.data.workspace.state.build.parts.map(a => a.properties.PartID)
       

        const partIdsToLoad = partIds.filter(id => stateParts.loadedParts.concat(stateParts.selectedParts).find(lp => lp.id === id) === undefined);
        const loadedParts = state.data?.parts?.selectedParts || [];

        let promises = partIdsToLoad.map(async (id) => {
            const variables = {
                id: id,
            };
            const result = await API.graphql(graphqlOperation(getParts, variables)) as any;
            return result?.data?.getParts;
        });
        dispatch(onLoadPartsInProgress(union(loadedParts, await Promise.all(promises)), undefined, 'select'));
    }
}

const onLoadParts = (newRequest = false) => {
    return async (dispatch, getState) => {
        const state = getState();
        await loadParts(dispatch, getState, newRequest);
        let newSubscriptions = {} as any;

        if (!state.subscriptions?.partCreate) {
          console.log("Adding PART CREATE subscription");
          newSubscriptions.partCreate = 
            (API.graphql(graphqlOperation(subscriptions.onCreateParts)) as any).subscribe({
              next: ({ provider, value }) => {
                  console.log({ provider, value })
                  dispatch(onLoadPartsCreated(value.data.onCreateParts))
              },
              error: error => console.error(error)
          });
        }
        if (!state.subscriptions?.partDelete) {
          console.log("Adding PART DELETE subscription");
          newSubscriptions.partDelete = 
            (API.graphql(graphqlOperation(subscriptions.onDeleteParts)) as any).subscribe({
              next: ({ provider, value }) => {
                  console.log({ provider, value })
                  dispatch(onLoadPartsDeleted(value.data.onDeleteParts))
              },
              error: error => console.error(error)
          });
        }
        if (!state.subscriptions?.partUpdate) {
          console.log("Adding PART UPDATE subscription");
          newSubscriptions.partUpdate = 
						(API.graphql(graphqlOperation(subscriptions.onUpdateParts)
          ) as any).subscribe({
              next: ({ provider, value }) => {
                  console.log({ provider, value })
                  dispatch(onLoadPartsUpdated(value.data.onUpdateParts))
              },
              error: error => console.warn(error)
          });
        }
        if (newSubscriptions.partCreate || newSubscriptions.partUpdate || newSubscriptions.partDelete) {
					dispatch(subscriptionsSet(newSubscriptions));
				}
    };
};

export default onLoadParts;
export { onLoadPartIds };
