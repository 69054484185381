import IPartInfo from "../../../../interfaces/IPartInfo";
import { TabIndex } from "../../../Enums";
import { IBuild, IPart, IState } from "../../../Interfaces";

const onNewBuildSuccessReducer = (state: IState, action) => {
    const previousBuildConfig = action.payload.previousBuildConfig;
    const addedPart = action.payload.addedParts;
    
    const newState = { ...state } as IState;
    // newState.data.workspace.addedParts = [] as IPart[];
    previousBuildConfig.machineConfig = JSON.parse(previousBuildConfig.machineConfig);
    previousBuildConfig.materialConfig = JSON.parse(previousBuildConfig.materialConfig);
    previousBuildConfig.metrics = JSON.parse(previousBuildConfig.metrics);
    previousBuildConfig.millConfig = JSON.parse( previousBuildConfig.millConfig);
    previousBuildConfig.recipe = JSON.parse(previousBuildConfig.recipe);
    newState.data.workspace.state.build = previousBuildConfig as any;
    newState.data.workspace.state.build.parts = addedPart as IPartInfo[];
    newState.data.workspace.isLoadingInProgress = !newState.data.workspace.isLoadingInProgress;
    
    newState.data.modals.newBuildModalStatus = {
        ...newState.data.modals.newBuildModalStatus,
        inputText: '',
        isOpen: false,
        isValid: false,
        hasError: false,
        errorMessage: '',
    };

    newState.data.info.homePageTabIndex = TabIndex.BUILDS;
    
    return newState;
};

export default onNewBuildSuccessReducer;
