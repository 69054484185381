import { IState } from "../../../Interfaces";

const onShowOwnedByPlatesReducer = (state: IState, action) => {

    const newState = { ...state } as IState;
    newState.data.info.showOwnedByPlates = !state.data.info.showOwnedByPlates;
    if(!state.data.info.showOwnedByPlates)  newState.data.info.showOwnedByAllCategories =false

    return newState;
};

export default onShowOwnedByPlatesReducer;