import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { DisplayBuildOptions } from "../..";
import { IState } from "../../../Interfaces";
import ProgressBar from "react-bootstrap/ProgressBar";
import { onNavigateToBuildReviewPage } from "../../../Actions/Home";
import { Auth } from "aws-amplify";

const Props = (state: IState, ownProps: any) => {
	const props = {};

	return props;
};

const Actions = {
	onNavigateToBuildReviewPage: onNavigateToBuildReviewPage,
};

class Component extends React.Component<any, any> {
	createdDate = (new Date(this.props.displayBuild.created_at) as Date).toLocaleString() as string;
	isConfigDisabled = false;
	displayFolderview = this.props.displayBuild.current_status !== "processing" ? 
                        this.props.displayBuild.current_status : this.props.displayBuild.build_result;
	componentDidMount() {
		Auth.currentAuthenticatedUser().then((user) => {
			const groups =
				user.signInUserSession.accessToken.payload["cognito:groups"] || [];
			if (groups[0] == "Operator") {
				this.isConfigDisabled = true;
			} else {
				this.isConfigDisabled = false;
			}
		});
	}
	public render() {	
    let buildProgress = 0;		
    if (this.props.displayBuild.current_status === "processing" && this.props.displayBuild.build_result) {
        buildProgress = parseFloat(this.props.displayBuild.build_result.replace("%", "").split(" ")[1]);
        if (isNaN(buildProgress)) {
            buildProgress = 0;
        }
    }
    console.log("Build progress: ", buildProgress);
		return (
			<>
				<div
					className="display-card build-card-view"
					id={`build-${this.props.displayBuild.index}`}
					key={`${this.props.displayBuild.index}`}
					onClick={() =>
						this.props.displayBuild.current_status &&
							(
								(this.props.displayBuild.current_status.toLowerCase().includes('completed')) ||
								(this.props.displayBuild.current_status.toLowerCase().includes('fail') && !this.isConfigDisabled)
							) ?
							(this.props.onNavigateToBuildReviewPage(this.props.displayBuild.id, this.props.history)) : (null)
					}
				>
					<div className="display-card-row">
						<div className="display-card-header">
							<label>{this.props.displayBuild.name}</label>
						</div>
						<div
							className="display-card-more-action"
							onClick={(e) => e.stopPropagation()}
						>
							<DisplayBuildOptions displayBuild={this.props.displayBuild} />
						</div>
					</div>
					<div className="display-card-row">
						<div className="display-card-sub-header">
							<div className="build-info">
								<label>{(new Date(this.props.displayBuild.created_at) as Date).toLocaleString()}</label>
								{this.props.displayBuild.moho_version ? (<label>v{this.props.displayBuild.moho_version}</label>) : (null)}
							</div>
						</div>
					</div>
					<div className="display-card-row">
						<div className="display-card-sub-header">
							{(this.props.displayBuild.current_status === "completed" ||
							  this.props.displayBuild.status === "completed") && (
								<span className="capitalise-text">

									<img src="/completed.svg" alt="Completed Status Icon" />
									{" " + "Completed"}
								</span>
							)}

							{(this.props.displayBuild.current_status === "failed" ||
							  this.props.displayBuild.status === "failed") && (
								<span className="capitalise-text">
									<img src="/failed.svg" alt="Failed Status Icon" />
									{" " + "Failed"}
								</span>
							)}
							{(this.props.displayBuild.current_status === "canceled" ||
							  this.props.displayBuild.status === "canceled") && (
								<span className="capitalise-text">
									<img src="/failed.svg" alt="Canceled Status Icon" />
									{" " + "Canceled"}
								</span>
							)}
							{(this.props.displayBuild.current_status === "processing" || this.props.displayBuild.current_status === "waiting" ||
								this.props.displayBuild.status === "processing" || this.props.displayBuild.status === "waiting") && (
									<div className="px-3">
										{this.props.displayBuild.current_status === "processing" ?
										(
											<label className="pl-0">{`${buildProgress}%`}</label>
										) : (
											<label className="pl-0">Initializing Build</label>
										)}
										<ProgressBar
											variant="success"
											now={buildProgress}
										/>
									</div>
								)}
						</div>
						{
							(this.props.displayBuild.build_messages !== null && JSON.parse(this.props.displayBuild.build_messages).warnings.length > 0) ?
								(<svg style={{marginRight:'10px', width:'30px',height:'30px'}} xmlns="http://www.w3.org/2000/svg" width="28.832" height="26.135" viewBox="0 0 28.832 26.135">
									<g id="warning" transform="translate(0 -23.946)">
										<path id="Path_36041" data-name="Path 36041" d="M201.439,26.125,211.5,43.558a4.35,4.35,0,0,1-3.77,6.523H197.67l-3.481-13.067,3.481-13.067A4.348,4.348,0,0,1,201.439,26.125Z" transform="translate(-183.254 0)" fill="#3b4145" />
										<path id="Path_36042" data-name="Path 36042" d="M10.646,26.125.581,43.558a4.35,4.35,0,0,0,3.77,6.523H14.416V23.946A4.348,4.348,0,0,0,10.646,26.125Z" transform="translate(0 0)" fill="#3a4145" />
										<path id="Path_36043" data-name="Path 36043" d="M269.582,73.619,259.517,56.186a2.619,2.619,0,0,0-2.188-1.308l8.608,22.653h1.382a2.61,2.61,0,0,0,2.262-3.912Z" transform="translate(-242.838 -29.19)" fill="#ffd749" />
										<path id="Path_36044" data-name="Path 36044" d="M54.216,73.589a2.931,2.931,0,0,1,.3,1.3,2.454,2.454,0,0,1-2.252,2.61H33.516a2.61,2.61,0,0,1-2.262-3.912L41.319,56.156a2.622,2.622,0,0,1,2.262-1.309l.075,0a2.238,2.238,0,0,1,1.877,1.308Z" transform="translate(-29.166 -29.161)" fill="#ffd764" />
										<path id="Path_36045" data-name="Path 36045" d="M256,354.131v2.9a1.45,1.45,0,1,0,0-2.9Z" transform="translate(-241.584 -311.592)" fill="#3b4145" />
										<path id="Path_36046" data-name="Path 36046" d="M231.7,354.131c.16,0,.29.649.29,1.45s-.13,1.45-.29,1.45a1.45,1.45,0,1,1,0-2.9Z" transform="translate(-217.279 -311.592)" fill="#3a4145" />
										<path id="Path_36047" data-name="Path 36047" d="M256,132.646v10.732a1.45,1.45,0,0,0,1.45-1.45V134.1A1.45,1.45,0,0,0,256,132.646Z" transform="translate(-241.584 -102.579)" fill="#3b4145" />
										<path id="Path_36048" data-name="Path 36048" d="M231.7,132.646c.16,0,.29.649.29,1.45v7.832c0,.8-.13,1.45-.29,1.45a1.45,1.45,0,0,1-1.45-1.45V134.1A1.45,1.45,0,0,1,231.7,132.646Z" transform="translate(-217.279 -102.579)" fill="#3a4145" />
									</g>
								</svg>
								) : null
						}
					</div>				
				</div>
			</>
		);
	}
}

const DisplayBuildCardView = withRouter(connect(Props, Actions)(Component));

export default DisplayBuildCardView;
