import API, { graphqlOperation } from '@aws-amplify/api';

import { updateParts } from '../../../../graphql/mutations';

import ACTION_TYPES from '../../../actionTypes';
import { IPart, IRenamePartModalStatus, IState } from '../../../Interfaces';
import { NotificationManager } from "react-notifications";
const onRenamePartInit = () => {
    return {
        type: ACTION_TYPES.PART.RENAME.INIT,
        payload: null,
    };
};

const onRenamePartSuccess = (renamedPart) => {
    return {
        type: ACTION_TYPES.PART.RENAME.SUCCESS,
        payload: renamedPart,
    };
};

const onRenamePartFail = (errorMessage: string) => {
    NotificationManager.error('Failure on Rename Part');
    console.error('Failure on Rename Part - ', errorMessage);
    return {
        type: ACTION_TYPES.PART.RENAME.FAIL,
        payload: errorMessage,
    };
};

const onRenamePartDone = () => {
    return {
        type: ACTION_TYPES.PART.RENAME.DONE,
        payload: null,
    };
};

const onRenamePart = () => {
    return async (dispatch, getState) => {

        dispatch(onRenamePartInit());

        const state = getState() as IState;

        const renamePartModalStatus = state.data.modals.renamePartModalStatus as IRenamePartModalStatus;

        const isPartNameEmpty = (!renamePartModalStatus.inputText) as boolean;
        const isPartNameExists = state.data.parts.loadedParts.some(p => p.name === renamePartModalStatus.inputText) as boolean;

        if (isPartNameEmpty) {
            const errorMessage = 'Part Name is mandatory.' as string;
            dispatch(onRenamePartFail(errorMessage));
            dispatch(onRenamePartDone());
            return;
        }
        if (isPartNameExists) {
            const errorMessage = 'Part Name already exists.' as string;
            dispatch(onRenamePartFail(errorMessage));
            dispatch(onRenamePartDone());
            return;
        }

        try {
            const state = getState() as IState;
            const partToBeRenamed = !state.data.search.isSearchEnabled ? (state.data.parts.loadedParts.find(
                (p) => p.id === state.data.parts.highlightedPartId)
              ) as IPart : state.data.search.highlightedPartData as IPart
              
            partToBeRenamed.name = renamePartModalStatus.inputText;
            partToBeRenamed.parts_search_string = renamePartModalStatus.inputText;
            delete partToBeRenamed._deleted;
            delete partToBeRenamed._lastChangedAt;
            delete partToBeRenamed.createdAt;
            delete partToBeRenamed.updatedAt;
            const variables = {
                input: partToBeRenamed,
            };
            const result = await API.graphql(graphqlOperation(updateParts, variables));
            const renamedPart = ((result as any)?.data?.updateParts) as IPart;
            dispatch(onRenamePartSuccess(renamedPart));
            dispatch(onRenamePartDone());
        } catch (graphqlError) {
            const errorMessage = `Rename part failed: ${Object((graphqlError as any)?.errors?.[0]?.message).toString()}`;
            dispatch(onRenamePartFail(errorMessage));
            dispatch(onRenamePartDone());
        }
    };
};

export default onRenamePart;