import React from "react";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  onArchivePart,
  onCopyPartOpenDialog,
  onDeletePart,
  onHighlightPart,
  onMovePartOpenDialog,
  onRenamePartOpenDialog,
  onUnarchivePart,
} from "../../../Actions/Parts";
import { onDownloadPart } from "../../../Actions/Home";
import { IPart, IState } from "../../../Interfaces";
import onGetHighlightedPart from "../../../Actions/Search/highlightPart";
import HorizontalLoader from "../../horizontalLoader";
import { Menu, MenuItem } from "@material-ui/core";

const Props = (state: IState, ownProps: any) => {
  const isSearchEnabled = state.data.search.isSearchEnabled as boolean;
  const highlightPart = state.data.search.highlightedPartData as IPart;
  const props = {
    isLoadingComplete: state.data.parts.isLoadingComplete as boolean,
    displayPart: ownProps.displayPart,
    isSearchEnabled: isSearchEnabled as boolean,
    highlightPart: highlightPart as IPart,
    creator:state.creator
  };

  return props;
};

const Actions = {
  onHighlightPart: onHighlightPart,
  onRenamePartOpenDialog: onRenamePartOpenDialog,
  onDownloadPart: onDownloadPart,
  onCopyPartOpenDialog: onCopyPartOpenDialog,
  onUnarchivePart: onUnarchivePart,
  onArchivePart: onArchivePart,
  onDeletePart: onDeletePart,
  onMovePartOpenDialog: onMovePartOpenDialog,
  onGetHighlightedPart: onGetHighlightedPart,
};

class Component extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
    event.currentTarget && !this.props.isSearchEnabled
      ? this.props.onHighlightPart(this.props.displayPart.id)
      : this.props.onGetHighlightedPart(this.props.displayPart.id);
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  getPart = async (id) => {
    await this.props.onGetHighlightedPart(id);
  };
  public render() {
    const { anchorEl } = this.state;
    let files =
      this.props?.displayPart?.files || this.props?.highlightPart?.files;
    if (files) {
      while (typeof files == "string" || files instanceof String) {
        files = JSON.parse(String(files));
      }
    }

    return (
      <div>
        <div>
          <div
            className="display-list-more-action"
            aria-controls={`simple-menu ${this.props.displayPart.id}`}
            aria-haspopup="true"
            id="dropdown-basic"
            onClick={this.handleClick}
            style={{ position: "relative" }}
          >
            <div className="options right-justify">
              <div className="more-check option">
                <img className="icon more-check" alt="" />
              </div>
            </div>
          </div>
          <Menu
            id={`simple-menu ${this.props.displayPart.id}`}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            style={{
              position: "absolute",
              top: "38px",
            }}
          >
            {!this.props.isSearchEnabled && (
              <div>
                {/* <MenuItem
							disabled={!this.props.isLoadingComplete}
							onClick={() => this.props.onNavigateToPartReviewPage(this.props.ids, this.props.history, true)}
						>
							Review Part
						</MenuItem> */}
                <MenuItem
                  // disabled={!this.props.isLoadingComplete}
                  disabled={true}
                  onClick={() => {
                    this.handleClose();
                    this.props.onRenamePartOpenDialog(
                      this.props.displayPart.id
                    );
                  }}
                >
                  Rename
                </MenuItem>
                {files
                  ? Object.keys(files).map((key) => (
                      <MenuItem
                        key={key}
                        onClick={() => {
                          this.handleClose();
                          this.props.onDownloadPart(
                            this.props.displayPart.id,
                            key
                          );
                        }}
                      >
                        Download {key}
                      </MenuItem>
                    ))
                  : null}
                <MenuItem
                  disabled={!this.props.isLoadingComplete}
                  onClick={() => {
                    this.handleClose();
                    this.props.onCopyPartOpenDialog(this.props.displayPart.id);
                  }}
                >
                  Duplicate
                </MenuItem>
                {/* <MenuItem
							disabled={!this.props.displayPart.isArchived}
							onClick={() => this.props.onUnarchivePart(this.props.displayPart.id)}
						>
							Unarchive
						</MenuItem>
						<MenuItem
							disabled={this.props.displayPart.isArchived}
							onClick={() => this.props.onArchivePart(this.props.displayPart.id)}
						>
							Archive
						</MenuItem> */}
                <MenuItem
                   disabled={
                    this.props.displayPart &&
                    (
                      (this.props.displayPart.plates && this.props.displayPart.plates.length !== 0) ||
                      this.props.displayPart.creator !== this.props.creator
                    ) &&
                    !(
                      this.props.displayPart.plates == null
                    )
                  }
                  onClick={() => {
                    this.handleClose();
                    this.props.onDeletePart(this.props.displayPart.id);
                  }}
                >
                  Delete
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    this.handleClose();
                    this.props.onMovePartOpenDialog(this.props.displayPart.id);
                  }}
                >
                  Move
                </MenuItem>
              </div>
            )}

            {this.props.isSearchEnabled && (
              <div>
                {Object.keys(this.props.highlightPart).length === 0 ? (
                  <MenuItem style={{ height: "230px" }}>
                    <HorizontalLoader></HorizontalLoader>
                  </MenuItem>
                ) : (
                  <div>
                    <MenuItem
                      // disabled={!this.props.isLoadingComplete}
                      disabled={true}
                      onClick={() => {
                        this.handleClose();
                        this.props.onRenamePartOpenDialog(
                          this.props.displayPart.id
                        );
                      }}
                    >
                      Rename
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        this.handleClose();
                        this.props.onDownloadPart(this.props.displayPart.id);
                      }}
                    >
                      Download
                    </MenuItem>
                    {files
                      ? Object.keys(files).map((key) => (
                          <MenuItem
                            key={key}
                            onClick={() => {
                              this.handleClose();
                              this.props.onDownloadPart(
                                this.props.displayPart.id,
                                key
                              );
                            }}
                          >
                            Download {key}
                          </MenuItem>
                        ))
                      : null}
                    <MenuItem
                      disabled={!this.props.isLoadingComplete}
                      onClick={() => {
                        this.handleClose();
                        this.props.onCopyPartOpenDialog(
                          this.props.displayPart.id
                        );
                      }}
                    >
                      Duplicate
                    </MenuItem>
                    {/* <MenuItem
							disabled={!this.props.displayPart.isArchived}
							onClick={() => this.props.onUnarchivePart(this.props.displayPart.id)}
						>
							Unarchive
						</MenuItem>
						<MenuItem
							disabled={this.props.displayPart.isArchived}
							onClick={() => this.props.onArchivePart(this.props.displayPart.id)}
						>
							Archive
						</MenuItem> */}
                    {/* {!this.props.isSearchEnabled && */}
                      <MenuItem
                        disabled={
                          this.props.displayPart &&
                          (
                            (this.props.highlightPart.plates && this.props.highlightPart.plates.length !== 0) ||
                            this.props.highlightPart.created_by ? this.props.highlightPart.created_by !== this.props.creator :this.props.highlightPart.creator !== this.props.creator 
                          ) &&
                          !(
                            this.props.highlightPart.plates == null
                          )
                        }
                        onClick={() => {
                          this.handleClose();
                          this.props.onDeletePart(this.props.displayPart.id);
                        }}
                      >
                        Delete 
                      </MenuItem>
                    {/* } */}
                    <MenuItem
                      onClick={() => {
                        this.handleClose();
                        this.props.onMovePartOpenDialog(
                          this.props.displayPart.id
                        );
                      }}
                    >
                      Move
                    </MenuItem>
                  </div>
                 )}
              </div>
            )}
          </Menu>
        </div>
      </div>
    );
  }
}

const DisplayPartOptions = withRouter(connect(Props, Actions)(Component));

export default DisplayPartOptions;
