import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
	DisplayReviewPlates,
	NewPlate,
	CopyPlate,
	RenamePlate,
	MovePlate,
	DisplayPlateCardView,
	DisplayPlateListView,
} from '../..';
import { onUnselectFolder } from '../../../Actions/Home';
import { onShowRecentPlates, onHideRecentPlates, onShowAllPlates, onHideAllPlates, onShowArchivedPlates, onHideArchivedPlates, onSelectPlate, onUnselectPlate } from '../../../Actions/Plates';
import { SortDirection } from '../../../Enums';
import { IDisplayFolder, IDisplayPlate, IState } from '../../../Interfaces';
import { getDisplayFolders, getDisplayPlates } from '../../../Mappers';

const Props = (state: IState, ownProps: any) => {

	const displayFolders = getDisplayFolders(state) as IDisplayFolder[];

	const totalFolders = (
		(state.data.folders.sortDirection === SortDirection.ASC) ?
			displayFolders.reverse() :
			displayFolders
	) as IDisplayFolder[];

	const filteredFolders = (
		totalFolders.filter(df => df.isFiltered)
	) as IDisplayFolder[];

	const selectedFolders = (
		filteredFolders.filter(ff => ff.isSelected)
	) as IDisplayFolder[];

	const recentFolders = (
		filteredFolders.filter(ff => !ff.isSelected && ff.isRecent)
	) as IDisplayFolder[];

	const allFolders = (
		filteredFolders.filter(ff => !ff.isSelected && !ff.isArchived)
	) as IDisplayFolder[];

	const archivedFolders = (
		filteredFolders.filter(ff => !ff.isSelected && ff.isArchived)
	) as IDisplayFolder[];

	const hasSelectedFolders = Boolean(selectedFolders.length > 0) as boolean;

	const displayPlates = getDisplayPlates(state) as IDisplayPlate[];

	const totalPlates = (
		(state.data.builds.sortDirection === SortDirection.ASC) ?
			displayPlates.reverse() :
			displayPlates
	) as IDisplayPlate[];

	const filteredPlates = (
		totalPlates.filter(dp => dp.isFiltered)
	) as IDisplayPlate[];

	const selectedPlates = (
		filteredPlates.filter(fp => fp.isSelected)
	) as IDisplayPlate[];

	const recentPlates = (
		filteredPlates.filter(fp => !fp.isSelected && !fp.isArchived && fp.isRecent)
	) as IDisplayPlate[];

	const allPlates = (
		filteredPlates.filter(fp =>  !fp.isArchived)
	) as IDisplayPlate[];

	const archivedPlates = (
		filteredPlates.filter(fp => !fp.isSelected && fp.isArchived)
	) as IDisplayPlate[];

	const hasSelectedPlates = Boolean(selectedPlates.length > 0) as boolean;

	const showSelectedPlates = Boolean(state.data.sections.recentPlates.showItems) as boolean;
	const showRecentPlates = Boolean(state.data.sections.recentPlates.showItems) as boolean;
	const showAllPlates = Boolean(state.data.sections.allPlates.showItems) as boolean;
	const showArchivedPlates = Boolean(state.data.sections.archivedPlates.showItems) as boolean;

	const props = {
		showListView: state.data.info.showListView as boolean,
		filteredFolders: filteredFolders as IDisplayFolder[],
		selectedFolders: selectedFolders as IDisplayFolder[],
		recentFolders: recentFolders as IDisplayFolder[],
		allFolders: allFolders as IDisplayFolder[],
		archivedFolders: archivedFolders as IDisplayFolder[],
		hasSelectedFolders: hasSelectedFolders as Boolean,
		filteredPlates: filteredPlates as IDisplayPlate[],
		selectedPlates: selectedPlates as IDisplayPlate[],
		recentPlates: recentPlates as IDisplayPlate[],
		allPlates: allPlates as IDisplayPlate[],
		archivedPlates: archivedPlates as IDisplayPlate[],
		hasSelectedPlates: hasSelectedPlates as Boolean,
		showSelectedPlates: showSelectedPlates as Boolean,
		showRecentPlates: showRecentPlates as Boolean,
		showAllPlates: showAllPlates as Boolean,
		showArchivedPlates: showArchivedPlates as Boolean,
	};

	return props;
};

const Actions = {
	onUnselectFolder: onUnselectFolder,
	onShowRecentPlates: onShowRecentPlates,
	onHideRecentPlates: onHideRecentPlates,
	onShowAllPlates: onShowAllPlates,
	onHideAllPlates: onHideAllPlates,
	onShowArchivedPlates: onShowArchivedPlates,
	onHideArchivedPlates: onHideArchivedPlates,
	onSelectPlate: onSelectPlate,
	onUnselectPlate: onUnselectPlate,
};

class Component extends React.Component<any, any> {
	// componentWillMount() {
	// 	this.props.history.push(`/dashboard/ReviewPage/Plates`);
	// }

	public plateListView() {
		return (
			<>
				<dl className='grid-view'>
					<dd>
						<>
							<div className='display-list'>
								<div className="display-list-row">
									<div className="display-list-header">
										<label>Type</label>
									</div>
									<div className="display-list-header">
										<label>Name</label>
									</div>
									<div className="display-list-header">
										<label>Imported Date</label>
									</div>
									<div className="display-list-header">
										<label>Owner</label>
									</div>
									<div className="display-list-header">
										<label>No of Builds</label>
									</div>
									<div className="display-list-header">
										<label>Folder</label>
									</div>
									<div className="display-list-header">
										<label>Actions</label>
									</div>
								</div>
							</div>
						</>
						<>
							{
								this.props.filteredPlates.map((fp, i) => {
									return (
										<>
											<DisplayPlateListView
												{...{
													key: i,
													index: i,
													displayPlate: fp,
													isSelected: this.props.isSelected,
													onUnselect: this.props.onUnselect,
													onSelect: this.props.onSelect,
												}}
											/>
										</>
									)
								})
							}
						</>
					</dd>
				</dl>
			</>
		)
	}

	public plateCardView() {
		return (
			<>
				{/* <DisplayReviewPlates
					{...{
						label: 'Selected Plates',
						displayFolders: [],
						displayPlates: this.props.selectedPlates,
						showOptions: true,
						showItems: this.props.showSelectedPlates,
						onShowItems: this.props.onShowRecentPlates,
						onHideItems: this.props.onHideRecentPlates,
						isSelected: this.props.isSelected,
					}}
				/> */}
				<>
					{
						this.props.hasSelectedFolders ?
							this.props.selectedFolders.map(selectedFolder => this.selectedFolderView(selectedFolder)) :
							this.platesView()
					}
				</>
			</>
		)
	}

	public selectedFolderView(selectedFolder) {
		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item
						onClick={() => this.props.onUnselectFolder(selectedFolder['id'])}
					>
						Plates
					</Breadcrumb.Item>
					<Breadcrumb.Item active>
						{selectedFolder['name']}
					</Breadcrumb.Item>
				</Breadcrumb>
				<dl className={`${this.props.showListView ? 'grid-view' : ''}`}>
					<dd>
						{
							this.props.showListView ?
								(
									<>
										<div className='display-list'>
											<div className='display-list-row'>
												<div className='display-list-header'>
													<label>Type</label>
												</div>
												<div className='display-list-header'>
													<label>Name</label>
												</div>
												<div className='display-list-header'>
													<label>Created Date</label>
												</div>
												<div className='display-list-header'>
													<label>Owner</label>
												</div>
												<div className='display-list-header'>
													<label>No of Builds</label>
												</div>
												<div className='display-list-header'>
													<label>Folder</label>
												</div>
												<div className='display-list-header'>
													<label>Actions</label>
												</div>
											</div>
										</div>
									</>
								)
								:
								(
									null
								)
						}
						{
							selectedFolder['displayPlates']
								.filter(dp => dp.folders.some(f => f === selectedFolder['name']))
								.map((dp, i) => this.props.showListView ? <DisplayPlateListView key={i} displayPlate={dp} /> : <DisplayPlateCardView key={i} displayPlate={dp} />)
						}
					</dd>
				</dl>
			</>
		)
	}

	public platesView() {
		return (
			<>
				<DisplayReviewPlates
					{...{
						label: 'All Plates',
						displayFolders: this.props.allFolders,
						displayPlates: this.props.allPlates,
						showOptions: false,
						showItems: this.props.showAllPlates,
						onShowItems: this.props.onShowAllPlates,
						onHideItems: this.props.onHideAllPlates,
						isSelected: false,
						onUnselect: this.props.onUnselectPlates,
						onSelect: this.props.onSelectPlate,
					}}
				/>
			</>
		)
	}

	public render() {
		return (
			<>
				{
					// this.props.showListView ?
					// 	this.plateListView() :
					this.plateCardView()
					// this.platesView()
				}
				<NewPlate />
				<RenamePlate />
				<CopyPlate />
				<MovePlate />
			</>
		);
	}
}

const ReviewPlatesSection = withRouter(connect(Props, Actions)(Component));

export default ReviewPlatesSection;
