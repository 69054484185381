import React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { onAddPartToAddedParts } from '../Actions/Parts';
import {
	getDefaultPrintConfiguration,
	getDefaultMillConfiguration,
	getDefaultMaterialConfiguration,
	getDefaultRecipeConfiguration,
	getDefaultPartConfiguration,
	getDefaultPartSlicerConfiguration,
} from '../DefaultConfigurations';
import { IConfiguration, IState } from '../Interfaces';
import moment from 'moment';

const Props = (state: IState, ownProps: any) => {
	const defaultPrintConfiguration = getDefaultPrintConfiguration(state) as IConfiguration;
    const defaultMillConfiguration = getDefaultMillConfiguration(state) as IConfiguration;
    const defaultMaterialConfiguration = getDefaultMaterialConfiguration(state) as IConfiguration;
    const defaultRecipeConfiguration = getDefaultRecipeConfiguration(state) as IConfiguration;

	const props = {
		ids: {
			partIds: [ownProps.loadedPart].map((part) => part.id) as string[],
			partConfigurationIds: [ownProps.loadedPart]
				.map((id) => getDefaultPartConfiguration(state))
				.map((configuration) => configuration.id) as string[],
			partSlicerConfigurationIds: [ownProps.loadedPart]
				.map((id) => getDefaultPartSlicerConfiguration(state))
				.map((configuration) => configuration.id) as string[],
			plateConfigurationIds: [
				defaultPrintConfiguration['id'],
				defaultMillConfiguration['id'],
				defaultMaterialConfiguration['id'],
				defaultRecipeConfiguration['id'],
			],
		},
		state: state
	};

	return props;
};

const Actions = {
	onAddPartToAddedParts: onAddPartToAddedParts,
};

class Component extends React.Component<any, any> {
	createdDate = this.props.loadedPart?.created_at;
    formatedDate = navigator.userAgent.indexOf('Mac OS X') != -1 ? this.createdDate.substring(3, 5) + "/" + this.createdDate.slice(0, 3) + this.createdDate.substring(5, this.createdDate.length) : this.props.loadedPart.created_at
	
	udpatePlates =  () => {
		const payload = this.props.ids;
		payload.plateConfigurationIds = this.props.state.data.workspace.selectedConfigurations.map(item => item !== undefined ?item.id : null)
		if(this.props.state.data.workspace.isAllAddedPartsSelected)
		{
			payload.partConfigurationIds[0] = this.props.state.data.workspace.addedPartsSelectedPartConfigurations[0].id
		}
		this.props.onAddPartToAddedParts(payload)
	}
	

	public render() {
		return (
			<>
				<div
					className='display-card'
					id={`part-${this.props.loadedPart.index}`}
					key={`${this.props.loadedPart.index}`}
				>
					<div>
                        <div className='thumbnail-preview'>
                            {
                                this.props.loadedPart.icon ?
                                    <img className='part-image' src={this.props.loadedPart.icon} />
                                    : <img src="/part-thumbnail.svg" alt="thumbnail image" className='default-thumb-image' />
                            }
                        </div>
                        <div className='display-card-row'>

                            <div className='display-card-header'>
                                <label title={this.props.loadedPart.name}>{this.props.loadedPart.name}</label>
                            </div>
							<div className='display-card-select-action'>
							<div className='options right-justify'>
								<div className='add-to-plate option'>
									<div
										className='active'
										onClick={()=> {this.udpatePlates()}}
									>+</div>
								</div>
							</div>
						</div>
                        </div>
                        <div className='display-card-row'>

                            <div className='display-card-sub-header'>
                                <div className='details'>
                                    <label>Imported Date:</label>
									<span>{this.createdDate ? moment(new Date(this.formatedDate)).format('MMMM Do YYYY, HH:mm:ss A') : 'Not available'}</span>
                                </div>
								<div className='details'>
                                    <label>Created By:</label>
                                    <span>{this.props.loadedPart?.creator}</span>
                                </div>
                                {/* <div className='details'>
                                    <label>File Size:</label>
                                    <span>34MB</span>
                                </div>
                                <div className='details'>
                                    <label>Dimensions:</label>
                                    <span>50mm x 50mm x 16mm</span>
                                </div> */}
                            </div>
                        </div>
                    </div>
					
					<div className='display-card-row'>
						<div className='display-card-sub-header'>
							<>
								{
									this.props.loadedPart.isMovedToFolder ?
										(
											<>
												{
													this.props.loadedPart.folders.map(folder => <label>{folder}</label>)
												}
											</>
										) :
										(
											<label></label>
										)
								}
							</>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const LoadedPart = withRouter(connect(Props, Actions)(Component));

export default LoadedPart;
