import { url } from "inspector";
import React from "react";
import * as THREE from "three";
import { Vector3 } from "three";
import { TransformControls } from "three/examples/jsm/controls/TransformControls";
import { Button, Checkbox, FormControlLabel, styled } from "@material-ui/core";

import Scene from "../../Scene";
import { CoordinateDisplay } from "../../WorkSpace/CoordinateDisplay";
import { IWorkspaceMeshManager } from "./WorkspaceMeshManager";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const emissiveColors = {
    standard: 0x000000,
    highlighted: 0x111111,
    selected: 0x222222,
};

interface IProps {
    scene: Scene;
    meshManager: IWorkspaceMeshManager;
    dispatch: any;
}

interface IState {
    viewControl: boolean;
    orthographic: boolean;
    showGrid: boolean;
    currentMode: Mode;
    clickedMesh: THREE.Object3D | null;
    refresh: boolean;
    isSelected: boolean,
    selectedView: string;
}

interface IPickResult {
    point: THREE.Vector3,
    face: THREE.Face3,
    object: THREE.Object3D,
}

export enum Mode {
    None,
    Translate,
    Rotate,
    Snap,
    FaceUpOrient,
    MoveToCentre,
    DualZ,
}

export default class WorkspaceSceneController extends React.Component<
    IProps,
    IState,
    IPickResult
> {
    tooltip: React.RefObject<HTMLDivElement>;
    hoveredMesh: THREE.Object3D | null;
    raycaster: THREE.Raycaster;
    transformControls: TransformControls;
    arrowHelper: THREE.ArrowHelper;
    plane: THREE.Mesh;
    isDraggingPlane: boolean;
    previousMousePosition = 0;
    constructor(props: IProps) {
        super(props);

        let buildAreaVolume = this.props.meshManager.getBuildAreaVolume();
        const planeGeometry = new THREE.PlaneGeometry(buildAreaVolume.max.x - buildAreaVolume.min.x, buildAreaVolume.max.y - buildAreaVolume.min.y);
        const planeMaterial = new THREE.MeshStandardMaterial({ color: 0x0000ff, side: THREE.DoubleSide, transparent: true, opacity: 0.4 });
        this.plane = new THREE.Mesh(planeGeometry, planeMaterial);
        this.plane.renderOrder = 999;
        this.plane.visible = false;
        props.scene.scene.add(this.plane);

        this.isDraggingPlane = false;

        this.onDelete = this.onDelete.bind(this);

        this.tooltip = React.createRef<HTMLDivElement>();
        this.hoveredMesh = null;
        this.raycaster = new THREE.Raycaster();
        this.arrowHelper = new THREE.ArrowHelper(
            new THREE.Vector3(0, 0, 1),
            new THREE.Vector3(0, 0, 0),
            30,
            0xffff00,
        );
        this.props.scene.scene.add(this.arrowHelper);
        this.arrowHelper.visible = false;
        this.transformControls = new TransformControls(
            props.scene.camera,
            props.scene.renderer.domElement
        );
        props.scene.scene.add(this.transformControls);
        this.putMeshToPart = this.putMeshToPart.bind(this)

        this.state = {
            viewControl: false,
            orthographic: false,
            showGrid: true,
            currentMode: Mode.None,
            clickedMesh: null,
            refresh: false,
            isSelected: true,
            selectedView: "",
        };

        this.transformControls.addEventListener(
            "dragging-changed",
            (event: any) => {
                if (this.props.scene.cameraControls) {
                    this.props.scene.cameraControls.enabled = !event.value;
                }
            }
        );

        this.transformControls.addEventListener("objectChange", () => {
            this.setState(
                Object.assign(this.state, { refresh: !this.state.refresh })
            );
        });

        this.transformControls.addEventListener("mouseUp", (event: any) => {
            const mesh = event.target.object;
            const mode = event.target.getMode();
            if (mode === "rotate" || mode === "translate"){
                this.putMeshToPart(mesh)
            }
        });


        props.scene.renderer.domElement.addEventListener("mousemove", (ev) => {
            if (this.state.clickedMesh){
                this.putMeshToPart(this.state.clickedMesh)
            }
            this.onMouseMove(ev)
          }
        );
        props.scene.renderer.domElement.addEventListener("mousedown", (ev) =>
            this.onMouseClick(ev)
        );
        props.scene.renderer.domElement.addEventListener("mouseup", (ev) =>
            this.onMouseUp(ev)
        );
        props.meshManager.addListener(this);
    }

    toggleViewControl() {
        this.setState({
            ...this.state, 
            viewControl: !this.state.viewControl,
        });
    }

    render() {
        return (
            <>
                <div
                    ref={this.tooltip}
                    style={{
                        position: "fixed",
                        display: "none",
                        border: "1px solid black",
                        backgroundColor: "#ffffff",
                        padding: "0 8px 0 8px",
                        borderRadius: "2px",
                        // backgroundImage:`url(${process.env.PUBLIC_URL+ "/img/workspace-bg.png"})`
                    }}
                ></div>
                <div className="modePickerContainer mt-4 pl-3">
                    
                    <div className={`modePickerSubContainer`}> 
                        <div className="modePickerContainerItem">
                            <div className={`icon translate ${Mode.Translate === this.state.currentMode ? 'active' : ''}`} onClick={() => {
                                let newMode = Mode.Translate === this.state.currentMode ? Mode.None : Mode.Translate;
                                this.setState({
                                    currentMode: newMode,
                                });
                            }}>
                            </div>
                            <span className="tooltiptext">Translate</span>
                        </div>
                        <div className="modePickerContainerItem">
                            <div className={`icon rotate ${Mode.Rotate === this.state.currentMode ? 'active' : ''}`} onClick={() => {
                                let newMode = Mode.Rotate === this.state.currentMode ? Mode.None : Mode.Rotate;
                                this.setState({
                                    currentMode: newMode,
                                });
                            }}>
                            </div>
                            <span className="tooltiptext">Rotate</span>
                        </div>
                        <div className="modePickerContainerItem">
                            <div className={`icon move-to-centre ${Mode.MoveToCentre === this.state.currentMode ? 'active' : ''}`} onClick={() => {
                                let newMode = Mode.MoveToCentre === this.state.currentMode ? Mode.None : Mode.MoveToCentre;
                                this.setState({
                                    currentMode: newMode,
                                });
                            }}>
                            </div>
                            <span className="tooltiptext">Move To Center</span>
                        </div>
                        <div className="modePickerContainerItem">
                            <div className={`icon face-up-orient ${Mode.FaceUpOrient === this.state.currentMode ? 'active' : ''}`} onClick={() => {
                                let newMode = Mode.FaceUpOrient === this.state.currentMode ? Mode.None : Mode.FaceUpOrient;
                                this.setState({
                                    currentMode: newMode,
                                });
                            }}>
                            </div>
                            <span className="tooltiptext">Face-down Orient</span>
                        </div>
                        <div className="modePickerContainerItem">
                            <div className={`icon snap ${Mode.Snap === this.state.currentMode ? 'active' : ''}`} onClick={() => {
                                let newMode = Mode.Snap === this.state.currentMode ? Mode.None : Mode.Snap;
                                this.setState({
                                    currentMode: newMode,
                                });
                            }}>
                            </div>
                            <span className="tooltiptext">Snap</span>
                        </div>
                {this.props.meshManager.getListOfMeshes().length == 1 &&
                        <div className="modePickerContainerItem">
                            <div className={`icon dual-z ${Mode.DualZ === this.state.currentMode ? 'active' : ''}`} onClick={() => {
                                let newMode = Mode.DualZ === this.state.currentMode ? Mode.None : Mode.DualZ;
                                this.setState({
                                    currentMode: newMode,
                                });
                            }}>
                            </div>
                            <span className="tooltiptext">Dual Configuration</span>
                        </div>
                }
                    </div>
                    
                </div>
                <div className="buildSliceSceneControls mt-3">
              <div className="build-review-reduced-margin">
                <FormControlLabel
                  control={
                    <Checkbox
                    onChange={(event) => {
                        this.setState({
                          ...this.state,
                          orthographic: event.target.checked,
                        });
                      }}
                      color="primary"
                      checked={this.state.orthographic}
                    />
                  }
                  label={
                    <span className="build-review-scene-controls">
                      Ortho
                    </span>
                  }
                />
              </div>
             
              <div>
                <OverlayTrigger
                  placement="left"
                  overlay={
                    <Tooltip className="overlay-text" id="workspace view">
                      TOP VIEW
                    </Tooltip>
                  }
                >
                  <div className={`icon top-view active-btn`} id="topview"

                    onClick={() => {
                        this.props.scene?.switchViewDirection(new THREE.Vector3(0, 0, 1))
                      this.setState({	
                        ...this.state,	
                        isSelected: true,	
                        selectedView: "topview",	
                      })
                    }}>
                  </div>
                </OverlayTrigger>
              </div>
              <div>
                <OverlayTrigger
                  placement="left"
                  overlay={
                    <Tooltip className="overlay-text" id="workspace view">
                      LEFT VIEW
                    </Tooltip>
                  }
                >
                  <div className={`icon left-view`} id="leftview" onClick={() => {
                    this.props.scene?.switchViewDirection(new THREE.Vector3(-1, 0, 0))
                    this.setState({	
                      selectedView: "leftview"	
                    })
                  }}>
                  </div>
                </OverlayTrigger>
              </div>
              <div>
                <OverlayTrigger
                  placement="left"
                  overlay={
                    <Tooltip className="overlay-text" id="workspace view">
                      FRONT VIEW
                    </Tooltip>
                  }
                >
                  <div className={`icon front-view`} id="frontview" onClick={() => {
                    this.props.scene?.switchViewDirection(new THREE.Vector3(0, -1, 0));
                    this.setState({	
                      selectedView: "frontview"	
                    })
                  }}>
                  </div>
                </OverlayTrigger>
              </div>
              <div>
                <OverlayTrigger
                  placement="left"
                  overlay={
                    <Tooltip className="overlay-text" id="workspace view">
                      SHOW GRID
                    </Tooltip>
                  }
                >
                  <div className={`icon grid-view`} id="gridview">
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event) => {
                            this.setState({
                                ...this.state,
                                  showGrid: !this.state.showGrid,
                                selectedView: "gridview"
                              });
                          }}
                          color="primary"
                          checked={this.state.showGrid}
                        />
                      }
                      label=""
                    />
                  </div>
                </OverlayTrigger>
              </div> 
            </div>
                {this.state.clickedMesh ? (
                    <CoordinateDisplay
                        mesh={this.state.clickedMesh}
                        planeZ={this.plane}
                        refreshToken={this.state.refresh}
                        mode={this.state.currentMode}
                        updater={this.putMeshToPart}
                    />
                ) : null}
            </>
        );
    }

    private putMeshToPart(mesh=null) {
            if (!mesh) {
                mesh = this.state.clickedMesh
            }
            let part = this.props.meshManager.findPartForMesh(mesh);
            part.properties.translate = {
                x: this.state.clickedMesh.position.x,
                y: this.state.clickedMesh.position.y,
                z: this.state.clickedMesh.position.z
            };
            part.properties.rotate = {
                x: this.state.clickedMesh.rotation._x,
                y: this.state.clickedMesh.rotation._y,
                z: this.state.clickedMesh.rotation._z
            };
            if (this.isPlaneOnScene()) {
                part.properties.DualZ = this.plane.position.z;
            }
            this.props.meshManager.syncPartAndMesh(part);
            this.props.meshManager.snapObjectToFloor(part.mesh);
            this.props.scene.render();
    }

    private isPlaneOnScene() {
        return this.plane && this.plane.visible;
    }

    componentDidUpdate(prevProps, prevState) {
      let needRender = false;
      if (prevState.selectedView != this.state.selectedView) {
        var elements = document.querySelectorAll('.active-btn');
        elements?.forEach((a) => {
          a.classList.remove("active-btn");
        });
        var element = document.getElementById(this.state.selectedView);
        element?.classList.add("active-btn");
      }
      if (prevState.currentMode !== this.state.currentMode) {
          switch (this.state.currentMode) {
              case Mode.None:
              case Mode.Snap:
              case Mode.FaceUpOrient:
              case Mode.MoveToCentre:
              case Mode.DualZ:
                  this.transformControls.detach();
                  if (this.state.clickedMesh) {
                      this.setState(Object.assign(this.state, { clickedMesh: null }));
                  }
                  break;
              case Mode.Translate:
                  this.transformControls.showX = this.transformControls.showY = true;
                  this.transformControls.showZ = false;
                  this.transformControls.setMode("translate");
                  break;
              case Mode.Rotate:
                  this.transformControls.showX = this.transformControls.showY = false;
                  this.transformControls.showZ = true;
                  this.transformControls.setMode("rotate");
                  break;
            }

            if (this.state.currentMode !== Mode.DualZ) {
                if (this.isPlaneOnScene()) {
                    this.plane.visible = false;
                }
            }
          needRender = true;
      }

      if (prevState.orthographic !== this.state.orthographic) {
          this.props.scene?.setCameraMode(this.state.orthographic);
          this.transformControls.camera = this.props.scene.camera;
          needRender = true;
      }
      if (prevState.showGrid !== this.state.showGrid) {
          console.log("Switching grid");
          let grid = this.props.scene.scene.getObjectByName("gridVisualizer");
          if (grid) {
              grid.visible = this.state.showGrid;
          }
          needRender = true;
      }

      if (needRender) {
          this.props.scene.render();
      }
    }

    onDelete(object: THREE.Object3D) {
        if (object === this.transformControls.object) {
            if (this.isPlaneOnScene()) {
              this.props.scene.scene.remove(this.plane);
              this.plane.geometry.dispose();
              (this.plane.material as THREE.MeshStandardMaterial).dispose();
            }
            this.transformControls.detach();
            this.setState({...this.state, clickedMesh: null});
        }
        this.setState(
            Object.assign(this.state, { refresh: !this.state.refresh })
        );
}

    private onMouseMove(event: MouseEvent) {

        if (this.isDraggingPlane)
        {
            if (this.isPlaneOnScene()) {
                const deltaMousePosition = event.clientY - this.previousMousePosition;

                this.plane.position.z = Math.max(0, this.plane.position.z - deltaMousePosition * 0.2);
                let part = this.props.meshManager.findPartForMesh(this.state.clickedMesh);

                this.previousMousePosition = event.clientY;

                this.setState(
                    Object.assign(this.state, { refresh: !this.state.refresh })
                );

                return;
            } else {
                this.isDraggingPlane = false;
            }
        }

        const object = this.pick(event);

        if (this.tooltip.current) {

            this.tooltip.current.innerHTML = '';
            if (object) {
                const mesh = object.object as THREE.Mesh;
                const part = this.props.meshManager.findPartForMesh(mesh);
                if (object.object.userData.intersectsOtherMesh) {
                    this.tooltip.current.innerHTML = `${part.customLabel} placement violates design rules. Please reposition it.`;
                }
                else if (object.object.userData.outsideBuildEnvelope) {
                    this.tooltip.current.innerHTML = `${part.customLabel} is not completely inside the build envelope`;
                }
                else if (object.object.userData.tooLarge) {
                    this.tooltip.current.innerHTML = `${part.customLabel} exceeds maximum recommended size`;
                }
                else if (object.object.userData.tooHeavy) {
                    this.tooltip.current.innerHTML = `${part.customLabel} exceeds maximum recommended weight`;
                }
                else if ('material' in object.object) {
                    this.tooltip.current.innerHTML = `${part.properties.PartID} as ${part.customLabel}`;
                }
            }

            this.tooltip.current.style.display = 'none';
            if (this.tooltip.current.innerHTML) {
                this.tooltip.current.style.display = 'block';
                this.tooltip.current.style.top = event.pageY + 20 + 'px';
                this.tooltip.current.style.left = event.pageX + 20 + 'px';
            }
        }

        if (object && this.state.currentMode === Mode.FaceUpOrient) {
            this.arrowHelper.position.set(object.point.x, object.point.y, object.point.z)
            let quat = new THREE.Quaternion();
            object.object.getWorldQuaternion(quat)
            this.arrowHelper.setDirection(object.face.normal.applyQuaternion(quat));
            this.arrowHelper.visible = true;
        } else {
            this.arrowHelper.visible = false;
        }

        if (this.hoveredMesh && this.hoveredMesh != this.state.clickedMesh) {
            const mesh = this.hoveredMesh as THREE.Mesh;
            if ("material" in mesh) {
                (mesh.material as THREE.MeshLambertMaterial).emissive.setHex(
                    emissiveColors.standard
                );
            }
        }

        if (object && object.object != this.state.clickedMesh) {
            const mesh = object.object as THREE.Mesh;
            if ("material" in mesh) {
                (mesh.material as THREE.MeshLambertMaterial).emissive.setHex(
                    emissiveColors.highlighted
                );
            }
        }

        if (this.state.currentMode === Mode.DualZ) {
            if (object) {
                if (this.plane.material.opacity !== 0.4) {
                    this.plane.material.opacity = 0.4;
                }
            } else if (this.plane.material.opacity !== 0.3) {
                this.plane.material.opacity = 0.3;
            }
        }

        this.hoveredMesh = object ? object.object : null;
        this.props.scene.render();
    }

    private onMouseUp(ev) {
        this.isDraggingPlane = false;
        this.props.scene.cameraControls.enabled = true;
    }

    private onMouseClick(ev) {
        if (
            this.state.currentMode !== Mode.None &&
            !this.transformControls.dragging
        ) {

            if (this.state.currentMode == Mode.DualZ && this.isPlaneOnScene())
            {
                this.isDraggingPlane = true;
                this.previousMousePosition = ev.clientY;
                this.props.scene.cameraControls.enabled = false;
            }

            const clickedMesh = this.pick(ev)?.object;

            if (this.state.clickedMesh) {
                const mesh = this.state.clickedMesh as THREE.Mesh;
                if ("material" in mesh) {
                    (mesh.material as THREE.MeshLambertMaterial).emissive.setHex(
                        emissiveColors.standard
                    );
                }
            }

            if (clickedMesh) {
                if (this.state.currentMode === Mode.FaceUpOrient) {
                    let object = this.pick(ev)
                    if (object) {
                        let oldCenter = new THREE.Vector3();
                        new THREE.Box3().setFromObject(clickedMesh, true).getCenter(oldCenter);

                        let part = this.props.meshManager.findPartForMesh(clickedMesh)

                        let quaternion = new THREE.Quaternion()
                        quaternion.setFromUnitVectors(object.face.normal, new THREE.Vector3(0, 0, -1))
                        let resultRotation = new THREE.Euler().setFromQuaternion(quaternion)
                        part.properties.rotate = {
                            x: resultRotation.x,
                            y: resultRotation.y,
                            z: resultRotation.z }
                        this.props.meshManager.syncPartAndMesh(part);

                        let newCenter = new THREE.Vector3();
                        new THREE.Box3().setFromObject(clickedMesh, true).getCenter(newCenter);
                        part.properties.translate = {
                            x: part.properties.translate.x + oldCenter.x - newCenter.x,
                            y: part.properties.translate.y + oldCenter.y - newCenter.y,
                            z: part.properties.translate.z + oldCenter.z - newCenter.z
                        }
                        this.props.meshManager.syncPartAndMesh(part);
                        this.props.meshManager.snapObjectToFloor(clickedMesh);
                        return
                    }
                }
                else if (this.state.currentMode === Mode.MoveToCentre) {
                    const objectBoundingBox = new THREE.Box3().setFromObject(clickedMesh, true);
                    let centre = new THREE.Vector3(0, 0);
                    objectBoundingBox.getCenter(centre);

                    let bvCenter = new Vector3()
                    this.props.meshManager.getBuildAreaVolume()?.getCenter(bvCenter)

                    let part = this.props.meshManager.findPartForMesh(clickedMesh)
                    let position = part.properties.translate;
                    let vectorForMove = new THREE.Vector3(0, 0, 0).addVectors(position, centre.negate());
                    part.properties.translate = {x: vectorForMove.x + bvCenter.x, y: vectorForMove.y + bvCenter.y, z: part.properties.translate.z};
                    this.props.meshManager.syncPartAndMesh(part);
                    this.props.meshManager.snapObjectToFloor(clickedMesh);
                    return;
                }
                else if (this.state.currentMode === Mode.Snap) {
                    this.props.meshManager.snapObjectToFloor(clickedMesh);
                    return;
                }
                else if (this.state.currentMode === Mode.DualZ) {
                    let part = this.props.meshManager.findPartForMesh(clickedMesh)
                    if (!this.isPlaneOnScene()) {
                        if (!this.plane) {
                            let buildAreaVolume = this.props.meshManager.getBuildAreaVolume();
                            const planeGeometry = new THREE.PlaneGeometry(buildAreaVolume.max.x - buildAreaVolume.min.x, buildAreaVolume.max.y - buildAreaVolume.min.y);
                            const planeMaterial = new THREE.MeshStandardMaterial({ color: 0x0000ff, side: THREE.DoubleSide, transparent: true, opacity: 0.4 });
                            this.plane = new THREE.Mesh(planeGeometry, planeMaterial);
                            this.props.scene.scene.add(this.plane);
                        }
                
                        this.plane.position.z = part?.properties?.DualZ ?? 0;
                        this.plane.renderOrder = 999;
                        this.plane.visible = true;
                    } else if (this.isPlaneOnScene() && clickedMesh) {
                        let canvasRect = this.props.scene.renderer.domElement.getBoundingClientRect();

                        let topOffset = canvasRect.top;
                        let leftOffset = canvasRect.left;
            
                        let canvasWidth = canvasRect.width;
                        let canvasHeight = canvasRect.height;
            
                        let mouse = new THREE.Vector2();
                        mouse.x = ((ev.clientX - leftOffset) / canvasWidth) * 2 - 1;
                        mouse.y = -((ev.clientY - topOffset) / canvasHeight) * 2 + 1;
            
                        this.raycaster.setFromCamera(mouse, this.props.scene.camera!);
            
                        let intersected = this.props.meshManager.selectedMatrix ?
                            this.raycaster.intersectObjects(this.props.meshManager.selectedMatrix.children)[0] :
                            this.raycaster.intersectObjects(this.props.meshManager.getListOfMeshes())[0];
        
                        if (intersected) {
                            this.plane.position.z = Math.max(0, intersected.point.z);
                        }

                        this.props.meshManager.syncPartAndMesh(part);
                        this.props.meshManager.snapObjectToFloor(clickedMesh);
                        //return;
                    }
                } else {
                    const mesh = clickedMesh as THREE.Mesh;
                    if ("material" in mesh) {
                        (mesh.material as THREE.MeshLambertMaterial).emissive.setHex(
                            emissiveColors.selected
                        );
                    }

                    this.transformControls.attach(clickedMesh);
                }
            } else if (ev.ctrlKey) {
                if (this.state.currentMode === Mode.MoveToCentre) {
                    this.props.meshManager.centerAll();
                    this.setState({currentMode: Mode.None});
                }
                else if (this.state.currentMode === Mode.Snap) {
                    ev.shiftKey ? this.props.meshManager.packParts() : this.props.meshManager.packOne(true);
                    this.setState({currentMode: Mode.None});
                }
                this.transformControls?.detach();
                if (this.isPlaneOnScene()) {
                  this.plane.visible = false;
                }
            } else {
                if (this.isPlaneOnScene()) {
                  this.plane.visible = false;
                }
                this.transformControls?.detach();
            }

            this.setState(Object.assign(this.state, { clickedMesh: clickedMesh }));
        }
        this.props.scene.render();
    }


    private pick(event: MouseEvent): IPickResult | null {
        // calculate mouse position in normalized device coordinates
        // (-1 to +1) for both components
        if (event !== undefined) {
            let canvasRect = this.props.scene.renderer.domElement.getBoundingClientRect();

            let topOffset = canvasRect.top;
            let leftOffset = canvasRect.left;

            let canvasWidth = canvasRect.width;
            let canvasHeight = canvasRect.height;

            let mouse = new THREE.Vector2();
            mouse.x = ((event.clientX - leftOffset) / canvasWidth) * 2 - 1;
            mouse.y = -((event.clientY - topOffset) / canvasHeight) * 2 + 1;

            // update the picking ray with the camera and mouse position
            this.raycaster.setFromCamera(mouse, this.props.scene.camera!);
            // calculate objects intersecting the picking ray

            if (this.props.meshManager.selectedMatrix) {
                let intersected = this.raycaster.intersectObjects(
                    this.props.meshManager.selectedMatrix.children
                )[0];

                if (intersected) {
                    return {
                        object: intersected.object.parent!,
                        point: intersected.point,
                        face: intersected.face!,
                    }
                }
            }
            else {
                let intersected = this.raycaster.intersectObjects(
                    this.props.meshManager.getListOfMeshes()
                )[0]
                if (intersected) {
                    return {
                        object: intersected.object,
                        point: intersected.point,
                        face: intersected.face!,
                    }
                }
            }
        }
        return null;
    }
}
