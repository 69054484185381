import { connect, useStore } from "react-redux";
import { withRouter } from "react-router-dom";
import { DisplayBuildCardView, DisplayBuildListView, DisplayFolder } from "../..";
import { IState } from "../../../Interfaces";
import React, { useState, useEffect, useRef } from "react";
import { buildByCreatedAt, buildByCurrentStatus } from "../../../../graphql/queries";
import API, { graphqlOperation } from "@aws-amplify/api";
import LoadingComponent from "../../../../LoadingComponent/LoadingComponent";
import { onSearchBuildsTextChange } from "../../../Actions/Builds";
import { onLoadBuilds } from "../../../Actions/Home";
import { updateBuild } from "../../../../graphql/mutations";
import DisplayDashboardBuildCardView from "../Builds/DisplayDashboardBuildCardView";
import DisplayDashboardFolder from "../Common/DisplayDashboardFolder";

const Props = (state: IState, ownProps: any) => {
    const hasDisplayItems =
        Boolean(ownProps.displayFolders && ownProps.displayFolders.length > 0);

    const props = {
        hasDisplayItems: hasDisplayItems as boolean,
        showListView: state.data.info.showListView as boolean,
        isLoadingComplete: state.data.builds.isLoadingComplete as boolean,
        loading: !(state.data.builds.isLoadingComplete as boolean),
        nextToken: state.data.builds.nextToken,
        displayFolders: ownProps.displayFolders,
        showItems: ownProps.showItems,
        onShowItems: ownProps.onShowItems,
        onHideItems: ownProps.onHideItems,
        loadedBuilds: state.data.builds.loadedBuilds,
        selectedBuildStatus: state.data.builds.selectedBuildStatus as string,
        searchText: state.data.builds.searchText as string,
        sortDirection: state.data.builds.sortDirection,
        selectedBuildIds: state.data.builds.selectedBuildIds
    };

    return props;
};

const Actions = {
    onSearchBuildsTextChange: onSearchBuildsTextChange,
    onLoadBuilds: onLoadBuilds,
};


const DisplayBuildsMethod = (props) => {
    const store = useStore();
    const loading = props.loading;
    const [searchedText, setSearchedText] = useState(undefined);
    const list = useRef(props.loadedBuilds);
    // let list = props.loadedBuilds;

    // const loadMoreBuilds = async () => {
    //   props.onLoadBuilds();
    // };
    useEffect(() => {
      if (props.searchText || searchedText) {
          props.onLoadBuilds(true);
      }
      setSearchedText(props.searchText)

    }, [props.searchText]);

    useEffect(() => {
      list.current = props.loadedBuilds;
    }, [props.loadedBuilds])

    const getDisplayFolder = () => {
      if(list.current.length) {
        return (list.current.length && list?.current.filter(i => !props.filter || i.id.toLowerCase().includes(props.filter.toLowerCase()))
          .map((dp, i) => (props.showListView ? <DisplayBuildListView key={i} displayBuild={dp} /> :
            <DisplayDashboardBuildCardView key={i} displayBuild={dp} visible={loading} isDoneBatchDelete={props.isDoneBatchDelete} resetBatchDelete={props.resetBatchDelete}  />
        )))
      }
      if (props.loadedBuilds.length === 0 && loading !== true) {
        return (
          <div className="d-block w-100 text-center">
            {
              props.selectedBuildStatus === "processing" ?
              <p className="justify-content-center p-4">The {props.selectedBuildStatus} status does not have any builds.</p>
              :
              <div className="d-block w-100 text-center search-message">
                <img src="/no-result-found.svg" alt="no-search-result" />
                <p className="justify-content-center p-4">No build found.</p>
              </div>
            }
          </div>
        )
      }
    }

   

    return (
      <>
        {
          true  ?
            (
              <>
                  <LoadingComponent visible={loading} />
                  <dl className={`${props.showListView ? "grid-view" : ""}`}>
                      <>
                        {
                          props.showItems ?/*  && falsedisabled */ 
                            (
                              <dd>
                                {
                                  props.displayFolders.map((df, i) => (
                                      <DisplayDashboardFolder
                                          key={i}
                                          displayFolder={df}
                                          showParts={true}
                                          showPlates={true}
                                          showBuilds={true}
                                      />
                                  ))
                                }
                              </dd>
                            )
                            :
                            ( null )
                        }
                      </>
                      <>
                        {
                          props.showItems ?
                            (
                              <dd className="options">
                                {
                                  <>
                                    {
                                      props.showListView ?
                                        (
                                          <div className="display-list">
                                              <div className="display-list-row">
                                                  <div className="display-list-header">
                                                      <label>Type</label>
                                                  </div>
                                                  <div className="display-list-header">
                                                      <label>Name</label>
                                                  </div>
                                                  <div className="display-list-header">
                                                      <label>Build Date</label>
                                                  </div>
                                                  <div className="display-list-header">
                                                      <label>Owner</label>
                                                  </div>
                                                  <div className="display-list-header">
                                                      <label>Status</label>
                                                  </div>
                                                  <div className="display-list-header">
                                                      <label>Folder</label>
                                                  </div>
                                                  <div className="display-list-header">
                                                      <label>Actions</label>
                                                  </div>
                                              </div>
                                          </div>
                                        )
                                        : ( null )
                                    }
                                    { getDisplayFolder() }
                                    {/* {
                                      props.nextToken ? (
                                          <div className="d-block w-100 text-center">
                                              <button onClick={loadMoreBuilds} className="btn btn-primary justify-content-center">
                                                  Load More Builds<span className="btn-right-arrow"></span></button>
                                          </div>
                                        ) : ( null )
                                    } */}
                                  </>
                                }
                              </dd>
                            ) : ( null )
                        }
                      </>
                  </dl>
              </>
            ) :
            (
              <div className="d-block w-100 text-center">
                <p className="justify-content-center p-4">Please wait until the page gets loaded...</p>
              </div>
            )
        }
      </>
    );
}

const DisplayBuilds = withRouter(connect(Props, Actions)(DisplayBuildsMethod));

export default DisplayBuilds;
