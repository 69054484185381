import { IPlate, IState } from "../Interfaces";

const getNewPlate = (state: IState) => {

    const build = state.data.workspace.state.build;

    const hasParts = Boolean(build.parts.length > 0);
    const parts = build.parts.map(part => {
        delete part['mesh'];
        return JSON.stringify(part);
    });

    const machineConfigId = typeof build.machineConfig === 'string' ? JSON.parse(build.machineConfig)['originalJson']['id'] :
                      typeof build.machineConfig === "object" ? build.machineConfig['originalJson']['id']  : null;
    const millConfigId = typeof build.millConfig === 'string' ? JSON.parse(build.millConfig)['originalJson']['id'] :
                       typeof build.millConfig === 'object' ? build.millConfig['originalJson']['id'] : null;
    const materialConfigId = typeof build.materialConfig === 'string' ? JSON.parse(build.materialConfig)['originalJson']['id'] : 
                             typeof build.materialConfig === 'object' ? build.materialConfig['originalJson']['id'] : null;
    const recipeId = typeof build.recipe === 'string' ? JSON.parse(build.recipe)['originalJson']['id'] :
                   typeof build.recipe === 'object' ? build.recipe['originalJson']['id'] : null;
    
    const newPlate = ({
        id: state.data.modals.newPlateModalStatus.inputText !=="" ? state.data.modals.newPlateModalStatus.inputText : state.data.plates.highlightedPlateId,
        name: state.data.modals.newPlateModalStatus.inputText !=="" ? state.data.modals.newPlateModalStatus.inputText : state.data.plates.highlightedPlateId,
        hasParts: hasParts,
        parts: parts,
        machineConfig: build.machineConfig ? JSON.stringify(build.machineConfig) : null,
        machineId: machineConfigId,
        millConfig: build.millConfig ? JSON.stringify(build.millConfig) : null,
        millConfigId: millConfigId,
        materialConfig: build.materialConfig ? JSON.stringify(build.materialConfig) : null,
        materialConfigId: materialConfigId,
        recipe: build.recipe ? JSON.stringify(build.recipe) : null,
        recipeId: recipeId,
        created_by: state.creator,
        created_at: new Date().toISOString(),
        modified_by: state.creator,
        modified_at: new Date().toISOString(),
        dumb: 1,
        plate_search_string: (state.data.modals.newPlateModalStatus.inputText !== "" && state.data.modals.newPlateModalStatus.inputText !== null) ? state.data.modals.newPlateModalStatus.inputText?.toLowerCase() : state.data.plates.highlightedPlateId?.toLowerCase()
    } as IPlate);

    return newPlate;
};

export default getNewPlate;