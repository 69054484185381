import { Mesh, Vector3, Quaternion, Euler } from 'three'
import { v4 as uuidv4 } from "uuid";

import { IWorkSpaceState, WorkspaceViewType } from "./WorkSpace";
import { IPartInfo } from "../../interfaces/IPartInfo";
import { IMatrix } from '../../interfaces/IMatrix';
import IRule, { ConfigType } from '../../interfaces/IRule'

const jsondiffpatch = require('jsondiffpatch').create();

function WorkspaceReducer(state: IWorkSpaceState, action: any) {
	let entityList = state.build.parts;
	entityList = entityList.concat(entityList.flatMap((entity: IPartInfo | IMatrix) => {
		if ("children" in entity) {
			return entity.children.map((part: IPartInfo) => part)
		}
		return entity;
	}));

	let entity = entityList.find(entity => entity.UUID === action.cargo?.entityUUID);

	const ruleUUID = action.cargo?.UUID;

	function setProperties(part: IPartInfo) {
		let partProps = action.cargo.properties
		if (partProps.translate) {
			part.properties.translate = partProps.translate
		}
		if (partProps.rotate) {
			part.properties.rotate = { x: partProps.rotate._x, y: partProps.rotate._y, z: partProps.rotate._z }
		}
		if (partProps.scale) {
			part.properties.scale = partProps.scale
		}
		if (partProps.DualZ) {
			part.properties.DualZ = partProps.DualZ
		}
	}

	function reorganizeLabels() {
		let matrixCounter = 0;
		let partCounter = 0;
		state.build.parts.forEach((entity: IPartInfo | IMatrix) => {
			if ("children" in entity) {
				matrixCounter++;
				const matrix = entity as IMatrix;
				matrix.matrixIndex = matrixCounter;
				matrix.children.forEach((part: IPartInfo) => part.matrixAbbreviature = "M" + matrixCounter.toString() + "_");
			} else {
				partCounter++;
				const part = entity as IPartInfo;
				part.customLabel = "Part " + partCounter;
			}
		});
	}

	switch (action.type) {
		case "setBuild":
			state.build = action.cargo.item;
			break;
		case "setMachine":
			state.build.machineConfig = {
				originalJson: { ...action.cargo.item },
				resultJson: { ...action.cargo.item }
			};
			delete state.build.machineConfig.diff
			break;
		case "updateMachine":
			state.build.machineConfig.diff = jsondiffpatch.diff(state.build.machineConfig?.originalJson, action.cargo.item);
			state.build.machineConfig.resultJson = { ...action.cargo.item }
			break;
		case "updateMill":
			state.build.millConfig.diff = jsondiffpatch.diff(state.build.millConfig?.originalJson, action.cargo.item);
			state.build.millConfig.resultJson = { ...action.cargo.item }
			break;
		case "updateRecipe":
			state.build.recipe.diff = jsondiffpatch.diff(state.build.recipe?.originalJson, action.cargo.item);
			state.build.recipe.resultJson = { ...action.cargo.item }
			break;
		case "updateMaterial":
			state.build.materialConfig.diff = jsondiffpatch.diff(state.build.materialConfig?.originalJson, action.cargo.item);
			state.build.materialConfig.resultJson = { ...action.cargo.item }
			break;
		case "updatePartConfig":
			(entity as IPartInfo).properties.PartConfig.diff = jsondiffpatch.diff((entity as IPartInfo).properties.PartConfig?.originalJson, action.cargo.item);
			(entity as IPartInfo).properties.PartConfig.resultJson = { ...action.cargo.item }
			break;
		case "patchPartConfig":
			if (entity) {
				const partConfig = (entity as IPartInfo).properties.PartConfig;
				const receivedConfig = action.cargo.item;
				partConfig.originalJson = { ...receivedConfig };
				if (receivedConfig) {
					partConfig.resultJson = jsondiffpatch.patch({ ...receivedConfig }, partConfig.diff)
				}
			}
			break;

		case "transform":
			setProperties(entity as IPartInfo);
			break;
		case "addPart":
			state.build.parts.push({
				buildErrors: false,
				UUID: uuidv4(),
				properties: {
					SlicerConfig: "undefined",
					PartID: action.cargo.PartID,
				},
			} as any);
			reorganizeLabels();
			break;
		case "removePart":

			state.build.parts = state.build.parts.filter(filtered => filtered['properties'].PartID !== action.cargo.PartID);
			// if( entity && "parentUUID" in entity ){
			// 	const part = entity as IPartInfo;
			// 	const parent = entityList.find( (ent) => ent.UUID === part.parentUUID ) as IMatrix;
			// 	if( parent ){
			// 		parent.children = parent.children.filter( child => child !== entity );
			// 	}
			// 	}else{
			// 		state.build.parts = state.build.parts.filter( filtered => filtered !== entity );
			// 	}
			reorganizeLabels();
			break;
		case "changeMesh":
			if (entity && "mesh" in entity) {
				const part = entity as IPartInfo;
				delete entity.mesh
				part.properties.PartID = action.cargo.PartID;
			}
			break;
		case "deletePart":
			if (entity && "parentUUID" in entity) {
				const part = entity as IPartInfo;
				const parent = entityList.find((ent) => ent.UUID === part.parentUUID) as IMatrix;
				if (parent) {
					parent.children = parent.children.filter(child => child !== entity);
				}
			} else {
				state.build.parts = state.build.parts.filter(filtered => filtered !== entity);
			}
			reorganizeLabels();
			break;

		case "createMatrixFromPart":
			let matrix: IMatrix = {
				UUID: uuidv4(),
				vizualizationGroup: null,
				matrixIndex: -1,
				originalPart: entity as IPartInfo,
				baseMesh: (entity as IPartInfo).mesh!,
				baseSlicerConfig: (entity as IPartInfo).properties.SlicerConfig,
				basePartConfig: (entity as IPartInfo).properties.PartConfig,
				columns: 4,
				columnGap: 2.5,
				rows: 3,
				rowGap: 2.5,
				children: [],
				configRules: [],
			};
			state.build.parts = state.build.parts.filter(filtered => filtered != entity);
			state.selectedMatrix = matrix;
			break;
		case "updateSelectedMatrix":
			state.selectedMatrix = action.cargo.updatedMatrix;
			break;
		case "saveMatrix":
			const matrixEntity = state.selectedMatrix!;
			matrixEntity.children = [];
			const matrixGroup = matrixEntity!.vizualizationGroup!;
			for (let row = 0; row < matrixEntity.rows; row++) {
				for (let column = 0; column < matrixEntity.columns; column++) {
					const columnLetter = String.fromCharCode('A'.charCodeAt(0) + column);
					const meshIndex = row * matrixEntity.columns + column;
					const childMesh = matrixGroup.children[meshIndex];

					let matrixPart: IPartInfo = {
						buildErrors: false,
						parentUUID: matrixEntity!.UUID,
						matrixAbbreviature: "",
						customLabel: columnLetter + (row + 1).toString(),
						UUID: uuidv4(),
						mesh: childMesh as Mesh,
						properties: {
							SlicerConfig: {},
							PartConfig: {},
							PartID: childMesh.userData.PartID,
							translate: new Vector3(),
							rotate: new Vector3(),
							DualZ: 0,
							scale: new Vector3(1, 1, 1),
						},
						userData: {
							intersectsOtherMesh: false,
							outsideBuildEnvelope: false
						},
						selected: false,
					}

					childMesh.userData.UUID = matrixPart.UUID;

					let absolutePosition = new Vector3();
					childMesh.getWorldPosition(absolutePosition);
					childMesh.position.set(absolutePosition.x, absolutePosition.y, absolutePosition.z);
					matrixPart.properties.translate = absolutePosition;

					let absoluteQuartenion = new Quaternion();
					childMesh.getWorldQuaternion(absoluteQuartenion);
					let tempEuler = new Euler().setFromQuaternion(absoluteQuartenion);
					matrixPart.properties.rotate = new Vector3(tempEuler.x, tempEuler.y, tempEuler.z);
					childMesh.rotation.setFromQuaternion(absoluteQuartenion);

					matrixPart.properties.PartConfig = JSON.parse(JSON.stringify(matrixEntity!.basePartConfig));
					matrixPart.properties.SlicerConfig = Object.assign({}, matrixEntity!.baseSlicerConfig);

					for (let rule of matrixEntity.configRules) {
						let assignDest;
						if (rule.configType === ConfigType.PartConfig) {
							assignDest = matrixPart.properties.PartConfig.resultJson;
						} else {
							assignDest = matrixPart.properties.PartConfig.SlicerPatch;
							if (!assignDest) {
								matrixPart.properties.PartConfig.SlicerPatch = {};
								assignDest = matrixPart.properties.PartConfig.SlicerPatch;
							}
						}
						applyRule(assignDest, rule, meshIndex);

					}
					matrixPart.properties.PartConfig.diff = jsondiffpatch.diff(matrixPart.properties.PartConfig.originalJson, matrixPart.properties.PartConfig.resultJson);
					matrixEntity?.children.push(matrixPart);
				}
			}
			state.build.parts.push(matrixEntity!);
			state.selectedMatrix = null;
			reorganizeLabels();
			break;

		case 'updateRule':
			const foundRuleIndex = state.selectedMatrix!.configRules.findIndex((el: IRule) => el.UUID === ruleUUID);
			if (foundRuleIndex !== -1) {
				state.selectedMatrix!.configRules[foundRuleIndex] = action.cargo.updatedRule;
			} else {
				state.selectedMatrix!.configRules.push(action.cargo.updatedRule);
			}
			break;
		case 'deleteRule':
			const filtredRules = state.selectedMatrix!.configRules.filter(rule => rule.UUID !== ruleUUID)
			state.selectedMatrix!.configRules = filtredRules;
			break
		case 'addLoading':
			state.showLoading.push(action.cargo.uuidLoading);
			break;
		case 'removeLoading':
			state.showLoading = state.showLoading.filter(el => el !== action.cargo.uuidLoading);
			break;
		case 'showLoading':
			state.reviewLoading = action.cargo.value;
			break;
		case 'hideLoading':
			state.reviewLoading = action.cargo.value;
			break;
		case 'adddefult':
			state.build["created_by"] = action.cargo.initiator;
			state.build["created_at"] = new Date().toISOString();
			state.build["id"] = action.cargo.name;
			state.build["name"] = action.cargo.name;
			state.build["dumb"] = 1;
			state.build["Min_Layer"] = -1;
			state.build["Max_Layer"] = -1;
			state.build["Max_Slab"] = -1;
			state.build["Contouring"] = true;
			state.build["overrides"] = [];
			state.build["current_status"] = "new";
			break;
		case 'setPrinter':
			state.selectedPrinter = action.cargo.item;
		case 'switchView':
			let newState = WorkspaceViewType.SceneView;
			if (state.viewState === newState) {
				newState = WorkspaceViewType.GrafanaView
			}
			state.viewState = newState;
			break;
	}

	return { ...state };
}

function applyRule(destination: Object, rule: IRule, index: number) {
	let path = rule.propertyPath.split('/');
	let parentElement = destination;

	for (let i = 0; i < path.length; i++) {
		const subPath = path[i];
		if (i === path.length - 1) {
			Object.assign(parentElement, { [subPath]: rule.values[index] });
		} else {
			parentElement = parentElement[subPath];
		}
	}
}

export default WorkspaceReducer;
