import { Storage } from 'aws-amplify';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import ACTION_TYPES from '../../../actionTypes';
import { IBuild, IState, } from '../../../Interfaces';
import onResetSearch from "../../Search/reset"
import onGetHighlightedBuild from "../../Search/highlightBuild";
import { NotificationManager } from "react-notifications";

const onDownloadGcodeRequest = () => {
    return {
        type: ACTION_TYPES.HOME.DOWNLOAD.GCODE.REQUEST,
        payload: null,
    };
};

const onDownloadGcodeStart = (contentLength: number) => {
    return {
        type: ACTION_TYPES.HOME.DOWNLOAD.GCODE.START,
        payload: contentLength,
    };
};

const onDownloadGcodeInProgress = (processedLength: number) => {
    return {
        type: ACTION_TYPES.HOME.DOWNLOAD.GCODE.IN_PROGRESS,
        payload: processedLength,
    };
};

const onDownloadGcodeSuccess = (receivedLength: number, contentLength: number) => {
    return {
        type: ACTION_TYPES.HOME.DOWNLOAD.GCODE.SUCCESS,
        payload: {
            receivedLength,
            contentLength,
        },
    };
};

const onDownloadGcodeFail = (receivedLength: number, contentLength: number) => {
    NotificationManager.error('Failure on Downloading Gcode');
    console.error('Failure on Downloading Gcode - receivedLength', receivedLength, 'contentLength', contentLength);
    return {
        type: ACTION_TYPES.HOME.DOWNLOAD.GCODE.FAIL,
        payload: {
            receivedLength,
            contentLength,
        },
    };
};

const onDownloadGcodeDone = () => {
    return {
        type: ACTION_TYPES.HOME.DOWNLOAD.GCODE.DONE,
        payload: null,
    };
};

const onDownloadGcode = (buildId) => {
    return async (dispatch, getState) => {

        dispatch(onDownloadGcodeRequest());

        try {
            dispatch(onDownloadGcodeStart(1024));
            const state = getState() as IState;
           

            const selectedBuild = !state.data.search.isSearchEnabled ? (state.data.builds.loadedBuilds.find(
                (b) => b.id === buildId
            ) as IBuild) : state.data.search.highlightedBuildData as IBuild

            const gcodePath = `${selectedBuild.bundle.replace('public/', '')}${selectedBuild.name}_Lava.gcode`;
            const result = await Storage.get(gcodePath, { download: true,  cacheControl: 'no-cache' });
            dispatch(onDownloadGcodeInProgress(1024));
            const fileName = gcodePath.slice(selectedBuild.bundle.replace('public/', '').length);
            const data = (result as any).Body;
            const zip = new JSZip();
            zip.file(fileName, data);
            const content = await zip.generateAsync({ type: 'blob' });
            const name = selectedBuild.name + '.zip';
            saveAs(content, name, 'application/zip');
            dispatch(onDownloadGcodeSuccess(1024, 1024));
            // if (state.data.search.isSearchEnabled) {
            //     dispatch(onResetSearch())
            // }
        }
        catch (error) {
            dispatch(onDownloadGcodeFail(1024, 1024));
        }
        dispatch(onDownloadGcodeDone());
    };
};

export default onDownloadGcode;