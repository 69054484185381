import { IState } from "../Interfaces";
import { Storage } from "aws-amplify";

const getNewBuild = async (state: IState) => {
  let partScaledVolume: any = state.data.plates.partsScaledVolume;
  let partScaledArea:any =state.data.plates.partsScaledArea;
  let newBuild: any = state.data.workspace.state.build;
  newBuild["id"] = state.data.modals.newBuildModalStatus.inputText;
  newBuild["name"] = state.data.modals.newBuildModalStatus.inputText;
  newBuild["build_search_string"] = state.data.modals.newBuildModalStatus.inputText.toLowerCase();
  // const fileName = newBuild["id"] + '.json';
  // let path = `BuildPartsFiles/` + fileName;
  // let AWSBucketParam = {
  //   Bucket: Storage["_config"]["AWSS3"]["bucket"],
  //   Key: path

  // }
  // const beforeSavingPartsToBuildPartsFiles = state.data.workspace.state.build.parts.map((part) => {
  //   delete part["mesh"];
  //   return JSON.stringify(part);
  // });
  // try {
  //   debugger;
  //   await Storage.put(path, beforeSavingPartsToBuildPartsFiles, {
  //     contentType: "application/json", // contentType is optional
  //     completeCallback: (event) => {
  //       console.log(`Successfully uploaded ${event.key}`);
  //     },
  //     progressCallback: (progress) => {
  //       console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
  //     },
  //     errorCallback: (err) => {
  //       console.error('Unexpected error while uploading', err);
  //     }
  //   });
  //   newBuild.parts = JSON.stringify(AWSBucketParam)
  // } catch (error) {
  //   console.log("Error uploading file: ", error);
  // }

  
 

  let sum_triangles=0
  let sum_volume =0
  let sum_area =0
 
  state.data.workspace.addedParts?.filter((p) => p.metrics && p.metrics[0]).map((part) => (
     sum_triangles += JSON.parse(part.metrics[0]).triangles,
     sum_volume += JSON.parse(part.metrics[0]).volume,
     sum_area += JSON.parse(part.metrics[0]).area
  ))
  newBuild["metrics"] = JSON.stringify({
    sum_triangles: sum_triangles,
    sum_volume: sum_volume,
    sum_area: sum_area,
    scaled_volume: sum_volume * partScaledVolume,
    scaled_area: sum_area * partScaledArea
  })
  
  const plateToBeUpdated = state.data.plates.loadedPlates.find(
    (lp) => lp.id === state.data.plates.highlightedPlateId
  );

  newBuild["hasPlates"] = state.data.plates.loadedPlates.some(
    (lp) => lp.id === state.data.plates.highlightedPlateId
  ) as boolean;

  newBuild["plates"] = JSON.stringify(plateToBeUpdated);
  newBuild.machineConfig = JSON.stringify(
    state.data.workspace.state.build.machineConfig
  );
  newBuild.millConfig = JSON.stringify(
    state.data.workspace.state.build.millConfig
  );
  newBuild.materialConfig = JSON.stringify(
    state.data.workspace.state.build.materialConfig
  );
  newBuild.recipe = JSON.stringify(state.data.workspace.state.build.recipe);
  newBuild["bundle"] = JSON.stringify(
    state.data.workspace.state.build.bundles &&
      state.data.workspace.state.build.bundles.length > 0
      ? state.data.workspace.state.build.bundles[0]
      : {}
  );
  newBuild["created_at"] = new Date().toISOString();
  newBuild["created_by"] = state.creator;
  delete newBuild["bundles"];

  return newBuild;
};

export default getNewBuild;
