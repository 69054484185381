import React, { useEffect, useRef, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import {
  NewBuild,
  MoveBuild,
  DisplayBuildListView,
  DisplayBuildOptions,
} from "../..";
import {
  onShowRecentBuilds,
  onHideRecentBuilds,
  onShowAllBuilds,
  onHideAllBuilds,
  onShowArchivedBuilds,
  onHideArchivedBuilds,
  onSelectBuild,
  onUnselectBuild,
  onUnselectBuilds,
} from "../../../Actions/Builds";
import {
  onUnselectFolder,
  onLoadBuilds,
  onNavigateToBuildReviewPage,
} from "../../../Actions/Home";
import { SortDirection } from "../../../Enums";
import {
  onSearchBuildsTextChange,
  onSortBuildsAsc,
  onSortBuildsDesc,
} from "../../../Actions/Builds";
import { IDisplayFolder, IDisplayBuild, IState } from "../../../Interfaces";
import { getDisplayFolders, getDisplayBuilds } from "../../../Mappers";
import DisplayDashboardBuildCardView from "./DisplayDashboardBuildCardView";
import DisplayMoreBuilds from "./DisplayMoreBuilds";
import NewBuildSuccess from "../../NewBuildSuccess";
import { DataGrid } from "@material-ui/data-grid";
import { Auth } from "aws-amplify";
import { NotificationManager } from "react-notifications";
import LoadingComponent from "../../../../LoadingComponent/LoadingComponent";

const Props = (state: IState, ownProps: any) => {
  const displayFolders = getDisplayFolders(state) as IDisplayFolder[];

  const totalFolders = (
    state.data.folders.sortDirection === SortDirection.ASC
      ? displayFolders.reverse()
      : displayFolders
  ) as IDisplayFolder[];

  const filteredFolders = totalFolders.filter(
    (df) => df.isFiltered
  ) as IDisplayFolder[];

  const selectedFolders = filteredFolders.filter(
    (ff) => ff.isSelected
  ) as IDisplayFolder[];

  const recentFolders = filteredFolders.filter(
    (ff) => !ff.isSelected && ff.isRecent
  ) as IDisplayFolder[];

  const allFolders = filteredFolders.filter(
    (ff) => !ff.isSelected && !ff.isArchived
  ) as IDisplayFolder[];

  const archivedFolders = filteredFolders.filter(
    (ff) => !ff.isSelected && ff.isArchived
  ) as IDisplayFolder[];

  const hasSelectedFolders = Boolean(selectedFolders.length > 0) as boolean;

  const displayBuilds = getDisplayBuilds(state) as IDisplayBuild[];

  const totalBuilds = (
    state.data.builds.sortDirection === SortDirection.ASC
      ? displayBuilds.reverse()
      : displayBuilds
  ) as IDisplayBuild[];

  const filteredBuilds = totalBuilds.filter(
    (db) => db.isFiltered
  ) as IDisplayBuild[];

  const selectedBuilds = filteredBuilds.filter(
    (fb) => fb.isSelected
  ) as IDisplayBuild[];

  const recentBuilds = filteredBuilds.filter(
    (fb) => !fb.isSelected && !fb.isArchived && fb.isRecent
  ) as IDisplayBuild[];

  const allBuilds = filteredBuilds.filter(
    (fb) => !fb.isSelected && !fb.isArchived
  ) as IDisplayBuild[];

  const archivedBuilds = filteredBuilds.filter(
    (fb) => !fb.isSelected && fb.isArchived
  ) as IDisplayBuild[];

  const hasSelectedBuilds = Boolean(selectedBuilds.length > 0) as boolean;

  const showSelectedBuilds = Boolean(
    state.data.sections.recentBuilds.showItems
  ) as boolean;
  const showRecentBuilds = Boolean(
    state.data.sections.recentBuilds.showItems
  ) as boolean;
  const showAllBuilds = Boolean(
    state.data.sections.allBuilds.showItems
  ) as boolean;
  const showArchivedBuilds = Boolean(
    state.data.sections.archivedBuilds.showItems
  ) as boolean;

  const props = {
    showListView: state.data.info.showListView as boolean,
    filteredFolders: filteredFolders as IDisplayFolder[],
    selectedFolders: selectedFolders as IDisplayFolder[],
    recentFolders: recentFolders as IDisplayFolder[],
    allFolders: allFolders as IDisplayFolder[],
    archivedFolders: archivedFolders as IDisplayFolder[],
    hasSelectedFolders: hasSelectedFolders as Boolean,
    filteredBuilds: filteredBuilds as IDisplayBuild[],
    selectedBuilds: selectedBuilds as IDisplayBuild[],
    recentBuilds: recentBuilds as IDisplayBuild[],
    allBuilds: allBuilds as IDisplayBuild[],
    archivedBuilds: archivedBuilds as IDisplayBuild[],
    hasSelectedBuilds: hasSelectedBuilds as Boolean,
    showSelectedBuilds: showSelectedBuilds as Boolean,
    showRecentBuilds: showRecentBuilds as Boolean,
    showAllBuilds: showAllBuilds as Boolean,
    showArchivedBuilds: showArchivedBuilds as Boolean,
    loadedBuilds: state.data.builds.loadedBuilds,
    sortDirection: state.data.builds.sortDirection,
    nextToken: state.data.builds.nextToken,
    selectedBuildIds: state.data.builds.selectedBuildIds || [],
    creator: state.creator,
    isLoadingComplete: state.data.builds.isLoadingComplete
  };

  return props;
};

const Actions = {
  onUnselectFolder: onUnselectFolder,
  onShowRecentBuilds: onShowRecentBuilds,
  onHideRecentBuilds: onHideRecentBuilds,
  onShowAllBuilds: onShowAllBuilds,
  onHideAllBuilds: onHideAllBuilds,
  onShowArchivedBuilds: onShowArchivedBuilds,
  onHideArchivedBuilds: onHideArchivedBuilds,
  onLoadBuilds: onLoadBuilds,
  onSearchBuildsTextChange: onSearchBuildsTextChange,
  onSortBuildsAsc: onSortBuildsAsc,
  onSortBuildsDesc: onSortBuildsDesc,
  onSelectBuild: onSelectBuild,
  onUnselectBuild: onUnselectBuild,
  onUnselectBuilds: onUnselectBuilds,
};

const BuildSectionComponent = (props) => {
  const isSorting = useRef(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const isConfigDisabled = useRef(false);
  const [selectedList, setSelectedList] = useState<any>([]);
  const loadMore = () => {
    props.onLoadBuilds();
  };

  useEffect(() => {
    isSorting.current = false;
    Auth.currentAuthenticatedUser().then((user) => {
      const groups =
        user.signInUserSession.accessToken.payload["cognito:groups"] || [];
      if (groups[0] == "Operator") {
        isConfigDisabled.current = true;
      } else {
        isConfigDisabled.current = false;
      }
    });
  }, []);

  useEffect(() => {
    if (isSorting.current !== props.sorted) {
      if (props.sorted) {
        if (props.sortDirection !== SortDirection.ASC) {
          props.onSortBuildsAsc();
          props.onLoadBuilds(true);
        }
      } else {
        if (props.sortDirection !== SortDirection.DESC) {
          props.onSortBuildsDesc();
          props.onLoadBuilds(true);
        }
      }
    }
  }, [props.sorted]);

  useEffect(() => {
    if (!props.selectedBuildIds.length) {
      setSelectedList([]);
    } else {
      setSelectedList([...props.selectedBuildIds]);
    }
  }, [props.showListView, props.selectedBuildIds]);

  const handleListRowClick = (params) => {
    if (
      params.row.hasOwnProperty("parts") &&
      params.row.hasOwnProperty("plates")
    ) {
      if (
        params.row.current_status &&
        (params.row.current_status.toLowerCase().includes("completed") ||
          (params.row.current_status.toLowerCase().includes("fail") &&
            !isConfigDisabled.current))
      ) {
        dispatch(onNavigateToBuildReviewPage(params.row.id, history));
      }
    }
  };

  const listViewRows = props.filteredBuilds.map((item) => {
    item.createdAt = (new Date(item.createdAt) as Date).toLocaleString();
    return item;
  });

  const buildListView = () => {
    const listViewColumns = [
      {
        field: "type",
        headerName: "Type",
        width: 150,
        sortable: false,
        renderCell: (params) => {
          if (
            params.row.hasOwnProperty("parts") &&
            params.row.hasOwnProperty("plates")
          ) {
            return <img className="icon build" alt="build" />;
          } else if (
            params.row.hasOwnProperty("parts") &&
            !params.row.hasOwnProperty("plates")
          ) {
            return <img className="icon plate" alt="plate" />;
          } else {
            return <img className="icon part" alt="part" />;
          }
        },
      },
      {
        field: "name",
        headerName: "Name",
        width: 400,
        sortable: true,
      },
      {
        field: "createdAt",
        headerName: "Imported Date",
        width: 250,
        sortable: true,
      },
      {
        field: "creator",
        headerName: "Owner",
        width: 300,
        sortable: false,
      },
      {
        field: "status",
        headerName: "Status",
        width: 350,
        sortable: false,
        renderCell: (params) => {
          let buildProgress = 0;
          if (
            params.row.current_status === "processing" ||
            params.row.current_status === "Progress"
          ) {
            buildProgress = parseFloat(
              params.row.build_result.replace("%", "").split(" ")[1]
            );
            if (isNaN(buildProgress)) {
              buildProgress = 0;
            }
          }
          if (
            params.row.hasOwnProperty("parts") &&
            params.row.hasOwnProperty("plates")
          ) {
            if (
              params.row.current_status === "completed" ||
              params.row.status === "completed"
            ) {
              return (
                <span className="capitalise-text font-weight-bold">
                  <img
                    src="/img/slicing-completed.svg"
                    alt="Completed Status Icon"
                    className="list-view-build-img"
                  />
                  {" Slicing Completed"}
                </span>
              );
            }
            if (
              params.row.current_status === "failed" ||
              params.row.status === "failed"
            ) {
              return (
                <span className="capitalise-text font-weight-bold">
                  <img
                    src="/img/slicing-failed.svg"
                    alt="Failed Status Icon"
                    className="list-view-build-img"
                  />
                  {" " + "Slicing Failed"}
                </span>
              );
            }
            if (
              params.row.current_status === "canceled" ||
              params.row.status === "canceled"
            ) {
              return (
                <span className="capitalise-text font-weight-bold">
                  <img
                    src="/img/slicing-failed.svg"
                    alt="Canceled Status Icon"
                    className="list-view-build-img"
                  />
                  {" " + "Slicing Canceled"}
                </span>
              );
            }
            if (
              params.row.current_status === "processing" ||
              params.row.current_status === "waiting" ||
              params.row.status === "processing" ||
              params.row.status === "waiting" ||
              params.row.current_status === "Progress"
            ) {
              return (
                <div className="progress-flex-section">
                  {params.row.current_status === "processing" ||
                  params.row.current_status === "Progress" ? (
                    <span className="pl-0">{`${buildProgress}%`}</span>
                  ) : (
                    <span className="pl-0">Initializing Build</span>
                  )}
                  <ProgressBar variant="success" now={buildProgress} />
                </div>
              );
            }
          }
        },
      },
      {
        field: "folder",
        headerName: "Folder",
        width: 200,
        sortable: false,
      },
      {
        field: "actions",
        headerName: "Actions",
        width: 100,
        sortable: false,
        renderCell: (params) => {
          return (
            <div
              className="display-list-more-action"
              onClick={(e) => e.stopPropagation()}
            >
              {<DisplayBuildOptions displayBuild={params.row} />}
            </div>
          );
        },
      },
    ];
    return (
      <>
        <dl className="grid-view">
          <dd>
            <>
              {/* <div className="display-list">
								<div className="display-list-row">
									<div className="display-list-header">
										<label>Type</label>
									</div>
									<div className="display-list-header">
										<label>Name</label>
									</div>
									<div className="display-list-header">
										<label>Build Date</label>
									</div>
									<div className="display-list-header">
										<label>Owner</label>
									</div>
									<div className="display-list-header">
										<label>Status</label>
									</div>
									<div className="display-list-header">
										<label>Folder</label>
									</div>
									<div className="display-list-header">
										<label>Actions</label>
									</div>
								</div>
							</div>
						</>
						<>
							{
								this.props.filteredBuilds.map((fb, i) => {
									return (
										<DisplayBuildListView
											{...{
												key: i,
												index: i,
												displayBuild: fb,
												isSelected: fb.isSelected,
												onUnselect: this.props.onUnselect,
												onSelect: this.props.onSelect,
											}}
										/>
									)
								})
							} */}
              <DataGrid
                columns={listViewColumns}
                rows={listViewRows}
                autoHeight
                autoPageSize
                disableSelectionOnClick
                disableColumnMenu
                className="build-grid-view"
                hideFooterPagination
                onRowClick={handleListRowClick}
                checkboxSelection
                onSelectionModelChange={(id) => {
                  handleCheckBoxSelect(id);
                }}
                selectionModel={selectedList} //using state variable for checked items
                // getRowClassName={getRowClassName}
              />
            </>
          </dd>
        </dl>
      </>
    );
  };

  const handleCheckBoxSelect = (ids) => {
    let selectedIds = ids;
    const created_by =
      listViewRows.filter((row) => row.id.toString() === ids[ids.length - 1])[0]
        ?.created_by ||
      listViewRows.filter((row) => row.id.toString() === ids[ids.length - 1])[0]
        ?.creator ||
      ""; // get created value for recently selected item.
    // find removed elements
    const removedItem = selectedList.filter((item) => ids.indexOf(item) === -1);

    if (removedItem.length) {
      const removedData = listViewRows.filter(
        (item) => item.id === removedItem[0]
      );
      if (removedData.length) {
        props.onUnselectBuild(removedData[0].id);
      }
    } else {
      const findItem = listViewRows.filter(
        (item) => item.id === ids[ids.length - 1]
      );
      if (created_by !== props.creator) {
        NotificationManager.error(
          "You lack the authorization to delete builds created by other users."
        );
        selectedIds.pop(); // remove recently selected item from the grid
      } else {
        props.onSelectBuild(findItem[0].id);
      }
    }
    if (!ids.length) {
      props.onUnselectBuilds();
    }
    setSelectedList(selectedIds);
  };

  const buildCardView = () => {
    return (
      <>
        {props.hasSelectedFolders
          ? props.selectedFolders.map((selectedFolder) =>
              selectedFolderView(selectedFolder)
            )
          : buildsView()}
      </>
    );
  };

  const selectedFolderView = (selectedFolder) => {
    return (
      <>
        {/* <Breadcrumb>

					<Breadcrumb.Item
						onClick={() => this.props.onUnselectFolder(selectedFolder["id"])}
					>
						Builds
					</Breadcrumb.Item>
					<Breadcrumb.Item active>
						{selectedFolder["name"]}
					</Breadcrumb.Item>
				</Breadcrumb> */}
        <dl className={`${props.showListView ? "grid-view" : ""}`}>
          <dd>
            <div className="options left-justify mt-3 w-100">
              <div className="back option">
                <div
                  className="backIcon"
                  onClick={() => props.onUnselectFolder(selectedFolder["id"])}
                ></div>
                <label>{selectedFolder["name"]}</label>
              </div>
            </div>
            {props.showListView ? (
              <>
                <div className="display-list">
                  <div className="display-list-row">
                    <div className="display-list-header">
                      <label>Type</label>
                    </div>
                    <div className="display-list-header">
                      <label>Name</label>
                    </div>
                    <div className="display-list-header">
                      <label>Build Date</label>
                    </div>
                    <div className="display-list-header">
                      <label>Owner</label>
                    </div>
                    <div className="display-list-header">
                      <label>Status</label>
                    </div>
                    <div className="display-list-header">
                      <label>Folder</label>
                    </div>
                    <div className="display-list-header">
                      <label>Actions</label>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
            {selectedFolder["displayBuilds"]
              .filter((db) =>
                db.folders.some((f) => f === selectedFolder["name"])
              )
              .map((db, i) =>
                props.showListView ? (
                  <DisplayBuildListView key={i} displayBuild={db} />
                ) : (
                  <DisplayDashboardBuildCardView key={i} displayBuild={db} />
                )
              )}
          </dd>
        </dl>
      </>
    );
  };

  const buildsView = () => {
    return (
      <>
        <DisplayMoreBuilds
          {...{
            label: false,
            displayFolders: props.allFolders,
            showOptions: false,
            showItems: true,
            filter: props.filter,
            onShowItems: props.onShowAllBuilds,
            onHideItems: props.onHideAllBuilds,
            isDoneBatchDelete: props.isDoneBatchDelete,
            resetBatchDelete: props.resetBatchDelete
          }}
        />
      </>
    );
  };

  return (
    <div>
      <>
        <LoadingComponent visible={!props.isLoadingComplete} />
        {props.showListView ? buildListView() : buildCardView()}
        {props.nextToken && !props.hasSelectedFolders && (
          <div className="d-block w-100 text-center pb-4">
            <button
              onClick={() => loadMore()}
              className="btn btn-primary justify-content-center"
            >
              Load More Builds<span className="btn-right-arrow"></span>
            </button>
          </div>
        )}
        {/* {
					(this.props.filteredBuilds && this.props.filteredBuilds?.length && this.props.hasSelectedFolders ) && <div className="d-block w-100 text-center">
					<p className="justify-content-center p-4">
						No data to load</p>
				</div>
				} */}
        <NewBuild />
        <NewBuildSuccess />
        <MoveBuild />
      </>
    </div>
  );
};

const BuildsSection = withRouter(
  connect(Props, Actions)(BuildSectionComponent)
);

export default BuildsSection;
