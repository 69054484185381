import { any } from "prop-types";
import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function MVPBuildToolReviewModal(props) {
  const { showModal, hideModal, selectedPrinterObj, estimatedToolLifeNeeded ,toolsDetails } =
    props;
  const toolValue = props.buildTool[0];
  const remainingLife = toolsDetails !== null ? (toolsDetails.filter((el)=>  parseInt(el.ToolId.substring(1)) === parseInt(toolValue.ToolNumber))) : null
 
  const toolLifeDetails =
    (selectedPrinterObj !== undefined && selectedPrinterObj !== null) ? selectedPrinterObj.length !== 0 
      ? JSON.parse(selectedPrinterObj).filter(
          (printer: any) => printer.ToolId.slice(1) == toolValue.ToolNumber
        )
      : null :null;
  const toolLifeNeeded = estimatedToolLifeNeeded?.filter(
    (e: any) => e.number === toolValue.ToolNumber
  );
  const toolListUsed = remainingLife !==null
    ? Math.floor(
        100 *
          (parseFloat(remainingLife[0]?.TLU) /
            parseFloat(remainingLife[0]?.TLM)) *
          100
      ) / 100
    : 0;
  function getToolName(toolNumber) {
    switch (toolNumber) {
      case 1:
        return "Chip Fan";
      case 3:
        return "3/8\" end mill";
      case 5:
        return "1/4\" end mill";
      case 7:
        return "1/16\" end mill";
      case 9:
        return "0.020\" ball end mill";
      case 11:
        return "0.020\" end mill";
      case 13:
        return "1/8\" end mill";
      case 15:
        return "Reference tool";
      case 17:
        return "0.006\" ball end mill";
      case 19:
        return "0.010\" ball end mill";
      default:
        return "-";
    }
  }

  function secondsToDhms(seconds) {
    seconds = Number(seconds);
    let d = Math.floor(seconds / (3600 * 24));
    let h = Math.floor((seconds % (3600 * 24)) / 3600);
    let m = Math.floor((seconds % 3600) / 60);
    let s = Math.floor(seconds % 60);
    // console.log(d, h, m, s)
    let dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
    let hDisplay = h > 0 ? h + (h == 1 ? " hr, " : " hr, ") : "";
    let mDisplay = m > 0 ? m + (m == 1 ? " min, " : " min ") : "";
    //  let sDisplay = s > 0 ? s + (s == 1 ? " sec" : " sec") : ""
    return dDisplay + hDisplay + mDisplay;
  }

  return (
    <Modal centered show={showModal} onHide={hideModal}>
      <Modal.Header
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: "1px solid #DBDBDB",
        }}
      >
        <Modal.Title>Tool Configuration</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          display: "flex",

          flexDirection: "column",
        }}
        className="modal-body"
      >
        <h3>
          #{toolValue.ToolNumber} - {getToolName(toolValue.ToolNumber)}
        </h3>
        <div className="tool-life-details">
          <div>
            <label className="title"> Remaining Tool Life:</label>
            {/* //<label className="value">{`${toolLifeDetails[0].TLR} / ${toolLifeDetails[0].TLM}`}</label> */}
            <label className="value">
              {remainingLife !== null && remainingLife[0] ? (
                <>
                  <span
                    style={
                      toolListUsed <= 25
                        ? { color: "#198754" }
                        : toolListUsed <= 75
                        ? { color: "#fd7e14" }
                        : { color: "#dc3545" }
                    }
                  >
                    {secondsToDhms(remainingLife[0]?.TLR)} 
                  </span>
                  <label className="title">
                  </label>
                </>
              ) : (
                "Not available"
              )}
            </label>
          </div>
          <div>
            <label className="title">Estimated Tool Life Needed:</label>
            <label className="value">
              {toolLifeNeeded == '' || toolLifeNeeded == undefined ? "Not Available":toolLifeNeeded[0]?.seconds > 59 ? secondsToDhms(toolLifeNeeded[0]?.seconds) : (toolLifeNeeded[0]?.seconds)?.toFixed() +' seconds'}
              </label>
          </div>
        </div>
        <ul className="tool-configuration-list">
          <li>
            <span>Shoulder Length</span>
            <span>{toolValue.ShoulderLength ? toolValue.ShoulderLength:'Not available'}</span>
          </li>
          <li>
            <span>Chip Load</span>
            <span>{toolValue.ChipLoad ? toolValue.ChipLoad :'Not available'}</span>
          </li>
          <li>
            <span>Flute Count</span>
            <span>{toolValue.FluteCount ? toolValue.FluteCount: 'Not available'}</span>
          </li>
          <li>
            <span>Shaft Diameter</span>
            <span>{toolValue.ShaftDiam ? toolValue.ShaftDiam :'Not available'}</span>
          </li>
          <li>
            <span>Spindle RPM</span>
            <span>{toolValue["Spndl-Rpm"] ? toolValue["Spndl-Rpm"]: 'Not available'}</span>
          </li>
          <li>
            <span>Feed Rate</span>
            <span>{toolValue.Feedrate ? toolValue.Feedrate :'Not available'}</span>
          </li>
          <li>
            <span>Flute Length</span>
            <span>{toolValue.FluteLength ? toolValue.FluteLength : 'Not available'}</span>
          </li>
          <li>
            <span>Corner Radius</span>
            <span>{toolValue.CornerRad ? toolValue.CornerRad : 'Not available'}</span>
          </li>
        </ul>
      </Modal.Body>
      <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
        <Button variant="secondary" onClick={hideModal}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MVPBuildToolReviewModal;
