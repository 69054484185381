import { IState } from "../../../Interfaces";

const onUnselectPlateReducer = (state: IState, action) => {
    const plateId = action.payload as string;

    const newState = { ...state } as IState;
    const index = newState.data.plates.selectedPlateIds.indexOf(plateId);
    const updatedSelectedPlates = newState.data.plates.selectedPlates.filter((plate)=> plate.id !== plateId);
    newState.data.plates.selectedPlateIds.splice(index,1);
    newState.data.plates.selectedPlates=updatedSelectedPlates;

    return newState;
};

export default onUnselectPlateReducer;