import React, { useEffect, useState } from "react";
import { DataGrid } from "@material-ui/data-grid";
import API, { graphqlOperation } from "@aws-amplify/api";
import { listRoles } from "../../../graphql/queries";
import LoadingComponent from "../../../LoadingComponent/LoadingComponent";
import { Button, Modal } from "react-bootstrap";
import { deleteRole } from "../../../graphql/mutations";
import { NotificationManager } from "react-notifications";

const RoleList = (props) => {
  const [roleList, setRoleList] = useState([]);
  const [showLoader, setShowLoader] = useState(false); // Loader visible state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteRoleObj, setDeleteRoleObj] =
    useState<{ _version: string; id: string }>();
  const isMountedRef = React.useRef(true);

  useEffect(() => {
    isMountedRef.current = true;
    getRolesList();
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const getRolesList = async () => {
    setShowLoader(true);
    try {
      const response = (await API.graphql(graphqlOperation(listRoles))) as any;
      if (isMountedRef.current && response?.data && response.data.listRoles) {
        const { items } = response.data.listRoles;
        const roleItems: any = [];
        items.forEach((roleItem) => {
            if(roleItem.roleName !== "SuperAdmin") {
                roleItems.push(roleItem);
            }
        })
        setRoleList(roleItems);
        setShowLoader(false);
      }
    } catch (error) {
      console.error("Error");
      setShowLoader(false);
    }
  };

  const columns = [
    {
      field: "roleName",
      headerName: "Role Name",
      sortable: true,
      width: 500
    },
    {
      field: "advancedFeatures",
      headerName: "Features",
      sortable: false,
      width: 500,
      renderCell: (params) => {
        return (
          <div>{0}</div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 300,
      renderCell: (params) => {
        return (
          <div className="user-grid-actions">
            <span
              className="edit-icon"
              onClick={() => {
                editRole(params.row);
              }}
            ></span>
            <span
              className="delete-icon"
              onClick={() => {
                deleteRoleClick(params.row.id, params.row._version);
              }}
            ></span>
          </div>
        );
      },
    },
  ];

  const editRole = (data) => {
    props.enableCreateRole(true);
    props.editRoleData(data);
  };

  const deleteRoleClick = (id, version) => {
    setIsModalOpen(true);
    setDeleteRoleObj({ _version: version, id: id });
  };

  const handleModalDelete = async () => {
    setIsModalOpen(false);
    setShowLoader(true);
    try {
      let response = await API.graphql(
        graphqlOperation(deleteRole, {
          input: { id: deleteRoleObj?.id, _version: deleteRoleObj?._version },
        })
      );
      if (response) {
        getRolesList();
        setShowLoader(false);
        NotificationManager.success("Role Deleted Successfully");
      }
    } catch (error) {
      NotificationManager.error("Error");
      setShowLoader(false);
    }
  };

  return (
    <div>
      <LoadingComponent visible={showLoader} />
      <div className="user-management-grid">
        <DataGrid
          rows={roleList}
          columns={columns}
          autoHeight
          autoPageSize
          disableSelectionOnClick
          disableColumnMenu
          className="list-view-grid"
          hideFooterPagination
        />
      </div>

      {/* Delete user popup modal */}
      <Modal show={isModalOpen} backdrop="static" keyboard={false} centered>
        <Modal.Header>
          <Modal.Title>Delete Role</Modal.Title>
          <span
            className="close-btn"
            onClick={() => {
              setIsModalOpen(false);
            }}
          ></span>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-4">
            <h4>Are you sure you want to delete this role?</h4>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-end">
          <Button
            className="btn btn-secondary"
            onClick={() => {
              setIsModalOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            className="btn btn-primary"
            onClick={() => {
              handleModalDelete();
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default RoleList;
