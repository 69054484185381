import React, { FunctionComponent, useEffect, useState } from 'react';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';

import './Fonts/Graphik/GraphikMedium.otf';
import './Fonts/Graphik/GraphikBold.otf';
import './Fonts/Graphik/GraphikSemibold.otf';
import './Fonts/Graphik/GraphikRegular.otf';
import './Fonts/OpenSans/OpenSans-Bold.woff';
import './Fonts/OpenSans/OpenSans-Regular.woff';
import './Fonts/OpenSans/OpenSans-SemiBold.woff';

import '../css/Dashboard.scss';
import '../css/index.scss';
import '../css/HomePage.scss';
import '../css/PlatesPage.scss';
import '../css/ReviewPage.scss';
import '../css/customJsonEditor.scss';
import '../MVConfigurationUpdate/MVConfigurationUpdate.scss';
import '../css/HelpPage.scss';

import configureStore from './configureStore';

import { HomePage, EditPlatePage, ConfigurePage, PlateReviewPage, PartReviewPage, ReviewPage, MantleDocumentation } from './Pages';
// import SideNav from '../MVPSideNav/SideNav';

import SideNav from '../MVPSideNav/SideNav';
import { Auth } from "aws-amplify";
import TopNav from '../MVPTopNav/TopNav';
import BuildPreparation from '../components/BuildPreparation/BuildPreparation';
import MVPAnalyticsDashboard from '../MVPAnalyticsDashboard/MVPAnalyticsDashboard';
import MVPBuildReview from '../MVPBuildReview/MVPBuildReview';
import MVPPrintDashboard from '../MVPPrintDashboard/MVPPrintDashboard';
import MVPPrintReview from '../MVPPrintReview/MVPPrintReview';

import { useHistory } from 'react-router-dom';
import UserManagement from './Pages/UserManagementPage';
import API, { graphqlOperation } from "@aws-amplify/api";
import { getRole } from "../graphql/queries";

const store = configureStore();

const HOME_PAGE_PATH = '/dashboard/home';

const Beta: FunctionComponent<any> = (props: any) => {
    const history = useHistory();
    const [group, setGroup] = useState("");
    const [isAdminPermission, setIsAdminPermission] = useState(false);

    useEffect(() => {
        if (performance.navigation.type === 1 && window.location.pathname !== '/dashboard/print') {
            history.push(HOME_PAGE_PATH);
        }
        
        Auth.currentAuthenticatedUser().then(async (user) => {
            const group = user.signInUserSession.accessToken?.payload["cognito:groups"] && user.signInUserSession.accessToken?.payload["cognito:groups"][0];
            setGroup(group || "");
            if (group !== "superAdmin" || group !== "SuperAdmin") {
                const response = (await API.graphql(
                  graphqlOperation(getRole, { id: group })
                )) as any;
                const { data } = response;
                if (response && data) {
                  setIsAdminPermission(group === "Admin" || group === "admin" ? true : false);
                }
              }
        })
    }, []);

    const checkCurrentRole = () => {
        if(group === "SuperAdmin" || group === "superAdmin") {
            return true;
        }
        return false;
    }

    return (
        <Provider store={store}>
            <div>
                <TopNav signOut={props.signOut}/>
                <div className='flex-d'>
                {/* <SideNav /> */}

                    <div className='dashboard'>

                        <div className='dashboard-body'>

                            <Switch>
                                <Route path='/dashboard/home'>
                                    <HomePage />
                                </Route>
                                <Route path='/dashboard/plates/edit'>
                                    <EditPlatePage />
                                </Route>
                                <Route path='/dashboard/BuildPreparation'>
                                    <BuildPreparation />
                                </Route>
                                <Route path='/dashboard/BuildReview/:id' component={MVPBuildReview} />
                                <Route path='/dashboard/PartReview/' component={PartReviewPage} />
                                {/* <Route path='/dashboard/ReviewPage/' component={ReviewPage} /> */}
                                <Route path='/dashboard/PlateReview/' component={PlateReviewPage} />
                                <Route path='/dashboard/print'>
                                    <MVPPrintDashboard />
                                </Route>
                                <Route path='/dashboard/printReview'>
                                    <MVPPrintReview />
                                </Route>
                                <Route path='/dashboard/analytics'>
                                    <MVPAnalyticsDashboard />
                                </Route>
                                <Route path='/dashboard/configuration'>
                                    <ConfigurePage />
                                </Route>
                                <Route path='/dashboard/documents'>
                                    <MantleDocumentation />
                                </Route>
                                {(group === "SuperAdmin" || group === "superAdmin" || isAdminPermission) &&
                                <Route path='/dashboard/user'>
                                    <UserManagement />
                                </Route>}
                                <Route path='/dashboard'>
                                    <Redirect to='/dashboard/home' />
                                </Route>
                            </Switch>

                        </div>
                    </div>

                </div>

            </div>
        </Provider>
    );
};

export default withRouter(Beta);