import API, { graphqlOperation } from '@aws-amplify/api';
import { SortDirection } from "../../../Enums";
import { union } from 'lodash';

import { plateByCreatedAt } from '../../../../graphql/queries';
import * as subscriptions from '../../../../graphql/subscriptions';
import subscriptionsSet from "../../Subscriptions/subscriptionsSet";

import ACTION_TYPES from '../../../actionTypes';
import { IPlate } from '../../../Interfaces';
import { Storage } from "aws-amplify";
import { updatePlate } from '../../../../graphql/mutations';

const getAvailablePlates = (plates: IPlate[] = []) => {
    return plates.filter(b => Boolean(b && !b._deleted));
};
const onUpdatePlateSuccess = (updatedPlate: IPlate) => {
    return {
        type: ACTION_TYPES.PLATE.UPDATE.SUCCESS,
        payload: updatedPlate,
    };
};

const onLoadPlatesStarted = () => {
    return {
        type: ACTION_TYPES.HOME.LOAD.PLATES.STARTED,
        payload: null,
    };
};

const onLoadPlatesInProgress = (newlyLoadedPlates, nextToken) => {
    return {
        type: ACTION_TYPES.HOME.LOAD.PLATES.IN_PROGRESS,
        payload: { plates: newlyLoadedPlates, nextToken: nextToken },
    };
};

const onLoadPlatesCompleted = () => {
    return {
        type: ACTION_TYPES.HOME.LOAD.PLATES.COMPLETED,
        payload: null,
    };
};

const onLoadPlatesUpdated = (newlyLoadedPlates) => {
    return {
        type: ACTION_TYPES.HOME.LOAD.PLATES.UPDATED,
        payload: newlyLoadedPlates,
    };
};

const onLoadPlatesCreated = (newlyLoadedPlates) => {
    return {
        type: ACTION_TYPES.HOME.LOAD.PLATES.CREATED,
        payload: newlyLoadedPlates,
    };
};

const onLoadPlatesDeleted = (deletedPlates) => {
    return {
        type: ACTION_TYPES.HOME.LOAD.PLATES.DELETED,
        payload: deletedPlates,
    };
};

//const loadPlates = async (dispatch, getState, response: { loadedPlates: IPlate[], nextToken: any } = { loadedPlates: [] as IPlate[], nextToken: undefined }) => {
const loadPlates = async (dispatch, getState, newRequest) => {
    try {
        const state = getState();
        var loadedPlates = newRequest ? [] : state.data?.plates?.loadedPlates || [];
        const nextToken = newRequest ? undefined : state.data?.plates?.nextToken;
        const sortDirection = state.data?.plates?.sortDirection === SortDirection.ASC ? 'ASC' : 'DESC';
        const searchText = state.data?.plates?.searchText;
        const currentUser = state.creator;
        const showOwnedByFilter = state.data?.info.showOwnedByPlates

        if (!newRequest && !nextToken) {
            return;
        }

        const variables = {
            limit: 50,
            dumb: 1,
            sortDirection: sortDirection,
            nextToken: nextToken,
        };

        var useFilter = (searchText && searchText !== "");
        if (useFilter) {
            (variables as any).filter = {
                plate_search_string: { contains: searchText },
            };
        }
        if(showOwnedByFilter && currentUser !==""){
            (variables as any).filter = {
                created_by: {eq: currentUser},
            };
        }

        dispatch(onLoadPlatesStarted());

        do {
            const result = await API.graphql(graphqlOperation(plateByCreatedAt, variables)) as any;
            const newlyLoadedPlates = getAvailablePlates((result as any)?.data?.plateByCreatedAt?.items || [] as IPlate[]);

            // newlyLoadedPlates.forEach(async (plate:any) => {
            //     if (
            //         plate.parts[0] &&
            //         JSON.parse(plate.parts[0]).Key !== `PlatePartsFiles/${plate.id}.json`
            //     ) {
            //         console.log(`NON S3 Reference Plate ${plate.id}`);
            //         try {
            //             plate.noOfParts = plate.parts.length
            //             let partsArrayToS3 = plate.parts;
            //             const path = `PlatePartsFiles/${plate.id}.json`;
            //             const AWSBucketParam = [JSON.stringify({
            //                 Bucket: Storage["_config"]["AWSS3"]["bucket"],
            //                 Key: path,
            //                 CacheControl: 'no-cache' // or 'max-age=0'
            //             })];
            //             await Storage.put(path,partsArrayToS3, {
            //                 contentType: "application/json",
            //                 completeCallback: (event) => console.log(`Successfully uploaded ${event.key}`),
            //                 progressCallback: (progress) => console.log(`Uploaded: ${progress.loaded}/${progress.total}`),
            //                 errorCallback: (error) => {
            //                     console.error('Error uploading part to S3', error.message)
            //                     // dispatch(onUpdatePlateFail("Update plate failed"));
            //                 }
            //             });
            //             delete plate._deleted;
            //             delete plate._lastChangedAt;
            //             delete plate.createdAt;
            //             delete plate.updatedAt;
            //             plate["parts"] = AWSBucketParam;
                        

            //             const variables = {
            //                 input: plate,
            //             };
            //             const result = await API.graphql(graphqlOperation(updatePlate, variables));
            //             const updatedPlate = ((result as any)?.data?.updatePlate) as any;
            //             dispatch(onUpdatePlateSuccess(updatedPlate));
            //         } catch (error:any) {
            //             console.error("Error", error.message);
            //             // dispatch(onUpdatePlateFail("Update plate failed"));
            //         }
            //     }
              
            // });



            const newNextToken = (result as any)?.data?.plateByCreatedAt?.nextToken || undefined;
            if (!newNextToken || (newlyLoadedPlates.length && !useFilter)) {
                dispatch(onLoadPlatesInProgress(union(loadedPlates, newlyLoadedPlates), newNextToken));
                break;
            }
            else {
                loadedPlates = union(loadedPlates, newlyLoadedPlates);
                dispatch(onLoadPlatesInProgress(loadedPlates, undefined));
                variables.nextToken = newNextToken;
            }
        } while (true);

        dispatch(onLoadPlatesCompleted());
        return Promise.resolve();
    } catch (graphqlError) {
        return Promise.resolve();
    }
};

const onLoadPlates = (newRequest = false) => {
    return async (dispatch, getState) => {
        const state = getState();
        await loadPlates(dispatch, getState, newRequest);
        let newSubscriptions = {};

        if (!state.subscriptions?.plateCreate) {
            console.log("Adding PLATE CREATE subscription");
            (newSubscriptions as any).plateCreate =
                (API.graphql(graphqlOperation(subscriptions.onCreatePlate)) as any).subscribe({
                    next: ({ provider, value }) => {
                        console.log({ provider, value })
                        dispatch(onLoadPlatesCreated(value.data.onCreatePlate))
                    },
                    error: error => console.error(error)
                });
        }
        if (!state.subscriptions?.plateDelete) {
            console.log("Adding PLATE DELETE subscription");
            (newSubscriptions as any).plateDelete =
                (API.graphql(graphqlOperation(subscriptions.onDeletePlate)) as any).subscribe({
                    next: ({ provider, value }) => {
                        console.log({ provider, value })
                        dispatch(onLoadPlatesDeleted(value.data.onDeletePlate))
                    },
                    error: error => console.error(error)
                });
        }
        if (!state.subscriptions?.plateUpdate) {
            console.log("Adding PLATE UPDATE subscription");
            (newSubscriptions as any).plateUpdate =
                (API.graphql(graphqlOperation(subscriptions.onUpdatePlate)
                ) as any).subscribe({
                    next: ({ provider, value }) => {
                        console.log({ provider, value })
                        dispatch(onLoadPlatesUpdated(value.data.onUpdatePlate))
                    },
                    error: error => console.warn(error)
                });
        }
        dispatch(subscriptionsSet(newSubscriptions));
    };
};

export default onLoadPlates;
