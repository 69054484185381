import React from "react";

import "./MVPBuildReview.scss";
import Button from "react-bootstrap/Button";
import API, { graphqlOperation } from "@aws-amplify/api";
import { getBuild, getPlate, listPrinters } from "../graphql/queries";
import Amplify, { Storage } from "aws-amplify";
import MVPBuildSliceReview from "../MVPBuildSliceReview/MVPBuildSliceReview";
import WorkspaceScene from "../WorkSpace/WorkspaceScene";
import workSpaceReducer from "../WorkSpace/workspaceReducer";
import { Auth } from "aws-amplify";
import JSZip from "jszip";
import { withRouter } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import IPartInfo from "../interfaces/IPartInfo";
import IMatrix from "../interfaces/IMatrix";
import IBuild from "../interfaces/IBuild";
import { IPlate, IState } from "../Beta/Interfaces";
import { TabIndex } from '../Beta/Enums';
import Scene from "../Scene";
import awsconfig from "../aws-exports";
import { STLLoader } from "three-stl-loader";
import { PLYLoader } from "../loaders/PLYLoader";
import { GCodeLoader } from "../loaders/GCodeLoader";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import rightarrow from "./rightarrow.svg";
import chart from "./chart.svg";
import moment from "moment";

import { TreeNode } from "../BundlesPage/BuildElementControl";

import * as THREE from "three";
import {
  onCancelBuild,
  onHighlightBuild,
  onMoveBuildOpenDialog,
} from "../Beta/Actions/Builds";
import { onNavigateToBuildReviewPage, onNavigateToHomePage, onNavigateToPlatesPage } from "../Beta/Actions/Home";
import {
  onDownloadBundle,
  onDownloadGcode,
  onDownloadLog,
} from "../Beta/Actions/Home";
import { connect } from "react-redux";
import { MVPBadBuildReview } from "../MVPBadBuildReview/MVPBadBuildReview";
import { S3ObjectTree } from "../BundlesPage/S3ObjectTree";
import MVPBuildToolReviewModal from "./MVPBuildToolReviewModal";
import MVPDisplayPasteConsumptionModal from "./MVPDisplayPasteConsumption";
import { Modal } from "react-bootstrap";
import { onHighlightPlate, onHighlightPlateObject } from "../Beta/Actions/Plates";

Amplify.configure(awsconfig);
const MESHES_TAG = "Meshes/";

type PrintableEntity = IPartInfo | IMatrix;

export interface IWorkSpaceState {
  build: IBuild;
  plateName: string;
  showLoading: string[];
  slicerText: string;
  combindedConfigEditor: Object | null;
  viewState: WorkspaceViewType;
  selectedMatrix: IMatrix | null;
  moho: string;
  selectedPart?: any[];
  open?: boolean;
  isConfigDisabled?: boolean;
  selectedPrinter?: any;
  isActualExpand?: boolean;
  isSlicedExpand?: boolean;
  isScreenChanged?: boolean;
  isExpanded: boolean;
  isscreenChangedTog?: boolean;
  reviewLoading?: boolean;
  reviewValues?: any;
  printerList?: any[];
  selPrinter?: any;
  checkedAntSwitch?: boolean;

  current_bundle: any;
  main_log: string;
  main_gcode: string;
  baselen?: number;
  gcodeTree: TreeNode;
  meshesTree: TreeNode;
  operations: TreeNode;
  levels: TreeNode;
  splitFastWork: boolean;
  resultJson: any;
  fullScreen: false;
  subtractiveStrategy: string;
  dryingStrategy: string;
  meterialScale: string;
  partConfig: any[];
  completedTime: any;
  plateLink: string;
  buildTools: any[];
  selectedBuildTool: {};
  showMVPBuildToolReviewModal: boolean;
  showMVPDisplayPasteConsumptionModal:boolean;
  showBuildWarningModal:boolean;
  isBuildLoaded: boolean,
  toolNames: any[]
  warnings:any[]
  pasteConsumption:any[]
  setToolsDetails:any[]
}

export enum WorkspaceViewType {
  SceneView,
  GrafanaView,
}

const Props = (state: IState, ownProps: any) => {
  const loadedPlates = state.data.plates.loadedPlates as any;
    let [currentBuild] = state.data.builds.loadedBuilds.filter(i => i.id === ownProps.match.params.id);
    const props = {
        currentBuild: currentBuild,
        selectedTabIndex: state.data.info.homePageTabIndex as number,
        loadedPlates: loadedPlates as any[],
    };
	return props;
};

const Actions = {
  onHighlightBuild: onHighlightBuild,
  onNavigateToBuildReviewPage: onNavigateToBuildReviewPage,
  onNavigateToHomePage: onNavigateToHomePage,
  onCancelBuild: onCancelBuild,
  onDownloadGcode: onDownloadGcode,
  onDownloadBundle: onDownloadBundle,
  onDownloadLog: onDownloadLog,
  onMoveBuildOpenDialog: onMoveBuildOpenDialog,
  onNavigateToPlatesPage: onNavigateToPlatesPage,
  onHighlightPlateObject: onHighlightPlateObject,
  onHighlightPlate: onHighlightPlate,
};

export function groupMeshesByPart(meshesEntries) {
  let meshesByPart: any[] = [];
  meshesEntries.forEach((meshEntry: any) => {
    let filePath = meshEntry.key;

    let found = filePath.match(/Part\d+_/);
    let partName = found
      ? found[0].slice(0, -1)
      : filePath.match(/Part\d+/)[0].slice(0);

    if (meshesByPart[partName] === undefined) {
      meshesByPart[partName] = new Array(filePath);
    } else {
      meshesByPart[partName].push(filePath);
    }
  });
  return meshesByPart;
}

export class MVPBuildReview extends React.Component<any, IWorkSpaceState> {
  stlLoader: STLLoader;
  plyLoader: PLYLoader;
  gcodeLoader: any;

  dispatch: (action: any) => void;
  zip: JSZip;
  sceneO: Scene | null = null;
  scene: Scene | null = null;
  isLoading: (val: any) => void;

  constructor(props: any) {
    super(props);

    const STLLoader = require("three-stl-loader")(THREE);
    this.stlLoader = new STLLoader();
    this.plyLoader = new PLYLoader();
    this.gcodeLoader = new GCodeLoader();
    this.state = {
      plateName: "",
      isConfigDisabled: false,
      showLoading: [],
      selectedPart: [],
      selectedPrinter: {},
      reviewLoading: false,
      open: false,
      moho: "",
      build: {
        id: "",
        machineConfig: {},
        millConfig: {},
        materialConfig: {},
        recipe: {},
        parts: [],
        bundles: [],
        build_messages:{}
      },
      slicerText: "",
      combindedConfigEditor: null,
      viewState: WorkspaceViewType.SceneView,
      selectedMatrix: null,
      isActualExpand: false,
      isExpanded: false,
      isSlicedExpand: true,
      isScreenChanged: false,
      isscreenChangedTog: false,
      reviewValues: {},
      printerList: [],
      selPrinter: "",
      checkedAntSwitch: false,

      main_log: "",
      main_gcode: "",
      current_bundle: {},
      baselen: 0,
      gcodeTree: {
        name: "",
        children: [],
        object: undefined,
        mesh: undefined,
        toggled: false,
      },
      meshesTree: {
        name: "",
        children: [],
        object: undefined,
        mesh: undefined,
        toggled: false,
      },
      operations: { name: "unassigned", children: [], toggled: false },
      levels: { name: "unassigned", children: [], toggled: false },
      splitFastWork: false,
      resultJson: null,
      fullScreen: false,
      subtractiveStrategy: "",
      dryingStrategy: "",
      meterialScale: "",
      partConfig: [],
      completedTime: "",
      plateLink: "",
      buildTools: [],
      selectedBuildTool: {},
      showMVPBuildToolReviewModal: false,
      showMVPDisplayPasteConsumptionModal:false,
      showBuildWarningModal:false,
      isBuildLoaded: false,
      toolNames: [],
      warnings:[],
      pasteConsumption:[],
      setToolsDetails:[]   
    };

    this.dispatch = (action: any) => {
      const newState = workSpaceReducer(this.state, action);
      this.setState(newState);
    };

    this.isLoading = (val: any) => {
      const loadingVal = {
        type: val,
        cargo: {
          value: val === "showLoading",
        },
      };

      const newState = workSpaceReducer(this.state, loadingVal);
      this.setState(newState);
    };
    this.zip = new JSZip();
  }

  onNavigateToPlatesPage = async (plateId) => {

    let plateToBeEdited = this.props.loadedPlates.find(lp => lp.id === plateId) as IPlate;
    if(!plateToBeEdited){
      const getPlateDetails = await API.graphql(
        graphqlOperation(getPlate, {
          id: plateId,
        })
      );
      plateToBeEdited = (getPlateDetails as any).data.getPlate;
      plateToBeEdited.parts = await this.getPartDetailsFromS3(plateToBeEdited.id);
    }
    if (
      plateToBeEdited &&
      plateToBeEdited.parts[0] &&
      JSON.parse(plateToBeEdited.parts[0]).Key === `PlatePartsFiles/${plateToBeEdited.id}.json`
    ) {

      plateToBeEdited.parts = await this.getPartDetailsFromS3(plateToBeEdited.id);
      // const path = `PlatePartsFiles/${plateToBeEdited.id}.json`;
      // const AWSBucketParam = {
      //   Bucket: Storage["_config"]["AWSS3"]["bucket"],
      //   Key: path,
      //   CacheControl: 'no-cache' // or 'max-age=0'
      // };
      // try {
      //   const getResult = await Storage.get(AWSBucketParam.Key, { download: true,  cacheControl: 'no-cache' });
      //   plateToBeEdited.parts = JSON.parse(await(getResult.Body as any).text());
      // } catch (error: any) {
      //   NotificationManager.error('An error occurred during the plate editing process');
      //   console.error("An error occurred during the plate editing process:", error.message);
      // }
      }
   


    const partIds = plateToBeEdited.parts.filter(Boolean).map(stringifiedPart => JSON.parse(stringifiedPart)).filter(Boolean).map(part => part.properties.PartID).filter(Boolean) as string[];
    const parts = plateToBeEdited.parts.filter(Boolean).map(stringifiedPart => JSON.parse(stringifiedPart)).filter(Boolean) as IPartInfo[];
    const partConfigurationIds = plateToBeEdited.parts.filter(Boolean).map(stringifiedPart => JSON.parse(stringifiedPart)).filter(Boolean).map(part => part.properties.PartConfig['originalJson']['id']).filter(Boolean);
    const partSlicerConfigurationIds = plateToBeEdited.parts.filter(Boolean).map(stringifiedPart => JSON.parse(stringifiedPart)).filter(Boolean).map(part => part.properties.SlicerConfig['originalJson']['id']).filter(Boolean);
    const plateConfigurationIds = [plateToBeEdited.machineId, plateToBeEdited.millConfigId, plateToBeEdited.materialConfigId, plateToBeEdited.recipeId];

    const ids = {
      partIds,
      parts,
      partConfigurationIds,
      partSlicerConfigurationIds,
      plateConfigurationIds,
    };

    this.props.onHighlightPlate(plateId);
    this.props.onNavigateToPlatesPage(ids, this.props.history, false);
  }

  getPartDetailsFromS3 = async (plateId) => {
    const path = `PlatePartsFiles/${plateId}.json`;
    const AWSBucketParam = {
      Bucket: Storage["_config"]["AWSS3"]["bucket"],
      Key: path,
      CacheControl: 'no-cache' // or 'max-age=0'
    };
    try {
      const getResult = await Storage.get(AWSBucketParam.Key, { download: true,  cacheControl: 'no-cache' });
      const parts = JSON.parse(await(getResult.Body as any).text());
      return parts;
    } catch (error: any) {
      NotificationManager.error('An error occurred during the plate editing process');
      console.error("An error occurred during the plate editing process:", error.message);
    }
  }

  setScene(scene: Scene) {
    this.scene = scene;
  }
  mapStateToProps = (state) => ({
    tools: state.data.configurations.highlightedConfigurationData,
  });

  componentDidMount() {
    Auth.currentAuthenticatedUser().then((user) => {
      //console.log(user);

      const groups =
        user.signInUserSession.accessToken.payload["cognito:groups"] || [];

      //console.log(groups);

      if (groups[0] == "Operator") {
        this.setState({ isConfigDisabled: true });
      }
    });

    this.setState({
      selectedPart: [],
    });
    this.LoadMachine().catch((reason) => {
      console.error(reason);
    });
    this.LoadBuild().catch((reason) => {
      console.error(reason);
    });
  }

  // public componentDidUpdate(prevProps: any, prevState: any) {
  //   if(this.state.build?.parts){
  //     this.loadPart();
  //   }
  // }

  async processGcode(build) {
    this.isLoading("showLoading");
    let failed = false;
    try {
      let indexKey = `${build.bundle.slice(7)}index.json`;
      const gcodeResponse = await Storage.get(indexKey, { download: true,  cacheControl: 'no-cache' });
      if (gcodeResponse) {
        const parsed = await new Response(gcodeResponse.Body as Blob).json();
        this.isLoading("hideLoading");
        return parsed;
      } else {
        failed = true;
      }
    } catch (er) {
      failed = true;
    }
    if (failed) {
      try {
        let gcode_key = `${build.bundle.slice(7)}${build.id}_Lava.gcode`;

        const gcodeResponse = await Storage.get(gcode_key);

        let parsed = await this.gcodeLoader.parse(
          gcodeResponse,
          this.state.splitFastWork
        );
        this.isLoading("hideLoading");
        return parsed;
      } catch (reason) {
        console.log(reason);
        // this.state.disabledAction.disabled = 'enable';
        this.isLoading("hideLoading");
        return null;
      }
    }
  }

  async processMeshes(build) {
    let meshesTree: TreeNode = {
      name: "Meshes",
      children: [],
      toggled: true,
    };
    try {
      const result = await Storage.list(`${build.bundle.slice(7)}Meshes`);

      //get only meshes
      let meshesEntries = result.filter((value: any) => {
        let fileName = value.key;
        let fileExtension = fileName.split(".").pop();
        fileExtension = fileExtension.toLowerCase();
        return fileExtension === "ply" || fileExtension === "stl";
      });

      //group meshes by Part
      let meshesByPart = groupMeshesByPart(meshesEntries);

      for (let entry of Object.entries(meshesByPart)) {
        let partName = entry[0];
        let partMeshes = entry[1];

        let meshPaths = partMeshes.map((filePath: string) => {
          let treePath = filePath
            .substring(filePath.indexOf(MESHES_TAG) + MESHES_TAG.length)
            .slice(0, -".ply".length);

          //remove redundant Part[Number]_ from fileName
          treePath = treePath.replace(new RegExp(partName + "_", "g"), "");

          //replace /Slab0_cutSim0_Roughing with Slab0/cutSim0_Roughing
          if (treePath.split("_").length > 2) {
            treePath = treePath.replace(/_/, "/");
          }

          let slabNumber = treePath.match(/\d+$/);
          if (slabNumber !== null) {
            //trick to place /meshes/slabs/slab0 under /meshes/slabs/slab0/slab0
            let lastDivider = treePath.lastIndexOf("/");

            let base = treePath.slice(0, lastDivider);
            let lastTag = treePath.substring(lastDivider + 1);

            treePath = `${base}/Slab${slabNumber[0]}/${lastTag}`;
          }
          return Object({
            treePath: treePath,
            databasePath: filePath,
          });
        });

        let partNode = {
          name: partName,
          children: [],
        };

        (meshesTree.children as any).push({
          name: partName,
          toggled: true,
        });

        meshPaths.sort((pathA, pathB) => {
          let slabNumbers = [pathA.treePath, pathB.treePath].map((path) => {
            let slabNameMatch = path.match(/Slab\d+/);
            if (slabNameMatch === null) {
              return 0;
            }
            return parseInt(slabNameMatch[0].match(/\d+/)[0]);
          });
          return slabNumbers[0] - slabNumbers[1];
        });

        for (let entry of meshPaths) {
          let treePath = entry.treePath;
          let databasePath = entry.databasePath;

          //add to node
          let parentNode = partNode;
          treePath
            .split("/")
            .forEach((entry: string, index: number, array: any) => {
              if (index === array.length - 1) {
                let node = {
                  name: entry,
                  databasePath: databasePath,
                  mesh: null,
                };
                (parentNode.children as any).push(node);
              } else {
                let branchNode = parentNode.children.find(
                  (branch: any) => branch.name === entry
                );
                if (branchNode === undefined) {
                  branchNode = {
                    name: entry,
                    children: [],
                  } as any;
                  (parentNode.children as any).push(branchNode);
                }
                parentNode = branchNode as any;
              }
            });
        }
        if (meshesTree.children) {
          meshesTree.children[meshesTree.children.length - 1] = partNode;
        }
      }
      return meshesTree;
    } catch (reason) {
      console.log(reason);
    }
    return meshesTree;
  }

  public processBuild = async (rBuild) => {
    let parsed: TreeNode;
    let meshes: TreeNode;
    try {
      // console.log('~~~props ~~~~', this.props);
      // this.loading = true;
      // console.log('MVPBuildReview ~~~selectedBuild ~~~~', this.props.buildData);

      this.setState(
        Object.assign(this.state, {
          gcodeTree: { name: "gcode", toggled: true, loading: true },
        })
      );

      const gcodePromise = this.processGcode(rBuild);
      const meshPromise = this.processMeshes(rBuild);
      [parsed, meshes] = await Promise.all([gcodePromise, meshPromise]);

      this.setState({
        ...this.state,
        gcodeTree: parsed,
        meshesTree: meshes,
        checkedAntSwitch: meshes.children ? meshes.children.length > 0 : false,
        isBuildLoaded: true,
        warnings: JSON.parse(this.state.build.build_messages).warnings !== null ? JSON.parse(this.state.build.build_messages).warnings :[]
      });
      console.log("processBuild~~~", this.state, parsed, meshes);
     
    

      // this.loading = false;
    } catch (err) {
      console.error(err);
      // this.loading = false;

      // NotificationManager.error(`error reading bundle: ${err.message}`);
      this.setState({
        ...this.state,
        // loading: false
      });
    }
  };

  public LoadMachine = async () => {
    let requestResult = await API.graphql(
      graphqlOperation(listPrinters, { limit: 100 })
    );
    console.log("MVPBuildReview ~~~requestResult ~~~~", requestResult);
    this.setState({
      printerList: (requestResult as any).data.listPrinters.items,
    });
    console.log("MVPBuildReview ~~~requestResult ~~~~", requestResult);
  };
  public LoadBuild = async () => {
    console.log("MVPBuildReview ~~~selectedBuild ~~~~", this.state);

    let partList: any[] = [];
    let requestResult = await API.graphql(
      graphqlOperation(getBuild, { id: this.props.match.params.id })
    );
    const loadedConfig = (requestResult as any).data.getBuild;
    loadedConfig.parts.forEach((a) => {
      partList.push(typeof a === "string" ? JSON.parse(a) : a);
    });
    let rBuild: any = loadedConfig;
    rBuild.parts = partList;
    rBuild.machineConfig =
      typeof loadedConfig.machineConfig === "string"
        ? JSON.parse(loadedConfig.machineConfig)
        : loadedConfig.machineConfig;
    rBuild.millConfig =
      typeof loadedConfig.millConfig === "string"
        ? JSON.parse(loadedConfig.millConfig)
        : loadedConfig.millConfig;
    rBuild.materialConfig =
      typeof loadedConfig.materialConfig === "string"
        ? JSON.parse(loadedConfig.materialConfig)
        : loadedConfig.materialConfig;
    rBuild.recipe =
      typeof loadedConfig.recipe === "string"
        ? JSON.parse(loadedConfig.recipe)
        : loadedConfig.recipe;
    rBuild.bundle = loadedConfig.bundle;
    // rBuild['bundle'] = JSON.parse(loadedConfig.bundles.length>0?loadedConfig.bundles[0]:{} );

    this.setState({ subtractiveStrategy: rBuild.millConfig.resultJson.id });
    this.setState({ dryingStrategy: rBuild.recipe.resultJson.id });
    this.setState({ meterialScale: rBuild.materialConfig.resultJson.id });
    if (
      rBuild &&
      rBuild.parts[0] &&
      rBuild.parts[0].Key === `BuildPartsFiles/${rBuild.id}.json`
    ) {
      const path = `BuildPartsFiles/${rBuild.id}.json`;
      const AWSBucketParam = {
        Bucket: Storage["_config"]["AWSS3"]["bucket"],
        Key: path,
        CacheControl: 'no-cache' // or 'max-age=0'
      };
      try {
        const getResult = await Storage.get(AWSBucketParam.Key, { download: true,  cacheControl: 'no-cache' });
        rBuild.parts = JSON.parse(await (getResult.Body as any).text());
      } catch (error: any) {
        NotificationManager.error('An error occurred during the plate editing process');
        console.error("An error occurred during the plate editing process:", error.message);
      }
    }

    this.setState({ partConfig: rBuild.parts });
    const { resultJson } = rBuild?.millConfig || {};

    if (resultJson?.Mill) {
      const { Mill } = resultJson;

      const millingSteps = Mill.MillingSteps || [];
      millingSteps.forEach((tool) => {
        this.setState((prevState) => ({
          toolNames: [...prevState.toolNames, tool.ToolName],
        }));
      });

      const bedFacingTool = Mill.BedFacing;
      if (bedFacingTool) {
        this.setState((prevState) => ({
          toolNames: [...prevState.toolNames, bedFacingTool.ToolName],
        }));
      }

      const layerFacingTool = Mill.LayerFacing;
      if (layerFacingTool) {
        this.setState((prevState) => ({
          toolNames: [...prevState.toolNames, layerFacingTool.ToolName],
        }));

        const perimeterToolName = layerFacingTool.PerimeterToolName;
        if (perimeterToolName) {
          this.setState((prevState) => ({
            toolNames: [...prevState.toolNames, perimeterToolName],
          }));
        }
        const PartNotching = Mill.PartNotching?.ToolName;
        if (PartNotching) {
          this.setState((prevState) => ({
            toolNames: [...prevState.toolNames, PartNotching],
          }));
        }
      }

    
      const millTools = Mill.Tools.filter((tool) =>
       this.state.toolNames.includes(tool.ToolName)
      );

      this.setState({ buildTools: millTools });
    }

    this.dispatch({
      type: "setBuild",
      cargo: {
        item: rBuild,
      },
    });

    if (loadedConfig.current_status === "completed") {
      await Promise.all([this.loadResult(rBuild), this.processBuild(rBuild)]);
      this.initSelectedPrinter();
    }
  };
  handleModalClose = () => this.setState({ showMVPBuildToolReviewModal: false });
  handleModalShow = () => this.setState({ showMVPBuildToolReviewModal: true });
  handleBuildWarningModalShow = () => this.setState({...this.state, showBuildWarningModal :true})
  handleBuildWarningModalHide = () => this.setState({ ...this.state, showBuildWarningModal: false })
  handleisplayPasteConsumptionModalShow = () => this.setState({ ...this.state, showMVPDisplayPasteConsumptionModal: true })
  handleBisplayPasteConsumptionModalHide = () => this.setState({ ...this.state, showMVPDisplayPasteConsumptionModal: false })
  public handleShowBuildTools = async (selectedTool) => {
    const machineInfo = this.state?.build?.machineConfig;
    const machine = machineInfo?.originalJson?.id;
    let select = this.state?.printerList?.find((a) => a.id === machine);
    console.log(this.state)
    if (select && this.state.setToolsDetails.length ===0) {
        interface FetchToolsInfo {
          id: string;
          name: string;
          GrafanaBundle: string
        }
    
        try {
          if (select) {
            const primaryInfo: FetchToolsInfo = {
              id: select.id,
              name: select.name,
              GrafanaBundle: select.GrafanaBundle
            };
            let requestData = {
              body: [primaryInfo]
            };
            const response = await API.post("lavaRestApi", "/getToolState", requestData);
    
            if (response[0]?.statusCode !== 200) {
              throw new Error('Network response was not ok');
            }
            const responseJSON = response[0].body as any
            if (responseJSON.length === 0) {
              console.log("The array is empty");
            }
            else {
              this.setState({
                setToolsDetails: response[0].body as any
              });
            }
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    
    console.log('setToolsDetails'+ this.state.setToolsDetails)
    this.setState({
      selectedBuildTool: this.state.buildTools.filter(
        (usedTool) => usedTool.ToolName === selectedTool
      ),
    });
    this.handleModalShow();
  };

  public loadResult = async (rBuild) => {
    this.setState({
      completedTime: new Date(rBuild.created_at).setSeconds(
        new Date(rBuild.created_at).getSeconds() + rBuild.build_time
      ),
    });

    const plateNameId = JSON.parse(rBuild.plates);
    this.setState({ plateLink: plateNameId?.id });
    const plateLinkId = JSON.parse(rBuild.plates || "{}"); // Ensure plates is defined
    // this.setState({ plateName: JSON.parse(plateNameId.id) });
    let key = `${rBuild.bundle}result.json`;
    if (key.indexOf("public/") === 0) {
      key = key.substr(7);
    }

    let resultJson = null;
    try {
      let result = await Storage.get(key, {
        download: true,
      });
      resultJson = await new Response((result as any).Body).json();
      this.setState({ resultJson: resultJson });
      console.log("loadResult~~~resultJson", resultJson);
      await this.calculateTimes(resultJson);
      await this.pasteConsumption(resultJson)
    } catch (e) {
      NotificationManager.info(`Failed to get result.json ${key}`);
    }
    // return await calculateTimes(estimationProperties, resultJson, {})
  };
  public pasteConsumption = async (bundle)=>{
    const  slab = bundle["Times"]["All Print"]["Slabs"];
    const keys = Object.keys(slab);
    const pasteConsumptionArray:any[] = [];
    const sortedPasteConsumptionArray:any[]=[];
   
    interface slabObjectType{
      slab:string,
      timeTaken:number,
      extruded?:number,
      purged?:number,
      pasteConsumption:number
    }

    for (let i = 0; i < keys.length; i++) {
      if (keys[i].startsWith('Slab_')) {
        let slabObject: slabObjectType ={
          slab: keys[i],
          timeTaken: slab[keys[i]]["Total"]["time"],
          // extruded: slabObj[keys[i]]["extruded"],
          // purged: slabObj[keys[i]]["purged"],
          pasteConsumption: (slab[keys[i]]["extruded"] + slab[keys[i]]["purged"]).toFixed(2)
         }
        pasteConsumptionArray.push(slabObject)    
            
      }   
    }
    sortedPasteConsumptionArray.push(pasteConsumptionArray.sort((a, b) => a.slab.slice(5) - b.slab.slice(5)))
    this.setState({ ...this.state, pasteConsumption: sortedPasteConsumptionArray[0] }, () => console.log(this.state.pasteConsumption))
   
  }
 public  getToolName=(toolNumber)=> {
  switch (toolNumber) {
    case 1:
      return "Chip Fan";
    case 3:
      return "3/8\" end mill";
    case 5:
      return "1/4\" end mill";
    case 7:
      return "1/16\" end mill";
    case 9:
      return "0.020\" ball end mill";
    case 11:
      return "0.020\" end mill";
    case 13:
      return "1/8\" end mill";
    case 15:
      return "Reference tool";
    case 17:
      return "0.006\" ball end mill";
    case 19:
      return "0.010\" ball end mill";
    default:
      return "-";
  }
}

  public calculateTimes = async (bundle) => {
    console.log("Calculate times");
    let layers = new Array(bundle.LayersCount);
    let totalTime = bundle["Times"]["All Print"]["Total"]["seconds"] * 1000;
    for (const [key, value] of Object.entries(
      bundle.Times["All Print"].Slabs
    )) {
      if (key.indexOf("Slab_") !== 0) {
        continue;
      }
      for (const [layer, layerData] of Object.entries(value as any)) {
        if (layer.indexOf("LayerPrint_") !== 0) {
          continue;
        }
        layers[Number(layer.substr("LayerPrint_".length)) - 1] = layerData;
      }
    }
    console.log("Init startpoint");
    let startPoint = {};
    // startPoint['layer'] = data.currentLayer || printer.currentLayer || 1
    // startPoint['stage'] = data.currentStage || printer.currentStage || 'PRG'
    // startPoint['currentStartTime'] = data.currentStartTime || printer.currentStartTime || Date.now()
    startPoint["layer"] = 1;
    startPoint["stage"] = "PRG";
    startPoint["currentStartTime"] = Date.now();
    let timeSpent = 0;
    let pasteSpent = 0.0;

    for (let i = 0; i < startPoint["layer"]; i++) {
      timeSpent += layers[i]["Total"]["seconds"] * 1000;
      pasteSpent += layers[i]["extruded"] + layers[i]["extruded"];
    }

    let timeToPass = 0;
    let timeToPasteEnd = -1;
    for (let i = startPoint["layer"] - 1; i < layers.length; i++) {
      pasteSpent += layers[i]["extruded"] + layers[i]["purged"];
      // if (pasteSpent >= printer.currentPasteAmount){
      if (pasteSpent >= 0) {
        timeToPasteEnd = timeToPass;
      }
      timeToPass += layers[i]["Total"]["seconds"] * 1000;
    }
    console.log("-----------------time" + timeToPass);
    console.log("before exit", timeToPasteEnd, totalTime, pasteSpent);
    this.setState({
      reviewValues: {
        timeToPasteEnd: Date.now() + timeToPasteEnd,
        totalTime: Date.now() + totalTime,
        pasteSpent: pasteSpent.toFixed(2),
      },
    });
    // return {
    //     id: printer.id,
    //     pasteEndTime: timeToPasteEnd!=-1 ? printer.timeStart + timeToPasteEnd: 0,
    //     printEndTime: printer.timeStart + totalTime,
    //     printEndPasteAmount: printer.currentPasteAmount - pasteSpent
    // }
  };

  public handleClose = () => {
    if (this.props.selectedTabIndex === TabIndex.BUILDS) {
      this.props.history.push("/dashboard/ReviewPage/Builds");
    }
    else {
      this.props.history.push("/dashboard/home");

    }
  };

  public initSelectedPrinter = () => {
    const machineInfo = this.state?.build?.machineConfig;
    const machine = machineInfo?.originalJson?.id;
    // this.setState({
    //   selPrinter: this.state?.build?.machineConfig?.originalJson?.id || this.state?.build?.machineConfig?.id
    // });
    if (machine) {
      // const prefix = "default_beta"
      // if (machine.indexOf(prefix) == 0) {
      let number = machine.replace(/[^0-9]/g, "");
      console.log("number~~~", number);
      let select = this.state?.printerList?.find((a) => a.id === machine);
      if (select) {
        console.info(select);
        this.setState({
          selPrinter: select,
        });      
      }
    }

  
  };
  public setSelectedPrinter = (name) => {
    console.log("setSelectedPrinter~~~", name);
    this.setState({
      selPrinter:
        this.state.build.machineConfig.originalJson.id ||
        this.state.build.machineConfig.id,
    });
  };
  public print = async (ev) => {
    this.isLoading("showLoading");
    console.log("print~~rr~", this.state, this.props);
    ev.stopPropagation();
    if (this.state?.selPrinter?.GrafanaBundle) {
      let path = this.state.build.bundle;
      const parentDir = path.split("/")[path.split("/").length - 2];
      const bundleSplit = parentDir.split("Build_");
      const bundleTime = bundleSplit[1];
      let targetName = `${bundleTime}_${this.state.build.id}_Lava.gcode`;
      let keyV = `${path}${this.state.build.id}_Lava.gcode`;
      console.log("print~~rr11~", keyV, targetName, this.state.selPrinter);
      await API.post(
        "lavaRestApi",
        `/queue/printers/${this.state.selPrinter.GrafanaBundle}`,
        {
          body: {
            key: keyV,
            targetName: targetName,
          },
        }
      );
      NotificationManager.info(
        `${targetName} sent to ${this.state.selPrinter.GrafanaBundle} queue.`
      );
      this.props.history.push("/dashboard/print");
    } else {
      NotificationManager.error(`Do not have printer queue to push`);
    }
  };

  public onPrinterSelect = (item) => {
    console.log("MVPBuildReviewprops~~onPrinterSelect~", item);
  };
  public handleDialogClose = () => {
    this.setState({
      open: false,
    });
  };
  public openPrinters = () => {
    this.setState({
      open: true,
    });
  };
  public expandActual = () => {
    this.setState({
      isExpanded: true,
      isActualExpand: true,
      isScreenChanged: true,
      isscreenChangedTog: !this.state.isscreenChangedTog,
    });
  };
  public collapseActual = () => {
    this.setState({
      isExpanded: false,
      isActualExpand: false,
      isScreenChanged: true,
      isscreenChangedTog: !this.state.isscreenChangedTog,
    });
  };
  public expandSliced = () => {
    this.setState({
      isExpanded: true,
      isSlicedExpand: true,
      isScreenChanged: true,
    });
  };
  public collapseSliced = () => {
    this.setState({
      isExpanded: false,
      isSlicedExpand: false,
      isScreenChanged: true,
    });
  };

  public handleAntSwitchChange = () => {
    this.setState({
      checkedAntSwitch: !this.state.checkedAntSwitch,
    });
  };

  public render() {
    var estimatedPaste = parseInt(this.state.reviewValues?.pasteSpent) || 0;
    //  console.log('MVPBuildReviewprops~~rr~', this.state, this.props, this.state.selectedPart);
    let parts = this.state.build?.parts?.flatMap((entity: PrintableEntity) =>
      "children" in entity ? entity.children : entity
    );

    const imageBox = this.state.resultJson?.ImagesBox;
    //  console.log('MVPBuildReviewprops~~rrparts~', parts);

    const selectedPrinterObj = this.state.printerList?.find((el) => el.id.toLowerCase() === this.state?.build?.machineConfig?.resultJson?.id.toLowerCase()) 
    // console.log('selectedPrinterObj' + JSON.stringify(selectedPrinterObj,null,4))
    const remainingPasteConsumption = selectedPrinterObj?.remainingPasteConsumption
    return (
      <div className="buildReview">
        {this.state.reviewLoading && <LoadingComponent visible={true} />}
        {!this.state.isExpanded && (
          <div className="bp-l">
            <div>
              <div className="bp-l-h pl-3">
                <div className="backIcon" onClick={this.handleClose}></div>
                <div
                  className="back-btn-heading"
                  title={this.state.build?.name || "NoName"}
                >
                  {this.state.build?.name || "noName"}
                </div>
              </div>
              <div className="border-botm"></div>
              <div className="pl-4 pb-1 pt-3">

                <div className="label-text"> Completed Time </div>
                <h6 className="label-value"> {this.state.completedTime ? moment(new Date(this.state.completedTime)).format('MMMM Do YYYY, HH:mm:ss A') : 'Not available'} </h6>
                {this.state.build.moho_version ?
                  (<>
                    <div className="label-text"> Slicer Version </div>
                    <h6 className="label-value"> {this.state.build.moho_version} </h6>
                  </>)
                  : (null)}

                {this.state.plateLink ?
                  (<>
                    <div className="label-text"> Link to Plate</div>
                    <h6 className="label-value link-format"
                      onClick={() => this.onNavigateToPlatesPage(this.state.plateLink)}>
                      {this.state.plateLink}
                    </h6>
                  </>)
                  : (null)}
              </div>

              <hr></hr>
              <Tabs
                defaultActiveKey="printDetails"
                id="uncontrolled-tab-example"
                className="mb-3"
                fill
              >
                <Tab eventKey="printDetails" title="Print ">
                  <div className="bp-l-b printDetails pl-4">
                    <div className="bp-l-b-e print-label">Basic Details :</div>
                    <div className="label-text">
                      Estimated Print Time
                    </div>
                    <div className="label-value">
                      {this.state.reviewValues?.totalTime?(
                        (this.state.reviewValues?.totalTime - Date.now()) /
                        1000 /
                        60 /
                        60 )
                        .toString()
                        .split(".")[0] +
                        " hr : " +
                        (
                          Math.min((this.state.reviewValues?.totalTime - Date.now()) /
                            1000 %
                            (60 * 60) /
                            60, 59.0)
                        )
                          .toString()
                          .split(".")[0] +
                        " min" :""}
                    </div>
                 
                    <div className="label-text">
                      Estimated Paste Consumption
                    </div>
                    <div className="label-value">
                      {estimatedPaste ?estimatedPaste + " mL" : ""}
                    </div>
                    {/* <div className="label-text">
                      Remaining Paste Consumption
                    </div>
                    <div className="label-value">
                      {remainingPasteConsumption? `${parseInt(remainingPasteConsumption).toFixed()} mL` :'' }
                    </div> */}
                    <div className="label-text">Selected Printer</div>
                    <div className="label-value">
                      {this.state?.build?.machineConfig?.resultJson?.id}                 
                      {/* <Select onChange={ ev => {
            ev.stopPropagation()
            this.setSelectedPrinter(ev.target.value as string)
            }}
                value={this.state.selPrinter}
        >
            <MenuItem value={""}>not selected</MenuItem>
            {this.state.printerList? this.state.printerList.map( printer => {
                return <MenuItem value={printer.name}>{printer.name}</MenuItem>
            }) : null}
        </Select> */}
                    </div>
                    {this.props?.build?.current_status !=='failed' &&
                    <div className="paste-consumption-btn" onClick={
                      this.handleisplayPasteConsumptionModalShow
                    }>
                      <span>
                        <img width="15" src={chart} alt="chart"></img>
                      </span>
                      <span style={{ width: "150px" }}>
                         Paste Consumption
                      </span>
                      <span style={{ width: "50px", textAlign: "right" }}>
                        <img width="8" src={rightarrow} alt="edit"></img>
                      </span>
                    </div>
  }
                
                    {/* <div style={{ color:'#ff6900', marginTop:'10px', cursor:'pointer'}} onClick={this.handleisplayPasteConsumptionModalShow} className="label-text" >n</div> */}
                    {/* {this.state.plateName ? (
                      <>
                        <div className="bp-l-b-e">Plate Name</div>
                        <div className="bp-l-b-e-v">{this.state.plateName}</div>
                      </>
                    ) : null} */}                  
                  </div>
                </Tab>
                <Tab eventKey="configDetails" title="Config">
                  <div className="config-details pl-4">
                    <div className="label-text">
                      Subtractive Strategy
                    </div>
                    <div className="label-value">
                      {this.state.subtractiveStrategy
                        ? this.state.subtractiveStrategy
                        : "Not Available"}
                    </div>
                    <div className="label-text">Drying Strategy </div>
                    <div className="label-value">
                      {this.state.dryingStrategy
                        ? this.state.dryingStrategy
                        : "Not Available"}
                    </div>
                    <div className="label-text">Material +Scaling </div>
                    <div className="label-value">
                      {this.state.meterialScale
                        ? this.state.meterialScale
                        : "Not Available"}
                    </div>
                    {this.state.partConfig.map((part) => (
                      <div key={part.UUID}>
                        <div className="label-text">
                          Parts Detailing
                        </div>
                        <div className="label-value">
                          {part.properties.PartID
                            ? part.properties.PartID
                            : "Not Available"}
                        </div>
                        <div className="multi-row py-2">
                          <div style={{ flexGrow: "2" }}>
                            <div className="label-text">
                              Adtv. Strategy
                            </div>
                            <div className="label-value">
                              {part.properties.PartConfig.originalJson
                                ? part.properties.PartConfig.originalJson.id
                                : "Not Available"}
                            </div>
                          </div>
                          <div>
                            <div className="label-text">
                              Adtv. Paths
                            </div>
                            <div className="label-value">
                              {part.properties.SlicerConfig.originalJson
                                ? part.properties.SlicerConfig.originalJson.id
                                : "Not Available"}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Tab>
                <Tab eventKey="toolsDetails" title="Tools">
                  <div className="config-details">
                    { this.state.buildTools?.length !== 0 && (
                      <ul className="build-tools">
                        <li>
                          <span style={{ width: "50px" }}>#Tool</span>
                          <span style={{ width: "150px" }}>Used Tool Name</span>
                          <span style={{ width: "50px" }}></span>
                        </li>
                        {this.state.buildTools?.map((tool, idx) => (
                          <li
                            key={idx}
                            onClick={() =>
                              this.handleShowBuildTools(tool.ToolName)
                            }
                          >
                            <span style={{ width: "50px" }}>
                              {tool.ToolNumber}
                            </span>
                            <span style={{ width: "150px" }}>
                              {this.getToolName(tool.ToolNumber)}
                            </span>
                            <span style={{ width: "50px", textAlign: "right" }}>
                              <img width="8" src={rightarrow} alt="edit"></img>
                            </span>
                          </li>
                        ))}
                      </ul>
                    )}
                    {this.state.buildTools?.length === 0 && (
                      <p className="m-4 c-666">
                        This build was completed without the use of any tools.
                      </p>
                    )}
                  </div>
                </Tab>
              </Tabs>
            </div>

            <div className="bp-l-f">
              <div>
                {!this.state.isConfigDisabled ? (
                  <S3ObjectTree
                    path={
                      this.state?.build?.bundle?.substring("public/".length) ||
                      ""
                    }
                    prefix={this.state.build.id}
                  />
                ) : null}
              </div>
              <div>
                {!this.state.isConfigDisabled ? (
                  <Button
                    className="btn btn-secondary"
                    disabled={this.state.isConfigDisabled}
                    onClick={(ev) => {
                      ev.stopPropagation();
                      this.props.onDownloadBundle(this.state.build);
                    }}
                  >
                    Download Bundle
                  </Button>
                ) : null}
              </div>
              <div>
               
                <Button
                  className="btn btn-primary"
                  disabled={!this.state.isBuildLoaded}
                  autoFocus
                 // onClick={this.print}
                  onClick={this.state.warnings.length !==0 ? this.handleBuildWarningModalShow : this.print}
                >
                  Send To Printer
                </Button>
              </div>
              <Modal show={this.state.showBuildWarningModal} onHide={this.handleBuildWarningModalHide}>
                <Modal.Header closeButton>
                 <h5>Warnings</h5>
                </Modal.Header>
                <Modal.Body style={{display:'flex',flexDirection :'column'}}>
                  <div className="">
                  <div className="warning-build-review">
                    <svg style={{ marginLeft: '30px', cursor: 'pointer', width: '40px', height: '40px', alignSelf: 'center', marginTop: '30px' }} xmlns="http://www.w3.org/2000/svg" width="28.832" height="26.135" viewBox="0 0 28.832 26.135">
                      <g id="warning" transform="translate(0 -23.946)">
                        <path id="Path_36041" data-name="Path 36041" d="M201.439,26.125,211.5,43.558a4.35,4.35,0,0,1-3.77,6.523H197.67l-3.481-13.067,3.481-13.067A4.348,4.348,0,0,1,201.439,26.125Z" transform="translate(-183.254 0)" fill="#3b4145" />
                        <path id="Path_36042" data-name="Path 36042" d="M10.646,26.125.581,43.558a4.35,4.35,0,0,0,3.77,6.523H14.416V23.946A4.348,4.348,0,0,0,10.646,26.125Z" transform="translate(0 0)" fill="#3a4145" />
                        <path id="Path_36043" data-name="Path 36043" d="M269.582,73.619,259.517,56.186a2.619,2.619,0,0,0-2.188-1.308l8.608,22.653h1.382a2.61,2.61,0,0,0,2.262-3.912Z" transform="translate(-242.838 -29.19)" fill="#ffd749" />
                        <path id="Path_36044" data-name="Path 36044" d="M54.216,73.589a2.931,2.931,0,0,1,.3,1.3,2.454,2.454,0,0,1-2.252,2.61H33.516a2.61,2.61,0,0,1-2.262-3.912L41.319,56.156a2.622,2.622,0,0,1,2.262-1.309l.075,0a2.238,2.238,0,0,1,1.877,1.308Z" transform="translate(-29.166 -29.161)" fill="#ffd764" />
                        <path id="Path_36045" data-name="Path 36045" d="M256,354.131v2.9a1.45,1.45,0,1,0,0-2.9Z" transform="translate(-241.584 -311.592)" fill="#3b4145" />
                        <path id="Path_36046" data-name="Path 36046" d="M231.7,354.131c.16,0,.29.649.29,1.45s-.13,1.45-.29,1.45a1.45,1.45,0,1,1,0-2.9Z" transform="translate(-217.279 -311.592)" fill="#3a4145" />
                        <path id="Path_36047" data-name="Path 36047" d="M256,132.646v10.732a1.45,1.45,0,0,0,1.45-1.45V134.1A1.45,1.45,0,0,0,256,132.646Z" transform="translate(-241.584 -102.579)" fill="#3b4145" />
                        <path id="Path_36048" data-name="Path 36048" d="M231.7,132.646c.16,0,.29.649.29,1.45v7.832c0,.8-.13,1.45-.29,1.45a1.45,1.45,0,0,1-1.45-1.45V134.1A1.45,1.45,0,0,1,231.7,132.646Z" transform="translate(-217.279 -102.579)" fill="#3a4145" />
                      </g>
                    </svg>
                    <p>Please review the warnings for this build before sending the job to the printer.</p>
                  </div>
                   
                  {/* {this.state.warnings.length !== 0 && (
                    this.state.warnings.map((el, index) => (
                      <div  key={index} style={{display:'flex',alignItems:'center',justifyContent:'start'}}>
                        <div style={{marginRight :'20px'}}>
                          <svg style={{ marginRight: '0px', width: '20px', height: '20px', alignSelf: 'center', marginBottom: '0px' }} xmlns="http://www.w3.org/2000/svg" width="28.832" height="26.135" viewBox="0 0 28.832 26.135">
                            <g id="warning" transform="translate(0 -23.946)">
                              <path id="Path_36041" data-name="Path 36041" d="M201.439,26.125,211.5,43.558a4.35,4.35,0,0,1-3.77,6.523H197.67l-3.481-13.067,3.481-13.067A4.348,4.348,0,0,1,201.439,26.125Z" transform="translate(-183.254 0)" fill="#3b4145" />
                              <path id="Path_36042" data-name="Path 36042" d="M10.646,26.125.581,43.558a4.35,4.35,0,0,0,3.77,6.523H14.416V23.946A4.348,4.348,0,0,0,10.646,26.125Z" transform="translate(0 0)" fill="#3a4145" />
                              <path id="Path_36043" data-name="Path 36043" d="M269.582,73.619,259.517,56.186a2.619,2.619,0,0,0-2.188-1.308l8.608,22.653h1.382a2.61,2.61,0,0,0,2.262-3.912Z" transform="translate(-242.838 -29.19)" fill="#ffd749" />
                              <path id="Path_36044" data-name="Path 36044" d="M54.216,73.589a2.931,2.931,0,0,1,.3,1.3,2.454,2.454,0,0,1-2.252,2.61H33.516a2.61,2.61,0,0,1-2.262-3.912L41.319,56.156a2.622,2.622,0,0,1,2.262-1.309l.075,0a2.238,2.238,0,0,1,1.877,1.308Z" transform="translate(-29.166 -29.161)" fill="#ffd764" />
                              <path id="Path_36045" data-name="Path 36045" d="M256,354.131v2.9a1.45,1.45,0,1,0,0-2.9Z" transform="translate(-241.584 -311.592)" fill="#3b4145" />
                              <path id="Path_36046" data-name="Path 36046" d="M231.7,354.131c.16,0,.29.649.29,1.45s-.13,1.45-.29,1.45a1.45,1.45,0,1,1,0-2.9Z" transform="translate(-217.279 -311.592)" fill="#3a4145" />
                              <path id="Path_36047" data-name="Path 36047" d="M256,132.646v10.732a1.45,1.45,0,0,0,1.45-1.45V134.1A1.45,1.45,0,0,0,256,132.646Z" transform="translate(-241.584 -102.579)" fill="#3b4145" />
                              <path id="Path_36048" data-name="Path 36048" d="M231.7,132.646c.16,0,.29.649.29,1.45v7.832c0,.8-.13,1.45-.29,1.45a1.45,1.45,0,0,1-1.45-1.45V134.1A1.45,1.45,0,0,1,231.7,132.646Z" transform="translate(-217.279 -102.579)" fill="#3a4145" />
                            </g>
                          </svg>
                        </div>
                        <p> {el.slice(el.search('[warning]') + 8)}</p>
                      </div>
                    ))
                  )} */}
                  </div>
                  </Modal.Body>
                <Modal.Footer className="warning-popup-footer" style={{display:'flex',justifyContent:'center'}}>
                  <Button className="footer-btn" variant="secondary" onClick={this.handleBuildWarningModalHide}>
                    Cancel
                  </Button>
                  <Button className="footer-btn" variant="primary" onClick={this.print}>
                    Proceed
                  </Button>
                </Modal.Footer>
              </Modal>
              {/* <Form>
                <Form.Check
                  type="checkbox"
                  defaultChecked={!this.state.isConfigDisabled}
                  disabled={this.state.isConfigDisabled}
                  label="Bundle"
                  onClick={(ev) => {
                    ev.stopPropagation();
                    this.props.onDownloadBundle(this.state.build.id);
                  }}
                />
              </Form> */}
              {/* <div className="bundle-checkbox"> 
                <input
                  type={"checkbox"}
                  defaultChecked={!this.state.isConfigDisabled}
                  disabled={this.state.isConfigDisabled}
                  onClick={(ev) => {
                    ev.stopPropagation();
                    this.props.onDownloadBundle(this.state.build.id);
                  }}
                />
                <label> Bundle</label>
              </div> */}
            </div>
          </div>
        )}
        {(this.state.build as any).current_status !== "completed" ? (
          <MVPBadBuildReview build={this.state.build} />
        ) : (
          <div
            className="bp-r"
            style={
              this.state.fullScreen
                ? { position: "absolute", left: 0, right: 0, top: 0, bottom: 0 }
                : {}
            }
          >
            <div className="buildReviewScreen">
              <div
                className={
                  this.state.isActualExpand === false
                    ? this.state.isSlicedExpand === false
                      ? "actualView"
                      : "actualView w-0 d-none "
                    : "actualView w-100"
                }
              >
                <div className="viewBody">
                  {
                    <WorkspaceScene
                      refProcessor={(scene) => this.setScene(scene)}
                      dispatch={this.dispatch}
                      parts={parts}
                      recipe={this.state.build?.recipe?.resultJson}
                      selectedMatrix={this.state.selectedMatrix}
                      materialConfig={
                        this.state.build?.materialConfig?.resultJson
                      }
                      machineConfig={
                        this.state.build?.machineConfig?.resultJson
                      }
                      screenName={"build-review"}
                      screenChangedTog={this.state.isscreenChangedTog}
                    />
                  }
                </div>
              </div>
              <div
                className={
                  this.state.isSlicedExpand === false
                    ? this.state.isActualExpand === false
                      ? "sliceView"
                      : "sliceView w-0"
                    : "sliceView w-100"
                }
              >
                <div className="viewBody">
                  {this.state.build.bundle ? (
                    <MVPBuildSliceReview
                      buildData={this.state.build}
                      gcodeSTree={this.state.gcodeTree}
                      meshesSTree={this.state.meshesTree}
                      screenChanged={this.state.isScreenChanged}
                      isLoading={this.isLoading}
                      imageBox={imageBox}
                      fullScreen={this.state.fullScreen}
                      fullScreenChange={(checked) => {
                        this.setState({
                          ...this.state, fullScreen: checked,
                          isScreenChanged: this.state.fullScreen !== checked
                        });
                      }}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        )}
        {(this.state.showMVPBuildToolReviewModal) && (
          <MVPBuildToolReviewModal
            showModal={this.state.showMVPBuildToolReviewModal}
            hideModal={this.handleModalClose}
            buildTool={this.state.selectedBuildTool}
            selectedPrinterObj={(selectedPrinterObj?.tools !== undefined) ? selectedPrinterObj.tools :null}
            toolsDetails={this.state.setToolsDetails.length !== 0 ? this.state.setToolsDetails :null}
            estimatedToolLifeNeeded={this.state.resultJson? this.state.resultJson["Times"]["All Print"]["Tool Wear"]:null}
          ></MVPBuildToolReviewModal>
        )}  

        {this.state.showMVPDisplayPasteConsumptionModal && (
          <MVPDisplayPasteConsumptionModal
            showModal={this.state.showMVPDisplayPasteConsumptionModal}
            hideModal={this.handleBisplayPasteConsumptionModalHide}
            pasteConsumption={this.state.pasteConsumption}
           
          ></MVPDisplayPasteConsumptionModal>
        )}     
      </div>
    );
  }
}

export default withRouter(connect(Props, Actions)(MVPBuildReview));


