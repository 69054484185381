import API, { graphqlOperation } from "@aws-amplify/api";
import { onDeleteConfiguration, onResetConfigurationScreen } from "..";

import { createConfigurations } from "../../../../graphql/mutations";

import ACTION_TYPES from "../../../actionTypes";
import {
  IConfiguration,
  IRenameConfigurationModalStatus,
  IState,
} from "../../../Interfaces";
import { NotificationManager } from "react-notifications";

const onRenameConfigurationInit = () => {
  return {
    type: ACTION_TYPES.CONFIGURATION.RENAME.INIT,
    payload: null,
  };
};

const onRenameConfigurationSuccess = (renamedConfiguration) => {
  return {
    type: ACTION_TYPES.CONFIGURATION.RENAME.SUCCESS,
    payload: renamedConfiguration,
  };
};

const onRenameConfigurationFail = (errorMessage: string) => {
  NotificationManager.error('Failure on Rename config');
  console.error('Failure on Rename config - ', errorMessage);
  return {
    type: ACTION_TYPES.CONFIGURATION.RENAME.FAIL,
    payload: errorMessage,
  };
};

const onRenameConfigurationDone = () => {
  return {
    type: ACTION_TYPES.CONFIGURATION.RENAME.DONE,
    payload: null,
  };
};

const onRenameConfiguration = () => {
  return async (dispatch, getState) => {
    dispatch(onRenameConfigurationInit());

    const state = getState() as IState;

    const renameConfigurationModalStatus = state.data.modals
      .renameConfigurationModalStatus as IRenameConfigurationModalStatus;

    const isConfigurationNameEmpty =
      !renameConfigurationModalStatus.inputText as boolean;
      const isConfigurationNameExists =
      state.data.configurations.loadedConfigurations.some(
        (p) => p.name === renameConfigurationModalStatus.inputText
      ) as boolean;

    if (isConfigurationNameEmpty) {
      const errorMessage = "Configuration Name is mandatory." as string;
      dispatch(onRenameConfigurationFail(errorMessage));
      dispatch(onRenameConfigurationDone());
      return;
    }
    if (isConfigurationNameExists) {
      const errorMessage = "Configuration Name already exists." as string;
      dispatch(onRenameConfigurationFail(errorMessage));
      dispatch(onRenameConfigurationDone());
      return;
    }

    try {
      const state = getState() as IState;
      let configurationToBeRenamed;
      if (state.data.configurations.isRestoreInitiated) {
        configurationToBeRenamed = {
          ...state.data.configurations.deletedConfigurations.find(
            (p) => p.id === state.data.configurations.restoreHighlightedConfigurationId
          ),
        } as IConfiguration;
      }
      else {
        configurationToBeRenamed = {
          ...state.data.configurations.loadedConfigurations.find(
            (p) => p.id === state.data.configurations.highlightedConfigurationId
          ),
        } as IConfiguration;
      }     
      const id = renameConfigurationModalStatus.inputText.replace(/\s/g, "");
      const dataJSON = {
        ...JSON.parse(configurationToBeRenamed["data"]),
        id: id,
      };
      configurationToBeRenamed.id = renameConfigurationModalStatus.inputText;
      configurationToBeRenamed.name = renameConfigurationModalStatus.inputText;
      delete configurationToBeRenamed._deleted;
      delete configurationToBeRenamed.archive;
      delete configurationToBeRenamed.ConfigurationGroupId;
      delete configurationToBeRenamed.eventName;
      delete configurationToBeRenamed._version;
      delete configurationToBeRenamed._lastChangedAt;
      delete configurationToBeRenamed.createdAt;
      delete configurationToBeRenamed.updatedAt;    
      configurationToBeRenamed.data = JSON.stringify(dataJSON);
      configurationToBeRenamed.default = false;
      configurationToBeRenamed.created_at = new Date().toISOString();
      configurationToBeRenamed.dumb = 1;
      configurationToBeRenamed.creator = JSON.parse(
        JSON.stringify(dataJSON).replace(/\\/g, "")
      ).creator;
      configurationToBeRenamed.modifier = JSON.parse(
        JSON.stringify(dataJSON).replace(/\\/g, "")
      ).modifier;
      const variables = {
        input: configurationToBeRenamed,
      };
      const result = await API.graphql(
        graphqlOperation(createConfigurations, variables)
      );
      const renamedConfiguration = (result as any)?.data
        ?.createConfigurations as IConfiguration;
      dispatch(onRenameConfigurationSuccess(renamedConfiguration));
      dispatch(onRenameConfigurationDone());
      if(!state.data.configurations.isRestoreInitiated){
        dispatch(onDeleteConfiguration());
      }    
      dispatch(onResetConfigurationScreen());
    } catch (graphqlError) {
      const errorMessage = `Rename configuration failed: ${Object(
        (graphqlError as any)?.errors?.[0]?.message
      ).toString()}`;
      dispatch(onRenameConfigurationFail(errorMessage));
      dispatch(onRenameConfigurationDone());
    }
  };
};

export default onRenameConfiguration;
