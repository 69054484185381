import { IState } from "../../../Interfaces";

const onShowOwnedByBuildsReducer = (state: IState, action) => {

    const newState = { ...state } as IState;
    newState.data.info.showOwnedByBuilds = !state.data.info.showOwnedByBuilds;
    if(!state.data.info.showOwnedByBuilds)  newState.data.info.showOwnedByAllCategories =false 

    return newState;
};

export default onShowOwnedByBuildsReducer;