import { IConfiguration, IState } from '../../../Interfaces';

const onRestoreHighlightConfigurationReducer = (state: IState, action) => {
    const configurationId = action.payload as string;
    
    const newState = { ...state } as IState;
    newState.data.configurations.restoreHighlightedConfigurationId = configurationId;

    return newState;
};

export default onRestoreHighlightConfigurationReducer;