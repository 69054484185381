import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function SendToPrinterRebuildModal(props) {
  const { showModal, hideModal, highlightedBuildId ,handleRebuildWoSendToPrinter, handleRebuildWSendToPrinter } = props;
  return (
    <Modal centered show={showModal} onHide={hideModal}>
      <Modal.Header>
        <Modal.Title>Rebuild</Modal.Title>
        <span className="close-btn"
						onClick={hideModal}>
					</span>
      </Modal.Header>
      <Modal.Body className="modal-body text-center">
        <h6> After the Rebuild, would you like to send {highlightedBuildId} to the printer?</h6>

      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button variant="secondary" onClick={handleRebuildWoSendToPrinter}>
          No
        </Button>
        <Button variant="primary" onClick={handleRebuildWSendToPrinter}>
          Yes
        </Button>
       
      </Modal.Footer>
    </Modal>
  );
}

export default SendToPrinterRebuildModal;
