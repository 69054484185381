
import { IConfiguration, IState } from '../../../Interfaces';

const onRestoreConfigurationDoneReducer = (state: IState, action) => {
    const status = action.payload as boolean;
    
    const newState = { ...state } as IState;
    newState.data.configurations.isRestoreInitiated = status;
    return newState;
};

export default onRestoreConfigurationDoneReducer;