import API, { graphqlOperation } from '@aws-amplify/api';
import { SortDirection } from "../../../Enums";
import { union } from 'lodash';

import { buildByCurrentStatus, buildByCreatedAt, listBuilds } from '../../../../graphql/queries';

import ACTION_TYPES from '../../../actionTypes';
import { IBuild } from '../../../Interfaces';

import * as subscriptions from '../../../../graphql/subscriptions';
import subscriptionsSetBuilds from "../../Subscriptions/subscriptionsSetBuilds";
import { Storage } from "aws-amplify";
import { updateBuild } from '../../../../graphql/mutations';

const getAvailableBuilds = (builds: IBuild[] = []) => {
    return builds.filter(b => Boolean(b && !b._deleted));
};

const onLoadBuildsStarted = () => {
    return {
        type: ACTION_TYPES.HOME.LOAD.BUILDS.STARTED,
        payload: null,
    };
};

const onLoadBuildsInProgress = (newlyLoadedBuilds, nextToken) => {
    return {
        type: ACTION_TYPES.HOME.LOAD.BUILDS.IN_PROGRESS,
        payload: {builds: newlyLoadedBuilds, nextToken: nextToken},
    };
};

const onLoadBuildsCompleted = () => {
    return {
        type: ACTION_TYPES.HOME.LOAD.BUILDS.COMPLETED,
        payload: null,
    };
};

const onLoadBuildsUpdated = (newlyLoadedBuilds) => {
    if (!newlyLoadedBuilds) return;
    return {
        type: ACTION_TYPES.HOME.LOAD.BUILDS.UPDATED,
        payload: newlyLoadedBuilds,
    };
};

const onLoadBuildsCreated = (newlyLoadedBuilds) => {
    if (!newlyLoadedBuilds) return;
    return {
        type: ACTION_TYPES.HOME.LOAD.BUILDS.CREATED,
        payload: newlyLoadedBuilds,
    };
};

const onLoadBuildsDeleted = (deletedBuilds) => {
    if (!deletedBuilds) return;
    return {
        type: ACTION_TYPES.HOME.LOAD.BUILDS.DELETED,
        payload: deletedBuilds,
    };
};

const queryBuilds = async (variables) => {
  const queryGraphQL = async (query) => {
    let queries = {buildByCurrentStatus: buildByCurrentStatus, buildByCreatedAt: buildByCreatedAt };
    let result = await API.graphql(graphqlOperation(queries[query], variables)) as any;
    return result.data[query];
  }

  if (variables.current_status !== "" && 
      variables.current_status !== "all") {
    return await queryGraphQL('buildByCurrentStatus');
  }

  return await queryGraphQL('buildByCreatedAt');
}

const loadBuilds = async (dispatch, getState, newRequest) => { 
    try {
        const state = getState();
        var   loadedBuilds = newRequest ? [] : state.data?.builds?.loadedBuilds || [];
        const nextToken = newRequest ? undefined : state.data?.builds?.nextToken;
        const sortDirection = state.data?.builds?.sortDirection === SortDirection.ASC ? 'ASC' : 'DESC';
        const searchText = state.data?.builds?.searchText;
        const selectedStatus = state.data.builds.selectedBuildStatus;
        const currentUser = state.creator;
        const showOwnedByFilter = state.data?.info.showOwnedByBuilds;

        if (!newRequest && ! nextToken) {
          return;
        }

        let variables = {
            dumb: 1,
            limit: 50, 
            sortDirection: sortDirection,
            current_status: selectedStatus,
            nextToken: nextToken,
            items: ["id"], //, "created_at", "current_status", "build_result", "index", "name"],
        };

        var useFilter = (searchText && searchText !== "");
        if (useFilter) {
            (variables as any).filter = {
                build_search_string: { contains: searchText },
            };
        }
        if(showOwnedByFilter && currentUser !==""){
            (variables as any).filter = {
                created_by: {eq: currentUser},
            };
        }

        dispatch(onLoadBuildsStarted());
        do {
          const result = await queryBuilds(variables);

          const newlyLoadedBuilds = result?.items || [] as IBuild[];


            // newlyLoadedBuilds.forEach(async (build: any) => {
            //     if (
            //         build.parts[0] &&
            //         JSON.parse(build.parts[0]).Key !== `BuildPartsFiles/${build.id}.json`
            //     ) {
            //         console.log(`NON S3 Reference Build ${build.id}`);
            //         try {
            //             build.noOfParts = build.parts.length;
            //             let partsArrayToS3 = build.parts;
            //             const path = `BuildPartsFiles/${build.id}.json`;
            //             const AWSBucketParam = [JSON.stringify({
            //                 Bucket: Storage["_config"]["AWSS3"]["bucket"],
            //                 Key: path,
            //                 CacheControl: 'no-cache' // or 'max-age=0'
            //             })];
            //             await Storage.put(path,partsArrayToS3, {
            //                 contentType: "application/json",
            //                 completeCallback: (event) => console.log(`Successfully uploaded ${event.key}`),
            //                 progressCallback: (progress) => console.log(`Uploaded: ${progress.loaded}/${progress.total}`),
            //                 errorCallback: (error) => {
            //                     console.error('Error uploading part to S3', error.message)
            //                     // dispatch(onUpdatePlateFail("Update plate failed"));
            //                 }
            //             });
            //             delete build._deleted;
            //             delete build._lastChangedAt;
            //             delete build.createdAt;
            //             delete build.updatedAt;
            //             build["parts"] = AWSBucketParam;

            //             const variables = {
            //                 input: build,
            //             };
            //             const result = await API.graphql(graphqlOperation(updateBuild, variables));
            //             const updatedBuild = ((result as any)?.data?.updateBuild) as IBuild;
                      

            //         } catch (error: any) {
            //             console.error("Error", error.message);
            //             // dispatch(onUpdatePlateFail("Update plate failed"));
            //         }
            //     }
            // });
          
          
            // newlyLoadedBuilds.forEach(async (build: any) => {
            //     if (build.purged === null || build.total_time === null || build.extruded === null) {
            //         const path = build.bundle.replace('public/', '') + 'result.json';
            //         const AWSBucketParam = {
            //             Bucket: Storage["_config"]["AWSS3"]["bucket"],
            //             Key: path,
            //             CacheControl: 'no-cache' // or 'max-age=0'
            //         };
            //         try {
            //             const getResult = await Storage.get(AWSBucketParam.Key, { download: true,  cacheControl: 'no-cache' });
            //             const getBundle = JSON.parse(await (getResult.Body as any).text());
            //             try {
            //                 delete build._deleted;
            //                 delete build._lastChangedAt;
            //                 delete build.createdAt;
            //                 delete build.updatedAt;

            //                 build.total_time = parseInt(getBundle['Times']['All Print']['Slabs']['Total']['seconds']).toString();
            //                 build.extruded = parseInt(getBundle['Times']['All Print']['Slabs']['extruded']).toString();
            //                 build.purged = parseInt(getBundle['Times']['All Print']['Slabs']['purged']).toString();
            //                 const variables = {
            //                     input: build,
            //                 };
            //                 const result = await API.graphql(graphqlOperation(updateBuild, variables));
            //                 const updatedBuild = ((result as any)?.data?.updateBuild) as IBuild;

            //             } catch (error: any) {
            //                 console.error("Failure on Copy Plate:" + error.message);
            //             }


            //         } catch (error: any) {
            //             console.error("Failure on Copy Plate:" + error.message);
            //         }
            //     }
            // });

          const newNextToken = result?.nextToken || undefined;
          if (!newNextToken || (newlyLoadedBuilds.length && !useFilter)) {
            dispatch(onLoadBuildsInProgress(union(loadedBuilds, getAvailableBuilds(newlyLoadedBuilds)), newNextToken));
            break;
          }
          else {
            loadedBuilds = union(loadedBuilds, getAvailableBuilds(newlyLoadedBuilds))
            dispatch(onLoadBuildsInProgress(loadedBuilds, undefined));
            variables.nextToken = newNextToken;
          }
        } while(true);

        dispatch(onLoadBuildsCompleted());
        return Promise.resolve();
    } catch (graphqlError) {
        return Promise.resolve();
    }
};

const onLoadBuilds = (newRequest = false) => {
    return async (dispatch, getState) => {
        const state = getState();
        await loadBuilds(dispatch, getState, newRequest);
        let newSubscriptions = {buildUpdate: null, buildCreate: null, buildDelete: null};

        if (!state.subscriptions?.buildCreate) {
          console.log("Adding BUILD CREATE subscription");
          newSubscriptions.buildCreate = 
            (API.graphql(graphqlOperation(subscriptions.onCreateBuild)) as any).subscribe({
              next: ({ provider, value }) => {
                  console.log({ provider, value })
                  dispatch(onLoadBuildsCreated(value.data.onCreateBuild))
              },
              error: error => console.error(error)
          });
        }
        if (!state.subscriptions?.buildDelete) {
          console.log("Adding BUILD DELETE subscription");
          newSubscriptions.buildDelete = 
            (API.graphql(graphqlOperation(subscriptions.onDeleteBuild)) as any).subscribe({
              next: ({ provider, value }) => {
                  console.log({ provider, value })
                  dispatch(onLoadBuildsDeleted(value.data.onDeleteBuild))
              },
              error: error => console.error(error)
          });
        }
        if (!state.subscriptions?.buildUpdate) {
          console.log("Adding BUILD UPDATE subscription");
          newSubscriptions.buildUpdate = (API.graphql(graphqlOperation(`
                subscription OnUpdateBuild {
                    onUpdateBuild {
                        id
                        current_status
                        build_result
                        build_time
                        path
                        bundle         
                        machine_info
                        moho_version
                        cloudWatchLogName
                        gcode_uuid                          
                        _version
                        _deleted
                    }
                }`)
          ) as any).subscribe({
              next: ({ provider, value }) => {
                  console.log({ provider, value })
                  dispatch(onLoadBuildsUpdated(value.data.onUpdateBuild))
              },
              error: error => console.warn(error)
          });
        }
        if (newSubscriptions.buildCreate || newSubscriptions.buildUpdate || newSubscriptions.buildDelete) {
          dispatch(subscriptionsSetBuilds(newSubscriptions));
        }
    };
};

export default onLoadBuilds;
