import API, { graphqlOperation } from '@aws-amplify/api';
import { NotificationManager } from "react-notifications";
import { updateParts, updatePlate } from '../../../../graphql/mutations';

import ACTION_TYPES from '../../../actionTypes';
import { getNewPlate } from '../../../Instances';
import { IPart, IPlate } from '../../../Interfaces';
import onUpdateOldConfiguraitonAfterUpdatePlate from './updateOldConfiguration';
import onUpdateNewConfiguraitonAfterUpdatePlate from './updateNewConfiguration';
import onUpdateOldPartAfterUpdatePlate from './updateOldPart';
import onUpdateNewPartAfterUpdatePlate from './updateNewPart';
import { Storage } from "aws-amplify";
import { getPlate } from '../../../../graphql/queries';

const onUpdatePartSuccess = (updatedPart: IPart) => {
    return {
        type: ACTION_TYPES.PART.UPDATE.SUCCESS,
        payload: updatedPart,
    };
}

const onUpdatePartFail = (errorMessage: string) => {
    //NotificationManager.error('Failure on updating part');
    console.error('Failure on updating part - ', errorMessage);
    return {
        type: ACTION_TYPES.PART.UPDATE.FAIL,
        payload: errorMessage,
    };
};

const onUpdatePlateInit = () => {
    return {
        type: ACTION_TYPES.PLATE.UPDATE.INIT,
        payload: null,
    };
};

const onUpdatePlateSuccess = (updatedPlate: IPlate) => {
    return {
        type: ACTION_TYPES.PLATE.UPDATE.SUCCESS,
        payload: updatedPlate,
    };
};

const onUpdatePlateFail = (errorMessage: string) => {
    NotificationManager.error('Failure on Update Plate');
    console.error('Failure on Update Plate - ${errorMessage}');
    return {
        type: ACTION_TYPES.PLATE.UPDATE.FAIL,
        payload: errorMessage,
    };
};


const onUpdatePlateDone = () => {
    return {
        type: ACTION_TYPES.PLATE.UPDATE.DONE,
        payload: null,
    };
};

const getPartDetailsFromS3 = async (plateId) => {
    const path = `PlatePartsFiles/${plateId}.json`;
    const AWSBucketParam = {
      Bucket: Storage["_config"]["AWSS3"]["bucket"],
      Key: path,
      CacheControl: 'no-cache' // or 'max-age=0'
    };
    try {
      const getResult = await Storage.get(AWSBucketParam.Key, { download: true,  cacheControl: 'no-cache' });
      const parts = JSON.parse(await(getResult.Body as any).text());
      return parts;
    } catch (error: any) {
      NotificationManager.error('An error occurred during the plate editing process');
      console.error("An error occurred during the plate editing process:", error.message);
    }
  }

const onUpdatePlate = (history) => {

    return async (dispatch, getState) => {
        dispatch(onUpdatePlateInit());

        try {
            const state = getState();
            const plateIndex = state.data.plates.loadedPlates.find(p => p.id === state.data.plates.highlightedPlateId);

            let path = `PlatePartsFiles/${state.data.plates.highlightedPlateId}.json`;
            let AWSBucketParam = {
                Bucket: Storage["_config"]["AWSS3"]["bucket"],
                Key: path,
                CacheControl: 'no-cache' // or 'max-age=0'
            }
            let plateToBeUpdated = plateIndex ? { ...state.data.plates.loadedPlates.find(p => p.id === state.data.plates.highlightedPlateId) } as IPlate : state.data.search.highlightedPlateData;

            if (Object.keys(plateToBeUpdated).length !== 0 &&
                plateToBeUpdated.parts[0]
            ) {
                // const path = `PlatePartsFiles/${plateToBeUpdated.id}.json`;
                // const AWSBucketParam = {
                //     Bucket: Storage["_config"]["AWSS3"]["bucket"],
                //     Key: path,
                //     CacheControl: 'no-cache' // or 'max-age=0'
                // };
                // try {
                //     const getResult = await Storage.get(AWSBucketParam.Key, { download: true, cacheControl: 'no-cache' });
                //     plateToBeUpdated.parts = JSON.parse(await (getResult.Body as any).text());
                // } catch (error: any) {
                //     dispatch(onUpdatePlateFail("Update plate failed"));
                //     console.error("An error occurred during the plate editing process:", error.message);
                // }
                plateToBeUpdated.parts = await getPartDetailsFromS3(plateToBeUpdated.id);
            }
            if (Object.keys(plateToBeUpdated).length === 0) {
                const getPlateDetails = await API.graphql(
                    graphqlOperation(getPlate, {
                      id: state.data.plates.highlightedPlateId,
                    })
                  );
                  plateToBeUpdated = (getPlateDetails as any).data.getPlate;
                  plateToBeUpdated.parts = await getPartDetailsFromS3(plateToBeUpdated.id);
            }
            // else {
            //     try {
            //         const getResult = await Storage.get(AWSBucketParam.Key, { download: true, cacheControl: 'no-cache' });
            //         plateToBeUpdated.parts = JSON.parse(await (getResult.Body as any).text());
            //     } catch (error: any) {
            //         dispatch(onUpdatePlateFail("Update plate failed"));
            //         console.error("An error occurred during the plate editing process:", error.message);
            //     }
            // }

            const oldPartConfigs = plateToBeUpdated.parts.map(part => JSON.parse(part).properties.PartConfig.resultJson.id);
            const oldSlicerConfigs = plateToBeUpdated.parts.map(part => JSON.parse(part).properties.SlicerConfig.resultJson.id);
            const oldConfigIdsToBeUpdated = ([] as string[]).concat(plateToBeUpdated.machineId, plateToBeUpdated.recipeId, plateToBeUpdated.millConfigId, plateToBeUpdated.materialConfigId, ...oldPartConfigs, ...oldSlicerConfigs) as string[];
            const oldConfigsToBeUpdated = state.data.configurations.loadedConfigurations.filter(lc => oldConfigIdsToBeUpdated.some(id => id === lc.id));

            const oldPartIdsToBeUpdated = plateToBeUpdated.parts.map(part => JSON.parse(part).properties.PartID);
            const oldPartsToBeUpdated = state.data.parts.loadedParts.filter(lp => oldPartIdsToBeUpdated.some(id => id === lp.id));

            const newPlate = getNewPlate(state);

            const newPartConfigs = newPlate.parts.map(part => JSON.parse(part).properties.PartConfig.resultJson.id);
            const newSlicerConfigs = newPlate.parts.map(part => JSON.parse(part).properties.SlicerConfig.resultJson.id);
            const newConfigIdsToBeUpdated = ([] as string[]).concat(newPlate.machineId, newPlate.recipeId, newPlate.millConfigId, newPlate.materialConfigId, ...newPartConfigs, ...newSlicerConfigs) as string[];
            const newConfigsToBeUpdated = state.data.configurations.loadedConfigurations.filter(lc => newConfigIdsToBeUpdated.some(id => id === lc.id));

            const newPartIdsToBeUpdated = newPlate.parts.map(part => JSON.parse(part).properties.PartID);
            const newPartIdsCount = new Map<string, number>();
            newPartIdsToBeUpdated.forEach(item => {
                newPartIdsCount.set(item, (newPartIdsCount.get(item) || 0) + 1);
            });
            const removedPartIdsToBeUpdated: string[] = [];
            oldPartIdsToBeUpdated.forEach(item => {
                if (!newPartIdsCount.has(item) || newPartIdsCount.get(item)! < 1) {
                    removedPartIdsToBeUpdated.push(item);
                } else {
                    newPartIdsCount.set(item, newPartIdsCount.get(item)! - 1);
                }
            });
            console.log(removedPartIdsToBeUpdated);
            //  const removedPartIdsToBeUpdated = oldPartIdsToBeUpdated.filter((item) => !newPartIdsToBeUpdated.includes(item)); 
            console.log("👊 ~ return ~ missingItems:", removedPartIdsToBeUpdated)
            let removedPartsToBeUpdated = [];

            if (removedPartIdsToBeUpdated.length > 0) {
                removedPartsToBeUpdated = state.data.parts.loadedParts.filter(lp =>
                    removedPartIdsToBeUpdated.includes(lp.id)
                );
            }
            console.log("👊 ~ return ~ removedPartsToBeUpdated:", removedPartsToBeUpdated)

            removedPartsToBeUpdated.map(async (part: any) => {
                if (part.plates && part.plates.length > 0) {
                    const indexToRemove = part.plates.findIndex(item => JSON.parse(item).name === state.data.plates.highlightedPlateId);
                    if (indexToRemove !== -1) {
                        try {
                            const partToUpdated = part
                            partToUpdated.plates.splice(indexToRemove, 1);
                            delete partToUpdated._deleted;
                            delete partToUpdated._lastChangedAt;
                            delete partToUpdated.createdAt;
                            delete partToUpdated.updatedAt;
                            const variables = {
                                input: partToUpdated,
                            };
                            const result = await API.graphql(graphqlOperation(updateParts, variables));
                            const updatedPart = ((result as any)?.data?.updateParts) as IPart;
                            dispatch(onUpdatePartSuccess(updatedPart));

                        } catch (graphqlError) {
                            const errorMessage = `Update part failed: ${Object((graphqlError as any)?.errors?.[0]?.message).toString()}`;
                            dispatch(onUpdatePartFail(errorMessage))
                            console.error(errorMessage)
                        }
                    }
                }

            });
            const newPartsToBeUpdated = state.data.parts.loadedParts.filter(lp => newPartIdsToBeUpdated.some(id => id === lp.id));
            const groupedPartsToBeUpdated = newPartIdsToBeUpdated.reduce((acc, filename) => {
                if (!acc[filename]) {
                    acc[filename] = 0;
                }
                acc[filename]++;
                return acc;
            }, {});

            try {
                let partsArrayToS3 = newPlate['parts'];
                const fileName = plateToBeUpdated.id + '.json';
                let path = `PlatePartsFiles/` + fileName;
                await Storage.put(path, partsArrayToS3, {
                    contentType: "application/json",
                    completeCallback: (event) => console.log(`Successfully uploaded ${event.key}`),
                    progressCallback: (progress) => console.log(`Uploaded: ${progress.loaded}/${progress.total}`),
                    errorCallback: (error: any) => {
                        console.error('Error uploading part to S3', error.message)
                        dispatch(onUpdatePlateFail("Update plate failed"));
                    }
                });
                plateToBeUpdated.parts = path;
            } catch (error: any) {
                console.error("Error", error.message);
                dispatch(onUpdatePlateFail("Update plate failed"));
            }
            plateToBeUpdated.noOfParts = newPlate.parts.length;
            plateToBeUpdated.machineConfig = newPlate.machineConfig;
            plateToBeUpdated.machineId = newPlate.machineId;
            plateToBeUpdated.millConfig = newPlate.millConfig;
            plateToBeUpdated.millConfigId = newPlate.millConfigId;
            plateToBeUpdated.materialConfig = newPlate.materialConfig;
            plateToBeUpdated.materialConfigId = newPlate.materialConfigId;
            plateToBeUpdated.recipe = newPlate.recipe;
            plateToBeUpdated.recipeId = newPlate.recipeId;
            plateToBeUpdated.modified_by = state.creator;
            plateToBeUpdated.modified_at = new Date().toISOString();

            delete plateToBeUpdated._deleted;
            delete plateToBeUpdated._lastChangedAt;
            delete plateToBeUpdated.createdAt;
            delete plateToBeUpdated.updatedAt;
            plateToBeUpdated['parts'] = JSON.stringify(AWSBucketParam);
            const variables = {
                input: plateToBeUpdated,
            };
            const result = await API.graphql(graphqlOperation(updatePlate, variables));
            const updatedPlate = ((result as any)?.data?.updatePlate) as IPlate;
            updatedPlate['parts'] = [JSON.stringify(AWSBucketParam)];
            dispatch(onUpdatePlateSuccess(updatedPlate));



            // oldPartsToBeUpdated.forEach(part => dispatch(onUpdateOldPartAfterUpdatePlate(part, updatedPlate)));
            newPartsToBeUpdated.forEach(part => {
                let noOfPartInstance = groupedPartsToBeUpdated[part.id];
                dispatch(onUpdateNewPartAfterUpdatePlate(part, updatedPlate, noOfPartInstance))
            });

            // oldConfigsToBeUpdated.forEach(config => dispatch(onUpdateOldConfiguraitonAfterUpdatePlate(config, updatedPlate)));
            newConfigsToBeUpdated.forEach(config => dispatch(onUpdateNewConfiguraitonAfterUpdatePlate(config, updatedPlate)));
            NotificationManager.success(
                `Updated Succesfully`
            );
        }
        catch (graphqlError) {
            const errorMessage = `Update plate failed: ${Object((graphqlError as any)?.errors?.[0]?.message).toString()}`;
            dispatch(onUpdatePlateFail(errorMessage));
        }
        dispatch(onUpdatePlateDone());
    };
};

export default onUpdatePlate;