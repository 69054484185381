import { IState } from "../../../Interfaces";

const onShowOwnedByAllCategoriesReducer = (state: IState, action) => {

    const newState = { ...state } as IState;
    newState.data.info.showOwnedByAllCategories = !state.data.info.showOwnedByAllCategories;
    newState.data.info.showOwnedByParts = state.data.info.showOwnedByAllCategories;
    newState.data.info.showOwnedByPlates = state.data.info.showOwnedByAllCategories;
    newState.data.info.showOwnedByBuilds = state.data.info.showOwnedByAllCategories;

    return newState;
};

export default onShowOwnedByAllCategoriesReducer;