import { IState } from "../../../Interfaces";

const onUnselectPlatesReducer = (state: IState, action) => {

    const newState = { ...state } as IState;
    newState.data.plates.selectedPlateIds = [];
    newState.data.plates.selectedPlates = [];
    return newState;
};

export default onUnselectPlatesReducer;
