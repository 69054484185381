import React, { useEffect, useState } from "react";
import { DataGrid } from "@material-ui/data-grid";
import API, { graphqlOperation } from "@aws-amplify/api";
import { listUsers } from "../../../graphql/queries";
import LoadingComponent from "../../../LoadingComponent/LoadingComponent";
import { Button, Modal } from "react-bootstrap";
import { deleteUser } from "../../../graphql/mutations";
import { NotificationManager } from "react-notifications";
import { Auth } from "aws-amplify";

const UserList = (props) => {
  const [userList, setUserList] = useState([]);
  const [showLoader, setShowLoader] = useState(false); // Loader visible state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteUserObj, setDeleteUserObj] =
    useState<{ _version: string; id: string }>();
  const isMountedRef = React.useRef(true);

  useEffect(() => {
    isMountedRef.current = true;
    getUsersList();
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const getUsersList = async () => {
    setShowLoader(true);
    try {
      const response = (await API.graphql(graphqlOperation(listUsers))) as any;
      if (isMountedRef.current && response?.data && response.data.listUsers) {
        await Auth.currentAuthenticatedUser().then((user) => {
          setShowLoader(false);
          let { items } = response.data.listUsers;
          const currentUserRole = user.signInUserSession.accessToken.payload["cognito:groups"][0] === "SuperAdmin";
          items = items.filter((userList) => userList.email !== user.username && userList.roleId !== "SuperAdmin");
          // items = items.filter((userList) => userList.email !== user.username);
          // if(!currentUserRole) {
          //   items = items.filter((userList) => userList.roleId !== "SuperAdmin");
          // }
          setUserList(items);
        });
      }
    } catch (error) {
      NotificationManager.error("Error");
      setShowLoader(false);
    }
  };

  const columns = [
    {
      field: "firstName",
      headerName: "User Name",
      sortable: true,
      width: 350,
    },
    {
      field: "roleId",
      headerName: "Role",
      sortable: false,
      width: 300,
      renderCell: (params) => {
        return (
          <div style={{ textTransform: "capitalize" }}>{params.row.roleId}</div>
        );
      },
    },
    // {
    //   field: "client",
    //   headerName: "Client",
    //   sortable: false,
    //   width: 250,
    // },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      width: 250,
      renderCell: (params) => {
        return (
          <div className="position-relative">
            <span
              className={
                params.row.status === "ACTIVE"
                  ? "active user-grid-status"
                  : "inActive user-grid-status"
              }
            ></span>
            <span
              className={
                params.row.status === "ACTIVE"
                  ? "active user-status-text"
                  : "inActive user-status-text"
              }
            >
              {params.row.status.toLowerCase()}
            </span>
          </div>
        );
      },
    },
    {
      field: "email",
      headerName: "Email ID",
      sortable: false,
      width: 350,
    },
    {
      field: "phoneNo",
      headerName: "Phone",
      sortable: false,
      width: 300,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 200,
      renderCell: (params) => {
        return (
          <div className={params.row.roleId === "SuperAdmin" ? "user-grid-actions not-allowed" : "user-grid-actions"} >
            <span
              className="edit-icon"
              onClick={() => {
                editUser(params.row);
              }}
            ></span>
            <span
              className="delete-icon"
              onClick={() => {
                deleteUserClick(params.row.id, params.row._version);
              }}
            ></span>
          </div>
        );
      },
    },
  ];

  const editUser = (data) => {
    props.enableCreateUser(true);
    props.editUserData(data);
  };

  const deleteUserClick = (id, version) => {
    setIsModalOpen(true);
    setDeleteUserObj({ _version: version, id: id });
  };

  const handleModalDelete = async () => {
    setIsModalOpen(false);
    setShowLoader(true);
    try {
      let response = await API.graphql(
        graphqlOperation(deleteUser, {
          input: { id: deleteUserObj?.id, _version: deleteUserObj?._version },
        })
      );
      if (response) {
        getUsersList();
        setShowLoader(false);
        NotificationManager.success("User Deleted Successfully");
      }
    } catch (error) {
      NotificationManager.error("Error");
      setShowLoader(false);
    }
  };

  return (
    <div>
      <LoadingComponent visible={showLoader} />
      <div className="user-management-grid">
        <DataGrid
          rows={userList}
          columns={columns}
          autoHeight
          autoPageSize
          disableSelectionOnClick
          disableColumnMenu
          className="list-view-grid"
          hideFooterPagination
        />
      </div>

      {/* Delete user popup modal */}
      <Modal show={isModalOpen} backdrop="static" keyboard={false} centered>
        <Modal.Header>
          <Modal.Title>Delete User</Modal.Title>
          <span
            className="close-btn"
            onClick={() => {
              setIsModalOpen(false);
            }}
          ></span>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-4">
            <h4>Are you sure you want to delete this user?</h4>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-end">
          <Button
            className="btn btn-secondary"
            onClick={() => {
              setIsModalOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            className="btn btn-primary"
            onClick={() => {
              handleModalDelete();
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserList;
