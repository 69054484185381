import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { onCopyPlateCloseDialog, onCopyPlateNameChange, onCopyPlate } from '../../../Actions/Plates';
import { IState, ICopyPlateModalStatus } from '../../../Interfaces';

const Props = (state: IState, ownProps: any) => {

    const copyPlateModalStatus = state.data.modals.copyPlateModalStatus as ICopyPlateModalStatus;

    const props = {
        isModalOpen: copyPlateModalStatus.isOpen,
        inputText: copyPlateModalStatus.inputText,
        hasError: copyPlateModalStatus.hasError,
        errorMessage: copyPlateModalStatus.errorMessage,
        isValid: copyPlateModalStatus.isValid,
    };

    return props;
};

const Actions = {
    onCopyPlateCloseDialog: onCopyPlateCloseDialog,
    onCopyPlateNameChange: onCopyPlateNameChange,
    onCopyPlate: onCopyPlate,
};

class Component extends React.Component<any, any> {

    private onCopyPlateNameChange(event) {
        const value = event.target.value.split(/[^a-zA-Z0-9._-]/).join('');
        this.props.onCopyPlateNameChange(value);
    }

    public render() {
        return (
            <>
                {
                    (this.props.isModalOpen) ?
                        (
                            <>
                                <Modal
                                    show={this.props.isModalOpen}
                                    onHide={() => this.props.onCopyPlateCloseDialog()}
                                    backdrop='static'
                                    keyboard={false}
                                    dialogClassName='platesDialog'
                                    aria-labelledby='contained-modal-title-vcenter'
                                    centered>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Copy</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className='each-dialog-content'>
                                            <div className='each-d-section'>
                                                Enter the name for the new plate
                                            </div>
                                            <div className='each-d-section'>
                                                <input className='f-w'
                                                    type='text'
                                                    value={this.props.inputText}
                                                    onChange={(event) => this.onCopyPlateNameChange(event)}
                                                />
                                            </div>
                                            <div className='hint-msg'>
                                                Name can contain only a-z, A-Z, 0-9, ._-
                                            </div>
                                            <div className='invalid-feedback d-block'
                                                hidden={!(this.props.hasError)}>
                                                {this.props.errorMessage}
                                            </div>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer className="justify-content-center">
                                        <Button
                                            className='btn-primary'
                                            onClick={() => this.props.onCopyPlate(this.props.history)}
                                            autoFocus>
                                            Copy Plate
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </>
                        ) :
                        (null)
                }

            </>
        );
    }
}

const CopyPlate = withRouter(connect(Props, Actions)(Component));

export default CopyPlate;