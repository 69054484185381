import { IState } from "../../../Interfaces";

const onSelectPlateReducer = (state: IState, action) => {
    const plateId = action.payload as string;
    
    const newState = { ...state } as IState;
    const isPlateAvaialble = newState.data.plates.loadedPlates.some((plate) => plate.id === plateId)
    const selectedPlate = newState.data.plates.loadedPlates.filter((plate) => plate.id === plateId)
    if(isPlateAvaialble){
        newState.data.plates.selectedPlates = newState.data.plates.selectedPlates.concat(selectedPlate)
    }
    newState.data.plates.selectedPlateIds = newState.data.plates.selectedPlateIds.concat(plateId);
    
    return newState;
};

export default onSelectPlateReducer;