import { IPart, IState, IDisplayPart } from "../Interfaces";

const getDisplayPart = (part: IPart, state: IState) => {
	const partId = part.id || "";
	const partName = part.name || "";
	const icon = part.icon || "";
	const displayPart = {
		id: partId as string,
		name: partName as string,
		icon: icon as string,
		files: JSON.parse(part.files),
		index: state.data.parts.loadedParts.indexOf(part) as number,
		isValid: Boolean(part) as boolean,
		isFiltered: Boolean(
			state.data.parts.selectedPartIds.some((id) => id === part.id) ||
			part.name
				.toLowerCase()
				.includes(state.data.parts.searchText.toLowerCase())
		) as boolean,
		isSelected: Boolean(
			state.data.parts.selectedPartIds.some((id) => id === part.id)
		) as boolean,
		isHighlighted: Boolean(
			state.data.parts.highlightedPartId === part.id
		) as boolean,
		isRecent: Boolean(
			(new Date().getTime() - new Date(part.createdAt).getTime()) /
			(60 * 60 * 1000) <
			24
		) as boolean,
		isYours: Boolean(
			part.creator === state.creator && !Boolean(part.archive)
		) as boolean,
		isOthers: Boolean(
			part.creator !== state.creator && !Boolean(part.archive)
		) as boolean,
		isArchived: Boolean(part.archive),
		isMovedToFolder: Boolean(part.folders && part.folders.length > 0),
		createdDate: (new Date(part.createdAt) as Date).toLocaleString() as string,
		creator: part.creator as string,
		hasBuilds: Boolean(part.hasBuilds) as boolean,
		builds: (Boolean(part.builds) ? part.builds.map(build => JSON.parse(build)['name']) : []) as string[],
		hasPlates: Boolean(part.hasPlates) as boolean,
		plates: (Boolean(part.plates) ? part.plates.map(plate => JSON.parse(plate)['name']) : []) as string[],
		hasFolders: Boolean(part.hasFolders) as boolean,
		folders: (Boolean(part.folders) ? part.folders.map(folder => JSON.parse(folder)['name']) : []) as string[],
		hasTags: Boolean(part.hasTags) as boolean,
		tags: (Boolean(part.tags) ? part.tags.map(tag => JSON.parse(tag)['name']) : []) as string[] as string[],
		createdAt: part.createdAt
	};

	if (
		false &&
		state.data.folders.selectedFolderIds &&
		state.data.folders.selectedFolderIds.length > 0
	) {
		displayPart.isFiltered =
			displayPart.isFiltered &&
			part.folders &&
			part.folders.some((folder) =>
				state.data.folders.selectedFolderIds.some(
					(id) => id === JSON.parse(folder).name
				)
			);
	}

	return displayPart as IDisplayPart;
};

export default getDisplayPart;
