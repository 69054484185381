import API, { graphqlOperation } from "@aws-amplify/api";
import { onResetConfigurationScreen } from "..";
import { createConfigurations } from "../../../../graphql/mutations";
import ACTION_TYPES from "../../../actionTypes";
import {
  IAddConfigurationModalStatus,
  IConfiguration,
  IState,
} from "../../../Interfaces";
import { NotificationManager } from "react-notifications";

const onAddConfigurationSuccess = (copiedConfiguration) => {
  return {
    type: ACTION_TYPES.CONFIGURATION.ADD.SUCCESS,
    payload: copiedConfiguration,
  };
};

const onAddConfigurationFail = (errorMessage: string) => {
  NotificationManager.error(errorMessage);
  console.error('Failure on adding configuration - ', errorMessage);
  return {
    type: ACTION_TYPES.CONFIGURATION.ADD.FAIL,
    payload: errorMessage,
  };
};

const onAddConfiguration = () => {
  return async (dispatch, getState) => {
    const state = getState() as IState;

    const addConfigurationModalStatus = state.data.modals
      .addConfigurationModalStatus as IAddConfigurationModalStatus;

    const isConfigurationNameEmpty = Boolean(
      !addConfigurationModalStatus.inputText
    ) as boolean;
    const isConfigurationNameExists = Boolean(
      state.data.configurations.loadedConfigurations.some(
        (p) => p.name === addConfigurationModalStatus.inputText
      )
    ) as boolean;
    const isConfigurationTypeSelected = Boolean(
      addConfigurationModalStatus.configurationType
    ) as boolean;
    const isConfigurationDataValid = Boolean(
      addConfigurationModalStatus.data
    ) as boolean;

    if (isConfigurationNameEmpty) {
      const errorMessage = "Configuration Name is mandatory." as string;
      dispatch(onAddConfigurationFail(errorMessage));
      return;
    }
    if (isConfigurationNameExists) {
      const errorMessage = "Configuration Name already exists." as string;
      dispatch(onAddConfigurationFail(errorMessage));
      return;
    }
    if (!isConfigurationTypeSelected) {
      const errorMessage = "Configuration Type is mandatory." as string;
      dispatch(onAddConfigurationFail(errorMessage));
      return;
    }

    if (!isConfigurationDataValid) {
      const errorMessage = "Configuration Data is mandatory." as string;
      dispatch(onAddConfigurationFail(errorMessage));
      return;
    }

    try {
      const id = addConfigurationModalStatus.inputText.replace(/\s/g, "");
      const dataJSON = {
        ...addConfigurationModalStatus.data,
        id: id,
      };

      const data = {
        id: id,
        name: id,
        type: addConfigurationModalStatus.configurationType,
        data: JSON.stringify(dataJSON),
        hasPlates: false,
        plates: [],
        dumb: 1,
        default: false,
        created_at: new Date().toUTCString(),
        creator: JSON.parse(JSON.stringify(dataJSON).replace(/\\/g, ""))
          .creator,

        modifier: JSON.parse(JSON.stringify(dataJSON).replace(/\\/g, ""))
          .creator,
      };

      const variables = {
        input: data,
      };
      const result = await API.graphql(
        graphqlOperation(createConfigurations, variables)
      );
      const addedConfiguration = (result as any)?.data
        ?.createConfigurations as IConfiguration;
      dispatch(onAddConfigurationSuccess(addedConfiguration));
      dispatch(onResetConfigurationScreen());
    } catch (graphqlError) {
      const errorMessage = `Add configuration failed: ${Object(
        (graphqlError as any)?.errors?.[0]?.message
      ).toString()}`;
      dispatch(onAddConfigurationFail(errorMessage));
    }
  };
};

export default onAddConfiguration;
