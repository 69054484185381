import API, { graphqlOperation } from "@aws-amplify/api";

import { deleteBuild, updatePlate } from "../../../../graphql/mutations";

import ACTION_TYPES from "../../../actionTypes";
import { IBuild, IPlate, IState } from "../../../Interfaces";
import { onNavigateToHomePage } from "../../Home";
import onResetSearch from "../../Search/reset"
import { onDisplayLoader } from "../../Search";
import { getPlate } from "../../../../graphql/queries";
import awsconfig from '../../../../aws-exports'; // Ensure this path is correct for your project
import { Amplify, Storage } from 'aws-amplify';

Amplify.configure(awsconfig);

const onDeletebuildDone = (deleteBuild) => {
  return {
    type: ACTION_TYPES.BUILD.DELETE.ACTION,
    payload: deleteBuild,
  };
};

const onUpdatePlateAfterDeleteBuild = (updatedPlate) => {
  return {
    type: ACTION_TYPES.BUILD.DELETE.UPDATE_PLATE,
    payload: updatedPlate,
  };
};

const onDeleteBuild = (buildId, history) => {
  return async (dispatch, getState) => {
    const state = getState() as IState;
    if (state.data.search.isSearchEnabled) {
      dispatch(onDisplayLoader(true))
    }


    const buildToBeRebuild = !state.data.search.isSearchEnabled ? (state.data.builds.loadedBuilds.find(
      (b) => b.id === buildId
    ) as IBuild) : state.data.search.highlightedBuildData as IBuild
    const plateToBeUpdated = JSON.parse(buildToBeRebuild?.plates[0]).id
    const currentBuildId = buildToBeRebuild.id;

    const deleteBuildVariables = {
      input: {
        id: buildToBeRebuild.id,
        _version: buildToBeRebuild._version,
      },
    };

    try {     // Delete the build
      const deleteResult = await API.graphql(
        graphqlOperation(deleteBuild, deleteBuildVariables)
      );
      const deletedBuildDetails = (deleteResult as any)?.data?.deleteBuild as IBuild;
      if (
        deletedBuildDetails &&
        deletedBuildDetails.parts[0] &&
        JSON.parse(deletedBuildDetails.parts[0]).Key === `BuildPartsFiles/${deletedBuildDetails.id}.json`
    ) {
        const path = `BuildPartsFiles/${deletedBuildDetails.id}.json`;
        const AWSBucketParam = {
            Bucket: Storage["_config"]["AWSS3"]["bucket"],
            Key: path,
            CacheControl: 'no-cache' // or 'max-age=0'
        };
        try {
            const getResult = await Storage.get(AWSBucketParam.Key, { download: true,  cacheControl: 'no-cache' });
            deletedBuildDetails.parts = JSON.parse(await (getResult.Body as any).text());
            try{
                const deletePartfromS3 = await Storage.remove(AWSBucketParam.Key, { level: 'public' });
                console.log(`File ${AWSBucketParam.Key} removed successfully from S3`);
                
            }catch(error:any){
                console.error("An error occurred during the build's s3 file deletion process:", error.message);
            }
        } catch (error: any) {
            console.error("An error occurred during the build's s3 file deletion process:", error.message);
        }
    }

      // Update the associated plate
      try {
        const getSelectedPlateResult = await API.graphql(
          graphqlOperation(getPlate, { id: plateToBeUpdated })
        );
        const plateObjToBeUpdated = (getSelectedPlateResult as any).data.getPlate;

        // Modify plate details
        plateObjToBeUpdated.modified_at = new Date().toISOString();
        plateObjToBeUpdated.builds = plateObjToBeUpdated?.builds
          .filter((build) => JSON.parse(build).name !== currentBuildId);
        delete plateObjToBeUpdated._deleted;
        delete plateObjToBeUpdated._lastChangedAt;
        delete plateObjToBeUpdated.createdAt;
        delete plateObjToBeUpdated.updatedAt;

        // Update the plate
        const updateVariables = { input: plateObjToBeUpdated };
        try {
          const updateResult = await API.graphql(
            graphqlOperation(updatePlate, updateVariables)
          );
          const updatedPlate = (updateResult as any)?.data?.updatePlate as IPlate;
          dispatch(onUpdatePlateAfterDeleteBuild(updatedPlate))
        } catch (updateEx: any) {
          console.log(`Exception ${updateEx.toString()} updating Plates`);
        }
      } catch (getPlateEx: any) {
        console.log(`Exception ${getPlateEx.toString()} getting Plates`);
      }

      // Dispatch actions after successful deletion and update
      dispatch(onDeletebuildDone(deletedBuildDetails));

      // Additional actions if search is enabled
      if (state.data.search.isSearchEnabled) {
        dispatch(onDisplayLoader(false));
        dispatch(onNavigateToHomePage(history));
        dispatch(onResetSearch());
      }

    } catch (ex: any) {
      // Handle exceptions during the overall process
      if (state.data.search.isSearchEnabled) {
        dispatch(onDisplayLoader(false));
        dispatch(onResetSearch());
      }
      console.error(`Exception ${ex.toString()} Delete build failed`);
    }
  };
};

export default onDeleteBuild;
