import API, { graphqlOperation } from "@aws-amplify/api";
import { stringify } from "querystring";
import { onResetConfigurationScreen } from "..";

import { updateConfigurations } from "../../../../graphql/mutations";

import ACTION_TYPES from "../../../actionTypes";
import { IConfiguration, IState } from "../../../Interfaces";
import { NotificationManager } from "react-notifications";

const onUpdateConfigurationInit = () => {
  return {
    type: ACTION_TYPES.CONFIGURATION.UPDATE.INIT,
    payload: null,
  };
};

const onUpdateConfigurationSuccess = (updatedConfiguration: IConfiguration) => {
  return {
    type: ACTION_TYPES.CONFIGURATION.UPDATE.SUCCESS,
    payload: updatedConfiguration,
  };
};

const onUpdateConfigurationFail = (errorMessage: string) => {
  //NotificationManager.error('Failure on updating configuration');
  console.error('Failure on updating configuration - ', errorMessage);
  return {
    type: ACTION_TYPES.CONFIGURATION.UPDATE.FAIL,
    payload: errorMessage,
  };
};

const onUpdateConfigurationDone = () => {
  return {
    type: ACTION_TYPES.CONFIGURATION.UPDATE.DONE,
    payload: null,
  };
};

const onUpdateConfiguration = (updatedData) => {
  return async (dispatch, getState) => {
    dispatch(onUpdateConfigurationInit());
    try {
      const state = getState() as IState;
      const configurationToBeUpdated =
        state.data.configurations.loadedConfigurations.find(
          (p) => p.id === state.data.configurations.highlightedConfigurationId
        ) as IConfiguration;

      let myValue: any = configurationToBeUpdated;
      if(updatedData.id){
        updatedData.id =configurationToBeUpdated["name"]
      }
      myValue.name = configurationToBeUpdated["name"];
      myValue.data = JSON.stringify(updatedData);
      myValue.creator = updatedData.creator;
      myValue.modifier = updatedData.modifier;

      delete myValue.default;
      delete myValue._deleted;
      delete myValue._lastChangedAt;
      delete myValue.createdAt;
      delete myValue.updatedAt;

      const variables = {
        input: myValue,
      };
      const result = await API.graphql(
        graphqlOperation(updateConfigurations, variables)
      );
      const updatedConfiguration = (result as any)?.data
        ?.updateConfigurations as IConfiguration;

      dispatch(onUpdateConfigurationSuccess(updatedConfiguration));
      dispatch(onResetConfigurationScreen());
    } catch (graphqlError) {
      const errorMessage = `Update configuration failed: ${Object(
        (graphqlError as any)?.errors?.[0]?.message
      ).toString()}`;
      dispatch(onUpdateConfigurationFail(errorMessage));
    }
    dispatch(onUpdateConfigurationDone());
  };
};

export default onUpdateConfiguration;
