import { API, graphqlOperation } from 'aws-amplify';
import { IState } from '../../../Interfaces';
import ACTION_TYPES from '../../../actionTypes';
import { getParts } from '../../../../graphql/queries';

const onSelectPartDone = (part) => {
    return {
        type: ACTION_TYPES.PART.SELECT.ACTION,
        payload: part,
    };
};

const onSelectPart = (partId) => {
    return async (dispatch, getState) => {
        try {
            const getSelectedPart = await API.graphql(
                graphqlOperation(getParts, {
                    id: partId,
                })
            );
            const getSelectedPartResult = (getSelectedPart as any).data.getParts;
            if (getSelectedPartResult) {
                dispatch(onSelectPartDone(getSelectedPartResult))
            }
        } catch (graphqlError) {
            const errorMessage = `Select part failed: ${Object((graphqlError as any)?.errors?.[0]?.message).toString()}`;
          
        }
    };
};

export default onSelectPart;