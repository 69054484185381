import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import IPartInfo from "../../../interfaces/IPartInfo";
import { onNavigateToPlateReviewPage, onNavigateToPlatesPage } from "../../Actions/Home";
import { IDisplayPlate, IPlate, IState } from "../../Interfaces";
import { onHighlightPlate, onSelectPlate } from '../../Actions/Plates';
import { onHomePageTabChange } from '../../Actions/Home';
import DisplayPlateOptions from "../Prepare/Plates/DisplayPlateOptions";
import moment from "moment";
import { API, graphqlOperation } from "aws-amplify";
import { NotificationManager } from "react-notifications";
import { getPlate } from "../../../graphql/queries";
import { TabIndex } from "../../Enums";
import onGetHighlightedPlate from "../../Actions/Search/highlightPlate";
import { Storage } from "aws-amplify";



const Props = (state: IState, ownProps: any) => {
	const isSearchEnabled = state.data.search.isSearchEnabled as boolean;
    const highlightPlate = state.data.search.highlightedPlateData as IPlate;
	const highlightedPlateId =state.data.plates.highlightedPlateId as string;
	const loadedPlates = state.data.plates.loadedPlates;
	const props = {
		isSearchEnabled: isSearchEnabled as boolean,
        highlightPlate: highlightPlate as IPlate,
		loadedPlates: loadedPlates as IPlate[],
		highlightedPlateId: highlightedPlateId as string
	};

	return props;
};

const Actions = {
	onHighlightPlate: onHighlightPlate,
	onNavigateToPlatesPage: onNavigateToPlatesPage,
	onHomePageTabChange: onHomePageTabChange,
	onNavigateToPlateReviewPage: onNavigateToPlateReviewPage,
	onSelectPlate: onSelectPlate,
	onGetHighlightedPlate: onGetHighlightedPlate,
};

class Component extends React.Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			selectedPlate: {}
		}

	}
	 editPlate=async(plateToBeEdited) => {
		if (
			plateToBeEdited &&
			plateToBeEdited.parts[0] &&
			JSON.parse(plateToBeEdited.parts[0]).Key === `PlatePartsFiles/${plateToBeEdited.id}.json`
		) {
			const path = `PlatePartsFiles/${plateToBeEdited.id}.json`;
			const AWSBucketParam = {
				Bucket: Storage["_config"]["AWSS3"]["bucket"],
				Key: path,
				CacheControl: 'no-cache' // or 'max-age=0'
			};
			try {
				const getResult = await Storage.get(AWSBucketParam.Key, { download: true,  cacheControl: 'no-cache' });
				plateToBeEdited.parts = JSON.parse(await (getResult.Body as any).text());
	
			} catch (error:any) {
				NotificationManager.error('An error occurred during the plate editing process');
			}
		}
	}
	handlePlateCardClickHandler = async (plateId) => {
		try {
			const result = await API.graphql(
				graphqlOperation(getPlate, {
					id: plateId,
				})
			);
			const getSelectedPlateResult = (result as any)?.data?.getPlate
			if (getSelectedPlateResult) {
				const plateToBeEdited = getSelectedPlateResult as IPlate;
				await this.editPlate(plateToBeEdited);
				const partIds = plateToBeEdited.parts.filter(Boolean).map(stringifiedPart => JSON.parse(stringifiedPart)).filter(Boolean).map(part => part.properties.PartID).filter(Boolean) as string[];
				const parts = plateToBeEdited.parts.filter(Boolean).map(stringifiedPart => JSON.parse(stringifiedPart)).filter(Boolean) as IPartInfo[];
				const partConfigurationIds = parts.map(part => part.properties.PartConfig?.originalJson?.id).filter(c => c);
				const partSlicerConfigurationIds = parts.map(part => part.properties.SlicerConfig?.originalJson?.id).filter(c => c);
				const plateConfigurationIds = [plateToBeEdited.machineId, plateToBeEdited.millConfigId, plateToBeEdited.materialConfigId, plateToBeEdited.recipeId];
				const ids = {
					partIds,
					parts,
					partConfigurationIds,
					partSlicerConfigurationIds,
					plateConfigurationIds,
				};
				this.props.onHighlightPlate(plateId);
				this.props.onSelectPlate(this.props.plateSearchResult.id)
				this.props.onNavigateToPlateReviewPage(ids, this.props.history, false);
				console.log("Selected Plate ID" + this.props.plateSearchResult.id);
			}
		} catch (graphqlError) {
			const errorMessage = `${Object(
				(graphqlError as any)?.errors?.[0]?.message
			).toString()}`;
			console.error(errorMessage);
			NotificationManager.error(
				`Not able to fetch selected Plate due to ${errorMessage} `
			);
		}
	}

	public render() {
		return (
			<>
				<div className={this.props.plateSearchResult.id === this.props.highlightedPlateId ? "is-selected-true display-card is-selected" : "normal-class display-card is-selected"}
					id={this.props.plateSearchResult.id}
					key={this.props.plateSearchResult.id}
					onClick={() =>  {
							this.handlePlateCardClickHandler(this.props.plateSearchResult.id)
							this.props.onGetHighlightedPlate(this.props.plateSearchResult.id)
						}
						}

				>
					<div>
					<div className="thumbnail-preview">
						<img
						src="/plate-thumbnail.svg"
						className="default-thumb-image"
						alt="PlateImage"
						/>
					</div>
					<div className='display-card-row align-items-center pt-0'>
						<div className='display-card-header'>
							<label title={this.props.plateSearchResult.id}>{this.props.plateSearchResult.id} </label>
						</div>
					</div>
					<div className='display-card-row'>
						<div className='display-card-sub-header'>
							<>
								<div className='details'>
									<label>Created Date:</label>
									<span>{(this.props.plateSearchResult.created_at ? moment(new Date(this.props.plateSearchResult.created_at)).format('MMMM Do YYYY, HH:mm:ss A') : 'Not Available')} </span>
								</div>
								<div className='details'>
									<label>Created By:</label>
									<span>{this.props.plateSearchResult.created_by}</span>
								</div>
							</>
						</div>
					</div>
				</div>
				</div>
			
			</>
		);
	}
}

const SearchReviewPlateCardView = withRouter(connect(Props, Actions)(Component));

export default SearchReviewPlateCardView;
