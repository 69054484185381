/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "influxPrinterApi",
            "endpoint": "https://v2ma1hbei6.execute-api.us-east-1.amazonaws.com/integrate",
            "region": "us-east-1"
        },
        {
            "name": "lavaNotificationApi",
            "endpoint": "https://1c14m7iik1.execute-api.us-east-1.amazonaws.com/integrate",
            "region": "us-east-1"
        },
        {
            "name": "lavaPrinterControllerApi",
            "endpoint": "https://hu4our5rv8.execute-api.us-east-1.amazonaws.com/integrate",
            "region": "us-east-1"
        },
        {
            "name": "lavaRestApi",
            "endpoint": "https://5btzwa1kn6.execute-api.us-east-1.amazonaws.com/integrate",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://osthafes4ffshcez643fj6dqfm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-oh4v3vdqgvh5bohzjnzm53x2pu",
    "aws_cognito_identity_pool_id": "us-east-1:e7e07e48-2062-455c-a566-4e5f86160fcf",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_r8D7SVFkl",
    "aws_user_pools_web_client_id": "358ca15eqq02t4267tuu5gg3q2",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "vizapp-s3204040-integrate",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
