import API, { graphqlOperation } from "@aws-amplify/api";
//import { onResetConfigurationScreen } from "..";

import { configurationByEventNameType } from "../../../../graphql/queries";

import ACTION_TYPES from "../../../actionTypes";
//import { IConfiguration, IState } from "../../../Interfaces";

const onFetchDeletedConfigs = (deletedConfigurations) => {
  return {
    type: ACTION_TYPES.CONFIGURATION.DELETEDCONFIGS.SUCCESS,
    payload: deletedConfigurations,
  };
};


const onFetchDeletedConfigurations = () => {
  return async (dispatch, getState) => {
    try {
         const variables: any = {
              eventName: "REMOVE",
              sortDirection: "DESC", 
              limit: 50
        };
      const result = await API.graphql(
        graphqlOperation(configurationByEventNameType, variables)
      );
      const deletedConfigurations = (result as any)?.data?.ConfigurationByEventNameType?.items
      dispatch(onFetchDeletedConfigs(deletedConfigurations))
     
    } catch (graphqlError) {
      console.error(`Fetching Deleted Configurations Failed: ${Object(
        (graphqlError as any)?.errors?.[0]?.message
      ).toString()}`)
     
    }
    
  };
};

export default onFetchDeletedConfigurations;

