import API, { graphqlOperation } from '@aws-amplify/api';

import { updateParts } from '../../../../graphql/mutations';

import ACTION_TYPES from '../../../actionTypes';
import { IPart, IMovePartModalStatus, IState } from '../../../Interfaces';
import { NotificationManager } from "react-notifications";
const onMovePartInit = () => {
    return {
        type: ACTION_TYPES.PART.MOVE.INIT,
        payload: null,
    };
};

const onMovePartSuccess = (movedPart) => {
    return {
        type: ACTION_TYPES.PART.MOVE.SUCCESS,
        payload: movedPart,
    };
};

const onMovePartFail = (errorMessage: string) => {
    NotificationManager.error('Failure on Move Part');
    console.error('Failure on Move Part - ', errorMessage);
    return {
        type: ACTION_TYPES.PART.MOVE.FAIL,
        payload: errorMessage,
    };
};

const onMovePartDone = () => {
    return {
        type: ACTION_TYPES.PART.MOVE.DONE,
        payload: null,
    };
};

const onMovePart = () => {
    return async (dispatch, getState) => {

        dispatch(onMovePartInit());

        const state = getState() as IState;

        const movePartModalStatus = state.data.modals.movePartModalStatus as IMovePartModalStatus;

        const isFolderNameEmpty = (!movePartModalStatus.inputText) as boolean;

        try {
            const state = getState() as IState;   
            const partToBeMoved = !state.data.search.isSearchEnabled ? (state.data.parts.loadedParts.find(
                (p) => p.id === state.data.parts.highlightedPartId)
              ) as IPart : state.data.search.highlightedPartData as IPart

            const folderName = JSON.stringify({ name: movePartModalStatus.inputText }) as string;
            partToBeMoved.folders = isFolderNameEmpty ? [] : [folderName] as string[];
            delete partToBeMoved._deleted;
            delete partToBeMoved._lastChangedAt;
            delete partToBeMoved.createdAt;
            delete partToBeMoved.updatedAt;
            const variables = {
                input: partToBeMoved,
            };
            const result = await API.graphql(graphqlOperation(updateParts, variables));
            const movedPart = ((result as any)?.data?.updateParts) as IPart;
            dispatch(onMovePartSuccess(movedPart));
            dispatch(onMovePartDone());
        } catch (graphqlError) {
            const errorMessage = `Move part failed: ${Object((graphqlError as any)?.errors?.[0]?.message).toString()}`;
            dispatch(onMovePartFail(errorMessage));
            dispatch(onMovePartDone());
        }
    };
};

export default onMovePart;