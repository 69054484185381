import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import IPartInfo from "../../../../interfaces/IPartInfo";
import { onNavigateToPlatesPage } from "../../../Actions/Home";
import { IDisplayPlate, IPlate, IState } from "../../../Interfaces";
import { onHighlightPlate } from '../../../Actions/Plates';
import { onHomePageTabChange } from '../../../Actions/Home';
import DisplayPlateOptions from "./DisplayPlateOptions";
import { onSelectPlate, onUnselectPlate } from '../../../Actions/Plates';
import { TabIndex } from '../../../Enums';
import moment from "moment";
import { Storage } from "aws-amplify";
import { NotificationManager } from "react-notifications";

const Props = (state: IState, ownProps: any) => {
	const props = {
		loadedPlates: state.data.plates.loadedPlates as IPlate[],
		displayPlate: ownProps.displayPlate as IDisplayPlate,
		creator:state.creator,
		selectedPlateIds: state.data.plates.selectedPlateIds,
        enableBatchDelete: state.enableBatchDelete
	};

	return props;
};

const Actions = {
	onHighlightPlate: onHighlightPlate,
	onNavigateToPlatesPage: onNavigateToPlatesPage,
	onHomePageTabChange: onHomePageTabChange,
	onSelectPlate: onSelectPlate,
    onUnselectPlate: onUnselectPlate,
};

class Component extends React.Component<any, any> {
	parts = (Boolean(this.props.displayPlate.parts)) as unknown as string[];
	constructor(props) {
		super(props);
		this.state = {
			isPlateSelected: false,
		};
	}

	componentWillReceiveProps() {
		if(this.props.selectedPlateIds.some((item) => item === this.props.displayPlate.id)) {
			this.setState({ isPlateSelected: true });
		}
		
        if(!this.props.enableBatchDelete && this.state.isPlateSelected && !this.props.selectedPlateIds.length) {
            this.setState({ isPlateSelected: false });
        }
        if(this.props.selectedPlateIds.length === 0 && this.props.enableBatchDelete && this.state.isPlateSelected) {
            this.setState({ isPlateSelected: false });
        }
	}

	editPlate=async(plateToBeEdited) => {
		if (
			plateToBeEdited &&
			plateToBeEdited.parts[0] &&
			JSON.parse(plateToBeEdited.parts[0]).Key === `PlatePartsFiles/${plateToBeEdited.id}.json`
		) {
			const path = `PlatePartsFiles/${plateToBeEdited.id}.json`;
			const AWSBucketParam = {
				Bucket: Storage["_config"]["AWSS3"]["bucket"],
				Key: path,
				CacheControl: 'no-cache' // or 'max-age=0'
			};
			try {
				const getResult = await Storage.get(AWSBucketParam.Key, { download: true,  cacheControl: 'no-cache' });
				plateToBeEdited.parts = JSON.parse(await (getResult.Body as any).text());
			} catch (error: any) {
				NotificationManager.error('An error occurred during the plate editing process');
			}
		}
	}
	
	onNavigateToPlatesPage = async (plateId) => {
		this.props.onHighlightPlate(plateId);
		const plateToBeEdited = this.props.loadedPlates.find(lp => lp.id === plateId) as IPlate;	
		await this.editPlate(plateToBeEdited);
		const partIds = plateToBeEdited.parts?.filter(Boolean).map(stringifiedPart => JSON.parse(stringifiedPart)).filter(Boolean).map(part => part?.properties.PartID).filter(Boolean) as string[];
		const parts = plateToBeEdited.parts.filter(Boolean).map(stringifiedPart => JSON.parse(stringifiedPart)).filter(Boolean) as IPartInfo[];
		const partConfigurationIds = parts.map(part => part.properties.PartConfig?.originalJson?.id).filter(c => c);
		const partSlicerConfigurationIds = parts.map(part => part.properties.SlicerConfig?.originalJson?.id).filter(c => c);
		const plateConfigurationIds = [plateToBeEdited.machineId, plateToBeEdited.millConfigId, plateToBeEdited.materialConfigId, plateToBeEdited.recipeId];

		const ids = {
			partIds,
			parts,
			partConfigurationIds,
			partSlicerConfigurationIds,
			plateConfigurationIds,
		};

		this.props.onNavigateToPlatesPage(ids, this.props.history, false);
	}

	onToggleSelectPlate = (id) => {
		if (this.props.displayPlate?.created_by !== null || this.props.displayPlate?.created_by !== undefined || this.props.displayPlate?.created_by !== "") {
			if (this.props.displayPlate?.created_by !== this.props.creator) {
				this.setState({ isAlertVisible: true })
				NotificationManager.error('You lack the authorization to delete plate created by other users.');
			}
			else {
				this.setState({ isPlateSelected: !this.state.isPlateSelected })
				!this.state.isPlateSelected
					? this.props.onSelectPlate(id)
					: this.props.onUnselectPlate(id);
			}
		}
	}

	public render() {
		return (
			<>
				<div
					className={`display-card is-selected-${this.props.displayPlate.isSelected} position-relative p-0`}
					id={`plate-${this.props.displayPlate.index}`}
					key={`${this.props.displayPlate.index}`}
					onClick={(e) => { this.props.onHomePageTabChange(TabIndex.PARTS); this.onNavigateToPlatesPage(this.props.displayPlate.id) }}
				>
				      
					{this.props.enableBatchDelete && <div className='display-card-select-action pt-2 pl-2 position-absolute'>
						<div className='options right-justify'>
							<>
								<div className='check option'>
									<img
										className={`icon ${this.state.isPlateSelected ? 'check' :'uncheck'}`}
										alt=''
										style={{ margin: '0px', padding: '4px' }}
										onClick={(e) => { e.stopPropagation(); this.onToggleSelectPlate(this.props.displayPlate.id) }}
									/>
								</div>
							</>
						</div>
					</div>}


					<div className='img-col plate-thumb'>
						{
							this.props.displayPlate.icon ?
								<img src={this.props.displayPlate.icon} alt=""  className='thumbnail-img' />
								: <img src="/plate-thumbnail.svg"alt="" className='thumbnail-img' />
						}
					</div>
					<div className='display-card-row align-items-center pt-0'>
						<div className='display-card-header'>
							<label title={this.props.displayPlate.name}>{this.props.displayPlate.name} </label>
						</div>
						<div className="display-card-more-action"
							onClick={(e) => e.stopPropagation()}>
							<DisplayPlateOptions
								{...{ displayPlate: this.props.displayPlate }}
							/>
						</div>
					</div>
					<div className='display-card-row'>
						<div className='display-card-sub-header'>
							<>
								<div className='details'>
									<label>Created Date:</label>
									<span className="data-format" title={this.props.displayPlate.created_at || this.props.displayPlate?.createdDate ? moment(new Date(this.props.displayPlate.created_at || this.props.displayPlate?.createdDate)).format('MMMM Do YYYY, HH:mm:ss A') : 'Not Available'}>{this.props.displayPlate.created_at || this.props.displayPlate?.createdDate ? moment(new Date(this.props.displayPlate.created_at || this.props.displayPlate?.createdDate)).format('MMMM Do YYYY, HH:mm:ss A') : 'Not Available'}</span>
								</div>
								<div className='details'>
									<label>Parts Plated</label>
									<span>{this.props.displayPlate.noOfParts}</span>
								</div>

								{/* <div className='details'>
									<label>File Size:</label>
									<span>34MB</span>
								</div>
								<div className='details'>
									<label>Dimensions:</label>
									<span>50mm x 50mm x 16mm</span>
								</div> */}
							</>
						</div>
					</div>
					{/* <div className="display-card-row">
						<div className="display-card-header">
							<label className="text-ellipsis-second-line">
								{this.props.displayPlate.name}
							</label>
						</div>
						<div className="display-card-more-action"
							onClick={(e) => e.stopPropagation()}>
							<DisplayPlateOptions
								{...{ displayPlate: this.props.displayPlate }}
							/>
						</div>
					</div>
					<div className="display-card-row">
						<div className="display-card-sub-header">
							<label>{this.props.displayPlate.createdDate}</label> 
							<label>{this.props.displayPlate.createdDate ? this.props.displayPlate.createdDate : this.createdDate}</label>
						</div>
					</div> */}
				</div>
			</>
		);
	}
}

const DisplayPlateCardView = withRouter(connect(Props, Actions)(Component));

export default DisplayPlateCardView;
