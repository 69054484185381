import { IPart, IState } from '../../../Interfaces';

const onRenamePartOpenDialogReducer = (state: IState, action) => {
    const partIdToBeRenamed = action.payload as string;
    const partTobeRenamed = !state.data.search.isSearchEnabled ? (state.data.parts.loadedParts.find(
        (p) => p.id === state.data.parts.highlightedPartId)
      ) as IPart : state.data.search.highlightedPartData as IPart
      
    const partNameToBeRenamed = partTobeRenamed.name as string;

    const newState = { ...state } as IState;
    newState.data.modals.renamePartModalStatus = {
        ...newState.data.modals.renamePartModalStatus,
        inputText: partNameToBeRenamed,
        isOpen: true,
        isValid: false,
        hasError: false,
        errorMessage: '',
    };
    return newState;
};

export default onRenamePartOpenDialogReducer;