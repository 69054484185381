
import { IBuild, IPlate, IState } from "../../Interfaces";

const onGetHighlightedPlateReducer = (state: IState, action) => {
    const highlightedPlateData = action.payload as IPlate;
    const newState = { ...state } as IState;
    const isPlateAvialble = state.data.plates.loadedPlates.some(
        (lp) => lp.id === highlightedPlateData.id
      );
      if(!isPlateAvialble){
        newState.data.plates.loadedPlates = [highlightedPlateData, ...newState.data.plates.loadedPlates]
      }
    newState.data.search = {
        ...newState.data.search,
        highlightedPlateData: highlightedPlateData,
    };

    return newState;
};

export default onGetHighlightedPlateReducer;