import React, { useEffect, useState, useRef } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { Button } from "react-bootstrap";
import CreateUser from "../Components/UserManagement/CreateUser";
import UserList from "../Components/UserManagement/UserList";
import API, { graphqlOperation } from "@aws-amplify/api";
import { getRole, listRoles } from "../../graphql/queries";
import CreateRole from "../Components/UserManagement/CreateRole";
import RoleList from "../Components/UserManagement/RoleList";
import { Auth } from "aws-amplify";

const UserManagement = (props) => {
  const [active, setActive] = useState(0);
  const [roles, setRoles] = useState("");
  const [enableCreateUser, setEnableCreateUser] = useState(false);
  const [enableCreateRole, setEnableCreateRole] = useState(false);
  const [editUserData, setEditUserData] = useState<null>();
  const [editRoleData, setEditRoleData] = useState<null>();
  const isMountedRef = useRef(true);
  const [roleOptions, setRoleOptions] = useState<any>();
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [permissionGridRows, setPermissionGridRows] = useState([
    {
      id: 1,
      permission: true,
      feature: "Preparation",
    },
    {
      id: 2,
      permission: false,
      feature: "Analytics",
    },
    {
      id: 3,
      permission: true,
      feature: "Configure",
    },
    {
      id: 4,
      permission: true,
      feature: "Help",
    },
    {
      id: 5,
      permission: false,
      feature: "Admin",
    },
  ]);

  const [permissionsByRole, setPermissionsByRole] = useState<{
    roleChange: boolean;
    userManagement: boolean;
    advancedFeatures: boolean;
  }>({
    roleChange: false,
    userManagement: true,
    advancedFeatures: false
  });

  useEffect(() => {
    isMountedRef.current = true;
    getPermissionsByRole();

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const getPermissionsByRole = async () => {
    await Auth.currentAuthenticatedUser().then(async (user) => {
      const group =
        user.signInUserSession.accessToken?.payload["cognito:groups"] &&
        user.signInUserSession.accessToken?.payload["cognito:groups"][0];
      setIsSuperAdmin(group === "SuperAdmin");
      if (group !== "superAdmin" || group !== "SuperAdmin") {
        const response = (await API.graphql(
          graphqlOperation(getRole, { id: group })
        )) as any;
        const { data } = response;
        if (response && data) {
          // setPermissionsByRole(data?.getRole?.permissions || null);
          // setActive(data?.getRole?.permissions?.userManagement ? 0 : 1);
          // setEnableCreateRole(data?.getRole?.permissions?.roleChange || false);
        }
      }
      // getRoleLists(group);
    });
  };

  const getRoleLists = async (userRole) => {
    try {
      const response = (await API.graphql(graphqlOperation(listRoles))) as any;
      if (isMountedRef.current && response?.data && response.data) {
        const roleItems: any = [];
        if (response.data?.listRoles?.items.length) {
          response.data?.listRoles?.items.forEach((roleItem) => {
            // if (userRole === "SuperAdmin"  && roleItem.roleName === "SuperAdmin") {
            //   roleItems.push(roleItem);
            // }
            // else if (userRole !== "SuperAdmin" && roleItem.roleName === "SuperAdmin") {
            //   return;
            // }
            // else {
            //   roleItems.push(roleItem);
            // }
            if (roleItem.roleName !== "SuperAdmin") {
              roleItems.push(roleItem);
            }
          });
        }
        setRoleOptions(roleItems || []);
      }
    } catch (error) {
      console.error("Error");
    }
  };

  const permissionGridColumns = [
    {
      field: "feature",
      headerName: "Feature",
      sortable: false,
      width: 500,
    },
    {
      field: "permission",
      headerName: "Permission",
      sortable: false,
      clickable: false,
      width: 300,
      renderCell: (params) => (
        <div className="grid-switch">
          <span>No</span>
          <label className="switch">
            <input
              type="checkbox"
              checked={params.row.permission}
              onChange={() => gridPermissionChange(params.row)}
            />
            <span className="slider"></span>
          </label>
          <span>Yes</span>
        </div>
      ),
    },
  ];

  const gridPermissionChange = (data) => {
    const updatedPermission = permissionGridRows;
    updatedPermission.map((dataItem) => {
      if (dataItem.id === data.id) {
        dataItem.permission = !dataItem.permission;
      }
      return dataItem;
    });
    setPermissionGridRows((prevState) => {
      const updatedPermission = [...prevState];
      return updatedPermission;
    });
  };

  const handleTabChange = (tabNumber) => {
    setActive(tabNumber);
  };

  const handleChange = (event) => {
    setRoles(event.target.value);
  };

  const handleCreateUserCancel = () => {
    setEnableCreateUser(false);
    setEditUserData(null);
  };

  const handleCreateRoleCancel = () => {
    setEnableCreateRole(false);
  };

  const handleEnableCreateUser = () => {
    setEnableCreateUser(true);
  };

  const getEditUserData = (data) => {
    setEditUserData(data);
  };

  const handleSearchInput = (e) => {
    setSearchInput(e.target.value);
  };

  const onSearchSubmit = () => {};

  return (
    <section className="page user-management">
      <header>
        {/* Manage user & permission sections */}
        {/* {!enableCreateUser && ( */}
        <div className="tab-bar">
          <div className="options left-justify">
            {/* User Management tab button */}
            {permissionsByRole?.userManagement && (
              <div
                className={active === 0 ? "tab active" : "tab"}
                onClick={() => {
                  handleTabChange(0);
                  setEnableCreateRole(false);
                  setEnableCreateUser(false);
                }}
              >
                <label>User management</label>
              </div>
            )}
            {/* User role tab button */}
            {isSuperAdmin && (
              <div
                className={active === 1 ? "tab active" : "tab"}
                onClick={() => {
                  handleTabChange(1);
                  setEnableCreateRole(false);
                  setEnableCreateUser(false);
                }}
              >
                {/* <label>Manage permissions</label> */}
                <label>User Roles</label>
              </div>
            )}
          </div>
          {active === 0 &&
            !enableCreateUser &&
            permissionsByRole?.userManagement && (
              <div
                className="create-user-btn"
                onClick={() => {
                  setEnableCreateUser(true);
                  setEnableCreateRole(false);
                }}
              >
                <Button className="active">
                  <span className="pr-2"> + </span> Create User
                </Button>
              </div>
            )}
          {active === 1 && !enableCreateRole && (
            <div
              className="create-user-btn"
              onClick={() => {
                setEnableCreateRole(true);
                setEnableCreateUser(false);
              }}
            >
              <Button className="active">
                <span className="pr-2"> + </span> Create Role
              </Button>
            </div>
          )}
        </div>

        {/* Create user header */}
        {/* {enableCreateUser && (
          <div className="tab-bar create-user-header">
            <div className="header-section">
              <div
                className="backIcon"
                onClick={() => {
                  setEnableCreateUser(false);
                  setEditUserData(null);
                }}
              ></div>
              <label className="heading">
                {editUserData ? "Update User" : "Create User"}
              </label>
            </div>
          </div>
        )}

        {enableCreateRole && (
          <div className="tab-bar create-user-header">
            <div className="header-section">
              <div
                className="backIcon"
                onClick={() => {
                  setEnableCreateUser(false);
                  setEditUserData(null);
                  setEnableCreateRole(false);
                }}
              ></div>
              <label className="heading">
                {"Create User Role"}
              </label>
            </div>
          </div>
        )} */}
      </header>

      {/* USER MANAGEMENT BODY  */}

      {!enableCreateUser && (
        <div className="manage-user">
          {active === 0 && permissionsByRole?.userManagement && (
            <div>
              <div className="search-input">
                <form
                  onSubmit={() => {
                    onSearchSubmit();
                  }}
                  className="d-flex w-100 user-search-form"
                >
                  <input
                    type="text"
                    autoComplete="off"
                    name="focus"
                    required
                    id="advanced-search-box"
                    className="w-100 advanced-search-box"
                    placeholder="Search by User name/ Email ID"
                    onChange={handleSearchInput}
                    value={searchInput}
                  />
                  <span className="close-icon"></span>
                </form>
              </div>
              {/* User Data grid Component */}
              <UserList
                enableCreateUser={handleEnableCreateUser}
                editUserData={getEditUserData}
              />
            </div>
          )}
          {active === 1 && !enableCreateRole && isSuperAdmin && (
            // <div className="manage-permission">
            <div className="">
              {/* Role List grid Component */}
              <RoleList
                enableCreateRole={() => {
                  setEnableCreateRole(true);
                }}
                editRoleData={(data) => {
                  setEditRoleData(data);
                }}
              />
            </div>
          )}
          {/* {active === 1 && !isSuperAdmin && (
            <div className="manage-permission">
              <CreateRole />
            </div>
          )} */}
        </div>
      )}

      {enableCreateUser && (
        <div className="create-user">
          <CreateUser
            handleCancel={() => {
              handleCreateUserCancel();
            }}
            editUserData={editUserData}
            // rolesList={roleOptions}
          />
        </div>
      )}

      {enableCreateRole && (
        <div className="create-role">
          <CreateRole
            handleCancel={() => {
              handleCreateRoleCancel();
            }}
            editRoleData={editRoleData}
          />
        </div>
      )}
    </section>
  );
};

export default UserManagement;

// <div className="roles-dropdown-section">
//   <label className="roles-head">Roles</label>
//   <div className="roles-dropdown">
//     <button
//       className="btn btn-secondary roles-list-dropdown select-box required-error"
//       type="button"
//       data-toggle="dropdown"
//       aria-haspopup="true"
//       aria-expanded="false"
//     >
//       {roles ? roles : "Select Role"}
//       <span
//         className="btn btn-secondary dropdown-toggle dropdown-toggle-split"
//         aria-haspopup="true"
//         aria-expanded="false"
//       ></span>
//     </button>

//     <div className="dropdown-menu">
//       {roleOptions.length ? (
//         roleOptions.map((roleItem, idx) => (
//           <div
//             key={idx}
//             id={roleItem.id}
//             className="dropdown-item"
//             onClick={() => {
//               setRoles(roleItem.id);
//             }}
//           >
//             <label>{roleItem.roleName}</label>
//           </div>
//         ))
//       ) : (
//         <div
//           style={{
//             height: "150px",
//             display: "flex",
//             justifyContent: "center",
//           }}
//           className="dropdown-item"
//         >
//           <img width="50px" src="/img/loader.svg" alt="loading" />
//         </div>
//       )}
//     </div>
//     {/* <select value={roles} onChange={handleChange}>
//       <option value={""}>--Select Role--</option>
//       {roleOptions.map((item) => {
//         return (
//           <option value={item.id}>{item.roleName}</option>
//         )
//       })}
//     </select> */}
//     </div>
//     </div>
//     {/* Permission grid */}
//     <div className="permission-grid">
//       <DataGrid
//         rows={permissionGridRows}
//         columns={permissionGridColumns}
//         autoHeight
//         autoPageSize
//         disableSelectionOnClick
//         disableColumnMenu
//         className="list-view-grid"
//         hideFooterPagination
//       />
//       {/* <DataGrid
//         rows={[
//           {
//             id: 1,
//             permission: "true",
//             feature: "Upload part",
//           },
//         ]}
//         columns={permissionGridColumns}
//         autoHeight
//         autoPageSize
//         disableSelectionOnClick
//         disableColumnMenu
//         className="list-view-grid"
//         hideFooterPagination
//       /> */}
//     </div>
//     <div className="permission-actions">
//       <Button
//         className="btn-secondary btn-cancel"
//         onClick={() => {
//           setActive(0);
//         }}
//       >
//         Cancel
//       </Button>
//       <Button
//         className="btn-primary btn-save"
//         onClick={() => {
//           setActive(0);
//         }}
//       >
//         Save
//       </Button>
//     </div>
