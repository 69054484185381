import React, { Component } from "react";
import { Storage } from "aws-amplify";
import { NotificationManager } from 'react-notifications'


export class MVPBadBuildReview extends React.Component<any, {
  bundle: string,
  log: string
}>{
  constructor(props: any) {
    super(props);
    this.loadBuild(props.build.bundle).catch(reason => { NotificationManager.info(reason) })
  }

  async loadBuild(bundle) {
    if (bundle) {
      try {
        const logPath = `${bundle.replace('public/', '')}Log/log.txt`;
        const result = (await Storage.get(logPath, { download: true,  cacheControl: 'no-cache' }));
        const text = await (result.Body as any).text()
        this.setState({ bundle: bundle, log: text.toString() || "" })
      }
      catch (e : any) {
        NotificationManager.error(e.toString());
      }
    }
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
    if (this.state?.bundle !== this.props.build?.bundle) {
      this.loadBuild(this.props.build.bundle).catch(reason => { NotificationManager.info(reason) })
    }
  }

  render() {
    return (
      <>
        <div style={{ whiteSpace: "pre-line", overflowX: 'auto', overflowY: 'auto' }}>
          {this.state?.log || undefined}
        </div>
      </>
    )
  }
}
