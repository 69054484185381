import IPartInfo from "../interfaces/IPartInfo";
import { IScene } from "../Scene";
import { IMatrix } from "../interfaces/IMatrix";
import * as THREE from "three";

interface IPartMeshPairing {
	part: IPartInfo;
	mesh?: THREE.Mesh
}

export interface IWorkspaceMeshManager {
	getListOfMeshes(): THREE.Mesh[];
	findPartForMesh(mesh: THREE.Mesh): IPartInfo;
	selectedMatrix: THREE.Group | null;
	updateParts(arg0: IPartInfo[], arg1: any): void;
	updateSelectedMatrix(arg0: IMatrix | null): void;
	snapObjectToFloor(arg0: THREE.Object3D): void;
	addListener(arg0: any): void;
	findAndHighlightPositionErrors(mesh: THREE.Mesh): void;
	setBuildAreaVolume(volume: THREE.Box3): void;
	setPurgeArea(radius?: number, offset?: THREE.Vector3): void;
	setMaterialScaling(coeffXY: number, coeffZ: number): void;
	getBuildAreaVolume(): THREE.Box3;
	updateScreen(arg0: boolean): void;
}

// This dummy one is for build review.  Why?

class WorkspaceMeshManager implements IWorkspaceMeshManager {
	partMeshPairs: IPartMeshPairing[] = [];
	downloadedPart: Set<IPartInfo> = new Set();
	selectedMatrix: THREE.Group | null = null;
	scene: IScene;
	dispatch: any;
	listeners: any[];
	buildAreaVolume: THREE.Box3;
	materialScale: THREE.Vector3;

	grid?: THREE.GridHelper;
	buildVolumeVizualization?: THREE.Object3D;
	purgeArea?: THREE.Mesh;

	constructor(scene: IScene, dispatch, buildVolume: THREE.Box3) {
		this.updateParts = this.updateParts.bind(this);
		this.updateScreen = this.updateScreen.bind(this);

		this.setBuildAreaVolume = this.setBuildAreaVolume.bind(this);
		this.getListOfMeshes = this.getListOfMeshes.bind(this);
		this.findPartForMesh = this.findPartForMesh.bind(this);
		this.setMaterialScaling = this.setMaterialScaling.bind(this);
		this.getBuildAreaVolume = this.getBuildAreaVolume.bind(this);

		this.scene = scene;
		this.dispatch = dispatch;
		this.listeners = [];
		this.materialScale = new THREE.Vector3(1, 1, 1);

		this.buildAreaVolume = buildVolume;
	}

	updateScreen(screenChanged: boolean) {
	}

	updateParts(parts: IPartInfo[], screenName = '') {
	}

	getBuildAreaVolume(): THREE.Box3 {
		return this.buildAreaVolume
	}

	findPartForMesh(mesh: THREE.Mesh): IPartInfo {
		const pair = this.partMeshPairs.find(search_pair => search_pair.mesh === mesh)!;
		return pair.part;
	}

	getListOfMeshes(): THREE.Mesh[] {
		return this.partMeshPairs
			.map((pair: IPartMeshPairing) => pair.mesh)
			.filter((result: THREE.Mesh | undefined) => result) as THREE.Mesh[];
	}

	updateSelectedMatrix(matrix: IMatrix | null) {
	}

	setBuildAreaVolume(volume: THREE.Box3) {
	}

	setMaterialScaling(coeffXY, coeffZ) {
	}

	setPurgeArea(radius?: number, offset?: THREE.Vector3) {
	}

	findAndHighlightPositionErrors(mesh: THREE.Mesh) {
	}

	syncPartAndMesh(part: IPartInfo) {
	}

	addListener(component: any) {
		this.listeners.push(component);
	}

	snapObjectToFloor(snapedMesh: THREE.Object3D) {
	}
}

export default WorkspaceMeshManager;
