import ACTION_TYPES from '../../../actionTypes';

const onRestoreHighlightConfiguration = (configurationId) => {

    return {
        type: ACTION_TYPES.CONFIGURATION.RESTORE.HIGHLIGHTEDCONFIGURATIONID,
        payload: configurationId,
    };
};

export default onRestoreHighlightConfiguration;