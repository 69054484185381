import { IConfiguration, IState } from '../../../Interfaces';

const onRenameConfigurationOpenDialogReducer = (state: IState, action) => {
    const configurationTobeRenamed = state.data.configurations.loadedConfigurations.find(p => p.id === state.data.configurations.highlightedConfigurationId) as IConfiguration;
    const configurationNameToBeRenamed = state.data.configurations.isRestoreInitiated ? state.data.configurations.restoreHighlightedConfigurationId :configurationTobeRenamed.name as string;

    const newState = { ...state } as IState;
    newState.data.modals.renameConfigurationModalStatus = {
        ...newState.data.modals.renameConfigurationModalStatus,
        inputText: configurationNameToBeRenamed,
        isOpen: true,
        isValid: false,
        hasError: false,
        errorMessage: '',
    };
    return newState;
};

export default onRenameConfigurationOpenDialogReducer;