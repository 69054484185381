import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import IPartInfo from "../../../../interfaces/IPartInfo";
import {
	onNavigateToPlatesPage,
	onNavigateToPlateReviewPage,
	onNavigateToReviewPage,
} from "../../../Actions/Home";
import { IDisplayPlate, IPlate, IState } from "../../../Interfaces";
import {
	onHighlightPlate,
	onSelectPlate,
	onUnselectPlate,
} from "../../../Actions/Plates";
import {
	onClearAddedParts,
} from "../../../Actions/Parts";
import moment from "moment";
import { Storage } from "aws-amplify";
import { NotificationManager } from "react-notifications";

const Props = (state: IState, ownProps: any) => {
	const props = {
		loadedPlates: state.data.plates.loadedPlates as IPlate[],
		displayPlate: ownProps.displayPlate as IDisplayPlate,
		creator:state.creator
	};

	return props;
};

const Actions = {
	onHighlightPlate: onHighlightPlate,
	onNavigateToPlatesPage: onNavigateToPlatesPage,
	onNavigateToPlateReviewPage: onNavigateToPlateReviewPage,
	onClearAddedParts: onClearAddedParts,
	onNavigateToReviewPage: onNavigateToReviewPage,
	onSelectPlate: onSelectPlate,
	onUnselectPlate: onUnselectPlate,
};

const DisplayReviewPlateCardViewMethod = (props) => {
	const[isPlateSelected,setIsPlateSelected] = useState(false)

	const editPlate = async (plateToBeEdited) => {
		if (
			plateToBeEdited &&
			plateToBeEdited.parts[0] &&
			JSON.parse(plateToBeEdited.parts[0]).Key ===
			`PlatePartsFiles/${plateToBeEdited.id}.json`
		) {
			const path = `PlatePartsFiles/${plateToBeEdited.id}.json`;
			const AWSBucketParam = {
				Bucket: Storage["_config"]["AWSS3"]["bucket"],
				Key: path,
				CacheControl: 'no-cache' // or 'max-age=0'
			};
			try {
				const getResult = await Storage.get(AWSBucketParam.Key, { download: true,  cacheControl: 'no-cache' });
				plateToBeEdited.parts = JSON.parse(await (getResult.Body as any).text());
	
			} catch (error:any) {
				NotificationManager.error('An error occurred during the plate editing process');
			}
		}
	};

	const onNavigateToPlateReviewPage = async (plateId) => {
		props.onHighlightPlate(plateId);

		const plateToBeEdited = props.loadedPlates.find(
			(lp) => lp.id === plateId
		) as IPlate;
		await editPlate(plateToBeEdited);
		const partIds = plateToBeEdited.parts
			.filter(Boolean)
			.map((stringifiedPart) => JSON.parse(stringifiedPart))
			.filter(Boolean)
			.map((part) => part.properties.PartID)
			.filter(Boolean) as string[];
		const parts = plateToBeEdited.parts
			.filter(Boolean)
			.map((stringifiedPart) => JSON.parse(stringifiedPart))
			.filter(Boolean) as IPartInfo[];
		const partConfigurationIds = plateToBeEdited.parts
			.filter(Boolean)
			.map((stringifiedPart) => JSON.parse(stringifiedPart))
			.filter(Boolean)
			.map((part) => part.properties.PartConfig["originalJson"]["id"])
			.filter(Boolean);
		const partSlicerConfigurationIds = plateToBeEdited.parts
			.filter(Boolean)
			.map((stringifiedPart) => JSON.parse(stringifiedPart))
			.filter(Boolean)
			.map((part) => part.properties.SlicerConfig["originalJson"]["id"])
			.filter(Boolean);
		const plateConfigurationIds = [
			plateToBeEdited.machineId,
			plateToBeEdited.millConfigId,
			plateToBeEdited.materialConfigId,
			plateToBeEdited.recipeId,
		];

		const ids = {
			partIds,
			parts,
			partConfigurationIds,
			partSlicerConfigurationIds,
			plateConfigurationIds,
			plateId,
		};

		props.onNavigateToPlateReviewPage(ids, props.history, true);
	};

	const createdDate = props.displayPlate.createdDate;
	const formatedDate =
		navigator.userAgent.indexOf("Mac OS X") != -1
			? createdDate.substring(3, 5) +
			"/" +
			createdDate.slice(0, 3) +
			createdDate.substring(5, createdDate.length)
			: props.displayPlate.createdDate;

			const onToggleSelectPlate = (id) => {
				const { displayPlate, creator, onSelectPlate, onUnselectPlate } = props;		  
				if (displayPlate?.creator !== creator && displayPlate?.creator !== null && displayPlate?.creator !== undefined && displayPlate?.creator !== "") {
				  NotificationManager.error('You lack the authorization to delete plates created by other users.');
				} else {
				  setIsPlateSelected(prevIsPlateSelected => !prevIsPlateSelected);
				  const isSelected = !isPlateSelected;
				  isSelected ? onSelectPlate(id) : onUnselectPlate(id);
				}
			  }
					  
	return (
		<>
			<div
				className={`position-relative display-card is-selected-${props.displayPlate.isHighlighted}`}
				id={`plate-${props.displayPlate.index}`}
				key={`${props.displayPlate.index}`}
				onClick={() => {
					props.displayPlate.isSelected
						? props.onSelectPlate(props.displayPlate.id)
						: onNavigateToPlateReviewPage(props.displayPlate.id);
				}}
			>
					<div className='display-card-select-action position-absolute plate-review-check-box'>
						<div className='options right-justify'>
							<>
								{/* <div className='check option'>
									<img
										className={`icon ${isPlateSelected ? 'check' :'uncheck'}`}
										alt=''
										style={{ margin: '0px', padding: '4px' }}
										onClick={(e) => { e.stopPropagation(); onToggleSelectPlate(props.displayPlate.id) }}
									/>
								</div> */}
							</>
						</div>
					</div>
				<div>
					<div className="thumbnail-preview">
						<img
							src="/plate-thumbnail.svg"
							className="default-thumb-image"
							alt="PlateImage"
						/>
					</div>
					<div className="display-card-row">
						<div className="display-card-header">
							<label className="text-ellipsis-second-line">
								{props.displayPlate.name}
							</label>
						</div>
					</div>
					<div className="display-card-row">
						<div className="display-card-sub-header">
							<div className="details">
								<label>Created Date:</label>
								<span>
									{props.displayPlate.createdDate
										? moment(new Date(formatedDate)).format(
											"MMMM Do YYYY, HH:mm:ss A"
										)
										: "Not available"}
								</span>
							</div>
							<div className="details">
								<label>Parts Plated:</label>
								<span>{props.displayPlate.noOfParts}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const DisplayPlateCardView = withRouter(
	connect(Props, Actions)(DisplayReviewPlateCardViewMethod)
);

export default DisplayPlateCardView;
