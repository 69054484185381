const ACTION_TYPES = {
  COMMON: {
    FOLDER: {
      VIEW: {
        ACTION: "ACTION_TYPES.COMMON.FOLDER.VIEW.ACTION",
      },
    },
    LIST: {
      VIEW: {
        ACTION: "ACTION_TYPES.COMMON.LIST.VIEW.ACTION",
      },
    },
    OWNEDBY: {
      PARTS: {
        ACTION: "ACTION_TYPES.COMMON.OWNEDBY.PARTS.ACTION",
      },
      PLATES: {
        ACTION: "ACTION_TYPES.COMMON.OWNEDBY.PLATES.ACTION",
      },
      BUILDS: {
        ACTION: "ACTION_TYPES.COMMON.OWNEDBY.BUILDS.ACTION",
      },
      ALL: {
        ACTION: "ACTION_TYPES.COMMON.OWNEDBY.ALL.ACTION",
      },
    },
    SENT_TO_PRINTER:{
      ENABLE:{
        ACTION: "ACTION_TYPES.COMMON.SENT_TO_PRINTER.ENABLE.ACTION",
      }
    }
  },

  FOLDERS: {
    ARCHIVE: {
      INIT: "ACTION_TYPES.FOLDERS.ARCHIVE.INIT",
      SUCCESS: "ACTION_TYPES.FOLDERS.ARCHIVE.SUCCESS",
      FAIL: "ACTION_TYPES.FOLDERS.ARCHIVE.FAIL",
      DONE: "ACTION_TYPES.FOLDERS.ARCHIVE.DONE",
    },
    DELETE: {
      INIT: "ACTION_TYPES.FOLDERS.DELETE.INIT",
      SUCCESS: "ACTION_TYPES.FOLDERS.DELETE.SUCCESS",
      FAIL: "ACTION_TYPES.FOLDERS.DELETE.FAIL",
      DONE: "ACTION_TYPES.FOLDERS.DELETE.DONE",
    },
    UNARCHIVE: {
      INIT: "ACTION_TYPES.FOLDERS.UNARCHIVE.INIT",
      SUCCESS: "ACTION_TYPES.FOLDERS.UNARCHIVE.SUCCESS",
      FAIL: "ACTION_TYPES.FOLDERS.UNARCHIVE.FAIL",
      DONE: "ACTION_TYPES.FOLDERS.UNARCHIVE.DONE",
    },
    ACCORDIONS: {
      COLLAPSE: {
        ACTION: "ACTION_TYPES.FOLDERS.ACCORDIONS.COLLAPSE.ACTION",
      },
      EXPAND: {
        ACTION: "ACTION_TYPES.FOLDERS.ACCORDIONS.EXPAND.ACTION",
      },
      RESET: {
        ACTION: "ACTION_TYPES.FOLDERS.ACCORDIONS.RESET.ACTION",
      },
    },
  },

  FOLDER: {
    ARCHIVE: {
      INIT: "ACTION_TYPES.FOLDER.ARCHIVE.INIT",
      SUCCESS: "ACTION_TYPES.FOLDER.ARCHIVE.SUCCESS",
      FAIL: "ACTION_TYPES.FOLDER.ARCHIVE.FAIL",
      DONE: "ACTION_TYPES.FOLDER.ARCHIVE.DONE",
    },
    DELETE: {
      INIT: "ACTION_TYPES.FOLDER.DELETE.INIT",
      SUCCESS: "ACTION_TYPES.FOLDER.DELETE.SUCCESS",
      FAIL: "ACTION_TYPES.FOLDER.DELETE.FAIL",
      DONE: "ACTION_TYPES.FOLDER.DELETE.DONE",
    },
    HIGHLIGHT: {
      ACTION: "ACTION_TYPES.FOLDER.HIGHLIGHT.ACTION",
    },
    RENAME: {
      OPEN_DIALOG: "ACTION_TYPES.FOLDER.RENAME.OPEN_DIALOG",
      NAME_CHANGE: "ACTION_TYPES.FOLDER.RENAME.NAME_CHANGE",
      INIT: "ACTION_TYPES.FOLDER.RENAME.INIT",
      SUCCESS: "ACTION_TYPES.FOLDER.RENAME.SUCCESS",
      FAIL: "ACTION_TYPES.FOLDER.RENAME.FAIL",
      DONE: "ACTION_TYPES.FOLDER.RENAME.DONE",
      CLOSE_DIALOG: "ACTION_TYPES.FOLDER.RENAME.CLOSE_DIALOG",
    },
    UNARCHIVE: {
      INIT: "ACTION_TYPES.FOLDER.UNARCHIVE.INIT",
      SUCCESS: "ACTION_TYPES.FOLDER.UNARCHIVE.SUCCESS",
      FAIL: "ACTION_TYPES.FOLDER.UNARCHIVE.FAIL",
      DONE: "ACTION_TYPES.FOLDER.UNARCHIVE.DONE",
    },
  },

  HOME: {
    BATCH_DELETE: {
      ENABLE_BATCH_DELETE: "ACTION_TYPES.BATCH_DELETE.ENABLE_BATCH_DELETE",
    },
    DOWNLOAD: {
      PART: {
        REQUEST: "ACTION_TYPES.HOME.DOWNLOAD.PART.REQUEST",
        START: "ACTION_TYPES.HOME.DOWNLOAD.PART.START",
        IN_PROGRESS: "ACTION_TYPES.HOME.DOWNLOAD.PART.IN_PROGRESS",
        SUCCESS: "ACTION_TYPES.HOME.DOWNLOAD.PART.SUCCESS",
        FAIL: "ACTION_TYPES.HOME.DOWNLOAD.PART.FAIL",
        DONE: "ACTION_TYPES.HOME.DOWNLOAD.PART.DONE",
      },
      GCODE: {
        REQUEST: "ACTION_TYPES.HOME.DOWNLOAD.GCODE.REQUEST",
        START: "ACTION_TYPES.HOME.DOWNLOAD.GCODE.START",
        IN_PROGRESS: "ACTION_TYPES.HOME.DOWNLOAD.GCODE.IN_PROGRESS",
        SUCCESS: "ACTION_TYPES.HOME.DOWNLOAD.GCODE.SUCCESS",
        FAIL: "ACTION_TYPES.HOME.DOWNLOAD.GCODE.FAIL",
        DONE: "ACTION_TYPES.HOME.DOWNLOAD.GCODE.DONE",
      },
      BUNDLE: {
        REQUEST: "ACTION_TYPES.HOME.DOWNLOAD.BUNDLE.REQUEST",
        START: "ACTION_TYPES.HOME.DOWNLOAD.BUNDLE.START",
        IN_PROGRESS: "ACTION_TYPES.HOME.DOWNLOAD.BUNDLE.IN_PROGRESS",
        SUCCESS: "ACTION_TYPES.HOME.DOWNLOAD.BUNDLE.SUCCESS",
        FAIL: "ACTION_TYPES.HOME.DOWNLOAD.BUNDLE.FAIL",
        DONE: "ACTION_TYPES.HOME.DOWNLOAD.BUNDLE.DONE",
      },
      LOG: {
        REQUEST: "ACTION_TYPES.HOME.DOWNLOAD.LOG.REQUEST",
        START: "ACTION_TYPES.HOME.DOWNLOAD.LOG.START",
        IN_PROGRESS: "ACTION_TYPES.HOME.DOWNLOAD.LOG.IN_PROGRESS",
        SUCCESS: "ACTION_TYPES.HOME.DOWNLOAD.LOG.SUCCESS",
        FAIL: "ACTION_TYPES.HOME.DOWNLOAD.LOG.FAIL",
        DONE: "ACTION_TYPES.HOME.DOWNLOAD.LOG.DONE",
      },
      PARTS: {
        REQUEST: "ACTION_TYPES.HOME.DOWNLOAD.PARTS.REQUEST",
        DONE: "ACTION_TYPES.HOME.DOWNLOAD.PARTS.DONE",
      },
      GCODES: {
        REQUEST: "ACTION_TYPES.HOME.DOWNLOAD.GCODES.REQUEST",
        DONE: "ACTION_TYPES.HOME.DOWNLOAD.GCODES.DONE",
      },
      BUNDLES: {
        REQUEST: "ACTION_TYPES.HOME.DOWNLOAD.BUNDLES.REQUEST",
        DONE: "ACTION_TYPES.HOME.DOWNLOAD.BUNDLES.DONE",
      },
      LOGS: {
        REQUEST: "ACTION_TYPES.HOME.DOWNLOAD.LOGS.REQUEST",
        DONE: "ACTION_TYPES.HOME.DOWNLOAD.LOGS.DONE",
      },
    },
    FOLDER_FILTER: {
      ACTION: "ACTION_TYPES.HOME.FOLDER_FILTER.ACTION",
    },
    LOAD: {
      CURRENT_USER_INFO: {
        INIT: "ACTION_TYPES.HOME.LOAD_CURRENT_USER_INFO.INIT",
        DONE: "ACTION_TYPES.HOME.LOAD_CURRENT_USER_INFO.DONE",
      },
      FOLDERS: {
        STARTED: "ACTION_TYPES.HOME.LOAD.FOLDERS.STARTED",
        IN_PROGRESS: "ACTION_TYPES.HOME.LOAD.FOLDERS.IN_PROGRESS",
        COMPLETED: "ACTION_TYPES.HOME.LOAD.FOLDERS.COMPLETED",
      },
      TAGS: {
        STARTED: "ACTION_TYPES.HOME.LOAD.TAGS.STARTED",
        IN_PROGRESS: "ACTION_TYPES.HOME.LOAD.TAGS.IN_PROGRESS",
        COMPLETED: "ACTION_TYPES.HOME.LOAD.TAGS.COMPLETED",
      },
      PARTS: {
        STARTED: "ACTION_TYPES.HOME.LOAD.PARTS.STARTED",
        IN_PROGRESS: "ACTION_TYPES.HOME.LOAD.PARTS.IN_PROGRESS",
        COMPLETED: "ACTION_TYPES.HOME.LOAD.PARTS.COMPLETED",
        UPDATED: "ACTION_TYPES.HOME.LOAD.PARTS.UPDATED",
        CREATED: "ACTION_TYPES.HOME.LOAD.PARTS.CREATED",
        DELETED: "ACTION_TYPES.HOME.LOAD.PARTS.DELETED",
      },
      PLATES: {
        STARTED: "ACTION_TYPES.HOME.LOAD.PLATES.STARTED",
        IN_PROGRESS: "ACTION_TYPES.HOME.LOAD.PLATES.IN_PROGRESS",
        COMPLETED: "ACTION_TYPES.HOME.LOAD.PLATES.COMPLETED",
        UPDATED: "ACTION_TYPES.HOME.LOAD.PLATES.UPDATED",
        CREATED: "ACTION_TYPES.HOME.LOAD.PLATES.CREATED",
        DELETED: "ACTION_TYPES.HOME.LOAD.PLATES.DELETED",
      },
      BUILDS: {
        STARTED: "ACTION_TYPES.HOME.LOAD.BUILDS.STARTED",
        IN_PROGRESS: "ACTION_TYPES.HOME.LOAD.BUILDS.IN_PROGRESS",
        COMPLETED: "ACTION_TYPES.HOME.LOAD.BUILDS.COMPLETED",
        UPDATED: "ACTION_TYPES.HOME.LOAD.BUILDS.UPDATED",
        CREATED: "ACTION_TYPES.HOME.LOAD.BUILDS.CREATED",
        DELETED: "ACTION_TYPES.HOME.LOAD.BUILDS.DELETED",
      },
      CONFIGURATIONS: {
        STARTED: "ACTION_TYPES.HOME.LOAD.CONFIGURATIONS.STARTED",
        IN_PROGRESS: "ACTION_TYPES.HOME.LOAD.CONFIGURATIONS.IN_PROGRESS",
        COMPLETED: "ACTION_TYPES.HOME.LOAD.CONFIGURATIONS.COMPLETED",
      },
    },
    NAVIGATE: {
      PAGES: {
        BUILD_REVIEW: {
          INIT: "ACTION_TYPES.HOME.NAVIGATE.PAGES.BUILD_REVIEW.INIT",
          DONE: "ACTION_TYPES.HOME.NAVIGATE.PAGES.BUILD_REVIEW.DONE",
        },
        HOME: {
          INIT: "ACTION_TYPES.HOME.NAVIGATE.PAGES.HOME.INIT",
          DONE: "ACTION_TYPES.HOME.NAVIGATE.PAGES.HOME.DONE",
        },
        PLATES: {
          INIT: "ACTION_TYPES.HOME.NAVIGATE.PAGES.PLATES.INIT",
          DONE: "ACTION_TYPES.HOME.NAVIGATE.PAGES.PLATES.DONE",
        },
        PART_REVIEW: {
          INIT: "ACTION_TYPES.HOME.NAVIGATE.PAGES.PART_REVIEW.INIT",
          DONE: "ACTION_TYPES.HOME.NAVIGATE.PAGES.PART_REVIEW.DONE",
        },
        PLATE_REVIEW: {
          INIT: "ACTION_TYPES.HOME.NAVIGATE.PAGES.PLATE_REVIEW.INIT",
          DONE: "ACTION_TYPES.HOME.NAVIGATE.PAGES.PLATE_REVIEW.DONE",
        },
        REVIEW: {
          INIT: "ACTION_TYPES.HOME.NAVIGATE.PAGES.REVIEW.INIT",
          DONE: "ACTION_TYPES.HOME.NAVIGATE.PAGES.REVIEW.DONE",
        },

        BUILD_PREVIEW: {
          INIT: "ACTION_TYPES.HOME.NAVIGATE.PAGES.BUILD_PREVIEW.INIT",
          DONE: "ACTION_TYPES.HOME.NAVIGATE.PAGES.BUILD_PREVIEW.DONE",
        },
      },
    },
    NEW: {
      FOLDER: {
        OPEN_DIALOG: "ACTION_TYPES.HOME.NEW.FOLDER.OPEN_DIALOG",
        NAME_CHANGE: "ACTION_TYPES.HOME.NEW.FOLDER.NAME_CHANGE",
        FAIL: "ACTION_TYPES.HOME.NEW.FOLDER.FAIL",
        SUCCESS: "ACTION_TYPES.HOME.NEW.FOLDER.SUCCESS",
        CLOSE_DIALOG: "ACTION_TYPES.HOME.NEW.FOLDER.CLOSE_DIALOG",
      },
      TAG: {
        OPEN_DIALOG: "ACTION_TYPES.HOME.NEW.TAG.OPEN_DIALOG",
        NAME_CHANGE: "ACTION_TYPES.HOME.NEW.TAG.NAME_CHANGE",
        FAIL: "ACTION_TYPES.HOME.NEW.TAG.FAIL",
        SUCCESS: "ACTION_TYPES.HOME.NEW.TAG.SUCCESS",
        CLOSE_DIALOG: "ACTION_TYPES.HOME.NEW.TAG.CLOSE_DIALOG",
      },
    },
    TAB: {
      CHANGE: "ACTION_TYPES.HOME.TAB.CHANGE",
    },
    FOLDER: {
      UNSELECT: {
        ACTION: "ACTION_TYPES.HOME.FOLDER.UNSELECT.ACTION",
      },
    },
    FOLDERS: {
      UNSELECT: {
        ACTION: "ACTION_TYPES.HOME.FOLDERS.UNSELECT.ACTION",
      },
    },
  },
  HELP: {
    TAB: {
      CHANGE: "ACTION_TYPES.HELP.TAB.CHANGE",
    },
  },

  PLATES: {
    ARCHIVE: {
      INIT: "ACTION_TYPES.PLATES.ARCHIVE.INIT",
      SUCCESS: "ACTION_TYPES.PLATES.ARCHIVE.SUCCESS",
      FAIL: "ACTION_TYPES.PLATES.ARCHIVE.FAIL",
      DONE: "ACTION_TYPES.PLATES.ARCHIVE.DONE",
    },
    DELETE: {
      INIT: "ACTION_TYPES.PLATES.DELETE.INIT",
      SUCCESS: "ACTION_TYPES.PLATES.DELETE.SUCCESS",
      FAIL: "ACTION_TYPES.PLATES.DELETE.FAIL",
      DONE: "ACTION_TYPES.PLATES.DELETE.DONE",
    },
    HIDE: {
      ALL: {
        ACTION: "ACTION_TYPES.PLATES.HIDE.ALL.ACTION",
      },
      ARCHIVED: {
        ACTION: "ACTION_TYPES.PLATES.HIDE.ARCHIVED.ACTION",
      },
      RECENT: {
        ACTION: "ACTION_TYPES.PLATES.HIDE.RECENT.ACTION",
      },
    },
    MOVE: {
      INIT: "ACTION_TYPES.PLATES.MOVE.INIT",
      SUCCESS: "ACTION_TYPES.PLATES.MOVE.SUCCESS",
      FAIL: "ACTION_TYPES.PLATES.MOVE.FAIL",
      DONE: "ACTION_TYPES.PLATES.MOVE.DONE",
    },
    SEARCH: {
      ACTION: "ACTION_TYPES.PLATES.SEARCH.ACTION",
    },
    SELECT: {
      PART: {
        CONFIGURATION: {
          ACTION: "ACTION_TYPES.PLATES.SELECT.PART.CONFIGURATION.ACTION",
        },
      },
      PLATE: {
        CONFIGURATION: {
          ACTION: "ACTION_TYPES.PLATES.SELECT.PLATE.CONFIGURATION.ACTION",
        },
      },
    },
    SHOW: {
      ALL: {
        ACTION: "ACTION_TYPES.PLATES.SHOW.ALL.ACTION",
      },
      ARCHIVED: {
        ACTION: "ACTION_TYPES.PLATES.SHOW.ARCHIVED.ACTION",
      },
      RECENT: {
        ACTION: "ACTION_TYPES.PLATES.SHOW.RECENT.ACTION",
      },
    },
    SORT: {
      ASC: {
        ACTION: "ACTION_TYPES.PLATES.SORT.ASC.ACTION",
      },
      DESC: {
        ACTION: "ACTION_TYPES.PLATES.SORT.DESC.ACTION",
      },
    },
    TAB: {
      CHANGE: "ACTION_TYPES.PLATES.TAB.CHANGE",
    },
    UNARCHIVE: {
      INIT: "ACTION_TYPES.PLATES.UNARCHIVE.INIT",
      SUCCESS: "ACTION_TYPES.PLATES.UNARCHIVE.SUCCESS",
      FAIL: "ACTION_TYPES.PLATES.UNARCHIVE.FAIL",
      DONE: "ACTION_TYPES.PLATES.UNARCHIVE.DONE",
    },
    UNSELECT: {
      ACTION: "ACTION_TYPES.PLATES.UNSELECT.ACTION",
    },
  },

  PLATE: {
    ARCHIVE: {
      INIT: "ACTION_TYPES.PLATE.ARCHIVE.INIT",
      SUCCESS: "ACTION_TYPES.PLATE.ARCHIVE.SUCCESS",
      FAIL: "ACTION_TYPES.PLATE.ARCHIVE.FAIL",
      DONE: "ACTION_TYPES.PLATE.ARCHIVE.DONE",
    },
    COPY: {
      OPEN_DIALOG: "ACTION_TYPES.PLATE.COPY.OPEN_DIALOG",
      NAME_CHANGE: "ACTION_TYPES.PLATE.COPY.NAME_CHANGE",
      INIT: "ACTION_TYPES.PART.PLATE.INIT",
      SUCCESS: "ACTION_TYPES.PLATE.COPY.SUCCESS",
      FAIL: "ACTION_TYPES.PLATE.COPY.FAIL",
      DONE: "ACTION_TYPES.PLATE.COPY.DONE",
      CLOSE_DIALOG: "ACTION_TYPES.PLATE.COPY.CLOSE_DIALOG",
    },
    DELETE: {
      INIT: "ACTION_TYPES.PLATE.DELETE.INIT",
      SUCCESS: "ACTION_TYPES.PLATE.DELETE.SUCCESS",
      FAIL: "ACTION_TYPES.PLATE.DELETE.FAIL",
      DONE: "ACTION_TYPES.PLATE.DELETE.DONE",
    },
    HIGHLIGHT: {
      ACTION: "ACTION_TYPES.PLATE.HIGHLIGHT.ACTION",
    },
    HIGHLIGHT_PLATE: {
      ACTION: "ACTION_TYPES.PLATE.HIGHLIGHT_PLATE.ACTION",
    },
    MOVE: {
      OPEN_DIALOG: "ACTION_TYPES.PLATE.MOVE.OPEN_DIALOG",
      NAME_CHANGE: "ACTION_TYPES.PLATE.MOVE.NAME_CHANGE",
      INIT: "ACTION_TYPES.PLATE.MOVE.INIT",
      SUCCESS: "ACTION_TYPES.PLATE.MOVE.SUCCESS",
      FAIL: "ACTION_TYPES.PLATE.MOVE.FAIL",
      DONE: "ACTION_TYPES.PLATE.MOVE.DONE",
      CLOSE_DIALOG: "ACTION_TYPES.PLATE.MOVE.CLOSE_DIALOG",
    },
    ADD: {
      OPEN_DIALOG: "ACTION_TYPES.PLATE.ADD.OPEN_DIALOG",
      NAME_CHANGE: "ACTION_TYPES.PLATE.ADD.NAME_CHANGE",
      FAIL: "ACTION_TYPES.PLATE.ADD.FAIL",
      SUCCESS: "ACTION_TYPES.PLATE.ADD.SUCCESS",
      CLOSE_DIALOG: "ACTION_TYPES.PLATE.ADD.CLOSE_DIALOG",
    },
    RENAME: {
      OPEN_DIALOG: "ACTION_TYPES.PLATE.RENAME.OPEN_DIALOG",
      NAME_CHANGE: "ACTION_TYPES.PLATE.RENAME.NAME_CHANGE",
      INIT: "ACTION_TYPES.PLATE.RENAME.INIT",
      SUCCESS: "ACTION_TYPES.PLATE.RENAME.SUCCESS",
      FAIL: "ACTION_TYPES.PLATE.RENAME.FAIL",
      DONE: "ACTION_TYPES.PLATE.RENAME.DONE",
      CLOSE_DIALOG: "ACTION_TYPES.PLATE.RENAME.CLOSE_DIALOG",
    },
    UNARCHIVE: {
      INIT: "ACTION_TYPES.PLATE.UNARCHIVE.INIT",
      SUCCESS: "ACTION_TYPES.PLATE.UNARCHIVE.SUCCESS",
      FAIL: "ACTION_TYPES.PLATE.UNARCHIVE.FAIL",
      DONE: "ACTION_TYPES.PLATE.UNARCHIVE.DONE",
    },
    UPDATE: {
      INIT: "ACTION_TYPES.PLATE.UPDATE.INIT",
      SUCCESS: "ACTION_TYPES.PLATE.UPDATE.SUCCESS",
      FAIL: "ACTION_TYPES.PLATE.UPDATE.FAIL",
      DONE: "ACTION_TYPES.PLATE.UPDATE.DONE",
    },
    SELECT: {
      ACTION: "ACTION_TYPES.PLATE.SELECT.ACTION",
    },
    UNSELECT: {
      ACTION: "ACTION_TYPES.PLATE.UNSELECT.ACTION",
    },
  },

  PARTS: {
    PARTS_REPETITION : {
      ACTION: 'ACTION_TYPES.PARTS.PARTS_REPETITION.ACTION'
    },
    ADD_TO_ADDED_PARTS: {
      ACTION: "ACTION_TYPES.PARTS.ADD_TO_ADDED_PARTS.ACTION",
    },
    ARCHIVE: {
      INIT: "ACTION_TYPES.PARTS.ARCHIVE.INIT",
      SUCCESS: "ACTION_TYPES.PARTS.ARCHIVE.SUCCESS",
      FAIL: "ACTION_TYPES.PARTS.ARCHIVE.FAIL",
      DONE: "ACTION_TYPES.PARTS.ARCHIVE.DONE",
    },
    CLEAR_ADDED_PARTS: {
      ACTION: "ACTION_TYPES.PARTS.CLEAR_ADDED_PARTS.ACTION",
    },
    DELETE: {
      INIT: "ACTION_TYPES.PARTS.DELETE.INIT",
      SUCCESS: "ACTION_TYPES.PARTS.DELETE.SUCCESS",
      FAIL: "ACTION_TYPES.PARTS.DELETE.FAIL",
      DONE: "ACTION_TYPES.PARTS.DELETE.DONE",
    },
    HIDE: {
      SELECTED: {
        ACTION: "ACTION_TYPES.PARTS.HIDE.SELECTED.ACTION",
      },
      ALL: {
        ACTION: "ACTION_TYPES.PARTS.HIDE.ALL.ACTION",
      },
      ARCHIVED: {
        ACTION: "ACTION_TYPES.PARTS.HIDE.ARCHIVED.ACTION",
      },
      RECENT: {
        ACTION: "ACTION_TYPES.PARTS.HIDE.RECENT.ACTION",
      },
    },
    MOVE: {
      INIT: "ACTION_TYPES.PARTS.MOVE.INIT",
      SUCCESS: "ACTION_TYPES.PARTS.MOVE.SUCCESS",
      FAIL: "ACTION_TYPES.PARTS.MOVE.FAIL",
      DONE: "ACTION_TYPES.PARTS.MOVE.DONE",
    },
    SEARCH: {
      ACTION: "ACTION_TYPES.PARTS.SEARCH.ACTION",
    },
    SELECT_ON_ADDED_PARTS: {
      ACTION: "ACTION_TYPES.PARTS.SELECT_ON_ADDED_PARTS.ACTION",
    },
    SHOW: {
      SELECTED: {
        ACTION: "ACTION_TYPES.PARTS.SHOW.SELECTED.ACTION",
      },
      ALL: {
        ACTION: "ACTION_TYPES.PARTS.SHOW.ALL.ACTION",
      },
      ARCHIVED: {
        ACTION: "ACTION_TYPES.PARTS.SHOW.ARCHIVED.ACTION",
      },
      RECENT: {
        ACTION: "ACTION_TYPES.PARTS.SHOW.RECENT.ACTION",
      },
    },
    SORT: {
      ASC: {
        ACTION: "ACTION_TYPES.PARTS.SORT.ASC.ACTION",
      },
      DESC: {
        ACTION: "ACTION_TYPES.PARTS.SORT.DESC.ACTION",
      },
    },
    UNARCHIVE: {
      INIT: "ACTION_TYPES.PARTS.UNARCHIVE.INIT",
      SUCCESS: "ACTION_TYPES.PARTS.UNARCHIVE.SUCCESS",
      FAIL: "ACTION_TYPES.PARTS.UNARCHIVE.FAIL",
      DONE: "ACTION_TYPES.PARTS.UNARCHIVE.DONE",
    },
    UNSELECT: {
      ACTION: "ACTION_TYPES.PARTS.UNSELECT.ACTION",
    },
    UNSELECT_ON_ADDED_PARTS: {
      ACTION: "ACTION_TYPES.PARTS.UNSELECT_ON_ADDED_PARTS.ACTION",
    },
  },

  PART: {
    ADD: {
      OPEN_DIALOG: "ACTION_TYPES.PART.ADD.OPEN_DIALOG",
      FILE_CHANGE: "ACTION_TYPES.PART.ADD.FILE_CHANGE",
      NAME_CHANGE: "ACTION_TYPES.PART.ADD.NAME_CHANGE",
      FAIL: "ACTION_TYPES.PART.ADD.FAIL",
      SUCCESS: "ACTION_TYPES.PART.ADD.SUCCESS",
      STATUS: "ACTION_TYPES.PART.ADD.STATUS",
      PROGRESS: "ACTION_TYPES.PART.ADD.PROGRESS",
      CLOSE_DIALOG: "ACTION_TYPES.PART.ADD.CLOSE_DIALOG",
    },
    PARTS_REPETITION: {
      ACTION: "ACTION_TYPES.PART.PARTS_REPETITION.ACTION",
    },
    ADD_TO_ADDED_PARTS: {
      ACTION: "ACTION_TYPES.PART.ADD_TO_ADDED_PARTS.ACTION",
    },
    ADD_TO_REVIEW_PAGE: {
      ACTION: "ACTION_TYPES.PART.ADD_TO_REVIEW_PAGE.ACTION",
    },
    ARCHIVE: {
      INIT: "ACTION_TYPES.PART.ARCHIVE.INIT",
      SUCCESS: "ACTION_TYPES.PART.ARCHIVE.SUCCESS",
      FAIL: "ACTION_TYPES.PART.ARCHIVE.FAIL",
      DONE: "ACTION_TYPES.PART.ARCHIVE.DONE",
    },
    COPY: {
      OPEN_DIALOG: "ACTION_TYPES.PART.COPY.OPEN_DIALOG",
      NAME_CHANGE: "ACTION_TYPES.PART.COPY.NAME_CHANGE",
      INIT: "ACTION_TYPES.PART.COPY.INIT",
      SUCCESS: "ACTION_TYPES.PART.COPY.SUCCESS",
      FAIL: "ACTION_TYPES.PART.COPY.FAIL",
      DONE: "ACTION_TYPES.PART.COPY.DONE",
      CLOSE_DIALOG: "ACTION_TYPES.PART.COPY.CLOSE_DIALOG",
    },
    DELETE: {
      INIT: "ACTION_TYPES.PART.DELETE.INIT",
      SUCCESS: "ACTION_TYPES.PART.DELETE.SUCCESS",
      FAIL: "ACTION_TYPES.PART.DELETE.FAIL",
      DONE: "ACTION_TYPES.PART.DELETE.DONE",
    },
    HIGHLIGHT: {
      ACTION: "ACTION_TYPES.PART.HIGHLIGHT.ACTION",
    },
    MOVE: {
      OPEN_DIALOG: "ACTION_TYPES.PART.MOVE.OPEN_DIALOG",
      NAME_CHANGE: "ACTION_TYPES.PART.MOVE.NAME_CHANGE",
      INIT: "ACTION_TYPES.PART.MOVE.INIT",
      SUCCESS: "ACTION_TYPES.PART.MOVE.SUCCESS",
      FAIL: "ACTION_TYPES.PART.MOVE.FAIL",
      DONE: "ACTION_TYPES.PART.MOVE.DONE",
      CLOSE_DIALOG: "ACTION_TYPES.PART.MOVE.CLOSE_DIALOG",
    },
    REMOVE_FROM_ADDED_PARTS: {
      ACTION: "ACTION_TYPES.PART.REMOVE_FROM_ADDED_PARTS.ACTION",
    },
    RENAME: {
      OPEN_DIALOG: "ACTION_TYPES.PART.RENAME.OPEN_DIALOG",
      NAME_CHANGE: "ACTION_TYPES.PART.RENAME.NAME_CHANGE",
      INIT: "ACTION_TYPES.PART.RENAME.INIT",
      SUCCESS: "ACTION_TYPES.PART.RENAME.SUCCESS",
      FAIL: "ACTION_TYPES.PART.RENAME.FAIL",
      DONE: "ACTION_TYPES.PART.RENAME.DONE",
      CLOSE_DIALOG: "ACTION_TYPES.PART.RENAME.CLOSE_DIALOG",
    },
    SELECT: {
      ACTION: "ACTION_TYPES.PART.SELECT.ACTION",
    },
    SELECT_ON_ADDED_PARTS: {
      ACTION: "ACTION_TYPES.PART.SELECT_ON_ADDED_PARTS.ACTION",
    },
    UNARCHIVE: {
      INIT: "ACTION_TYPES.PART.UNARCHIVE.INIT",
      SUCCESS: "ACTION_TYPES.PART.UNARCHIVE.SUCCESS",
      FAIL: "ACTION_TYPES.PART.UNARCHIVE.FAIL",
      DONE: "ACTION_TYPES.PART.UNARCHIVE.DONE",
    },
    UNSELECT: {
      ACTION: "ACTION_TYPES.PART.UNSELECT.ACTION",
    },
    UNSELECT_ON_ADDED_PARTS: {
      ACTION: "ACTION_TYPES.PART.UNSELECT_ON_ADDED_PARTS.ACTION",
    },
    UPDATE: {
      INIT: "ACTION_TYPES.PART.UPDATE.INIT",
      SUCCESS: "ACTION_TYPES.PART.UPDATE.SUCCESS",
      FAIL: "ACTION_TYPES.PART.UPDATE.FAIL",
      DONE: "ACTION_TYPES.PART.UPDATE.DONE",
    },
  },
  DOCUMENT: {
    ADD: {
      FAIL: "ACTION_TYPES.DOCUMENT.ADD.FAIL",
      SUCCESS: "ACTION_TYPES.DOCUMENT.ADD.SUCCESS",
      STATUS: "ACTION_TYPES.DOCUMENT.ADD.STATUS",
      PROGRESS: "ACTION_TYPES.DOCUMENT.ADD.PROGRESS",
    },
  },

  BUILDS: {
    BUILD_STATUS_FILTER: {
      ACTION: "ACTION_TYPES.BUILDS.BUILD_STATUS_FILTER.ACTION",
    },
    HIDE: {
      ALL: {
        ACTION: "ACTION_TYPES.BUILDS.HIDE.ALL.ACTION",
      },
      ARCHIVED: {
        ACTION: "ACTION_TYPES.BUILDS.HIDE.ARCHIVED.ACTION",
      },
      RECENT: {
        ACTION: "ACTION_TYPES.BUILDS.HIDE.RECENT.ACTION",
      },
    },
    MOVE: {
      INIT: "ACTION_TYPES.BUILDS.MOVE.INIT",
      SUCCESS: "ACTION_TYPES.BUILDS.MOVE.SUCCESS",
      FAIL: "ACTION_TYPES.BUILDS.MOVE.FAIL",
      DONE: "ACTION_TYPES.BUILDS.MOVE.DONE",
    },
    SEARCH: {
      ACTION: "ACTION_TYPES.BUILDS.SEARCH.ACTION",
    },
    SHOW: {
      ALL: {
        ACTION: "ACTION_TYPES.BUILDS.SHOW.ALL.ACTION",
      },
      ARCHIVED: {
        ACTION: "ACTION_TYPES.BUILDS.SHOW.ARCHIVED.ACTION",
      },
      RECENT: {
        ACTION: "ACTION_TYPES.BUILDS.SHOW.RECENT.ACTION",
      },
    },
    SORT: {
      ASC: {
        ACTION: "ACTION_TYPES.BUILDS.SORT.ASC.ACTION",
      },
      DESC: {
        ACTION: "ACTION_TYPES.BUILDS.SORT.DESC.ACTION",
      },
    },
    UNSELECT: {
      ACTION: "ACTION_TYPES.BUILDS.UNSELECT.ACTION",
    },
    
  },

  BUILD: {
    CANCEL: {
      INIT: "ACTION_TYPES.BUILD.CANCEL.INIT",
      DONE: "ACTION_TYPES.BUILD.CANCEL.DONE",
    },
    DELETE: {
      ACTION: "ACTION_TYPES.BUILD.DELETE.ACTION",
      UPDATE_PLATE: "ACTION_TYPES.BUILD.DELETE.UPDATE_PLATE"
    },
    REBUILD: {
      ACTION: "ACTION_TYPES.BUILD.REBUILD.ACTION",
    },
    HIGHLIGHT: {
      ACTION: "ACTION_TYPES.BUILD.HIGHLIGHT.ACTION",
    },
    MOVE: {
      OPEN_DIALOG: "ACTION_TYPES.BUILD.MOVE.OPEN_DIALOG",
      NAME_CHANGE: "ACTION_TYPES.BUILD.MOVE.NAME_CHANGE",
      INIT: "ACTION_TYPES.BUILD.MOVE.INIT",
      SUCCESS: "ACTION_TYPES.BUILD.MOVE.SUCCESS",
      FAIL: "ACTION_TYPES.BUILD.MOVE.FAIL",
      DONE: "ACTION_TYPES.BUILD.MOVE.DONE",
      CLOSE_DIALOG: "ACTION_TYPES.BUILD.MOVE.CLOSE_DIALOG",
    },
    ADD: {
      OPEN_DIALOG: "ACTION_TYPES.BUILD.ADD.OPEN_DIALOG",
      NAME_CHANGE: "ACTION_TYPES.BUILD.ADD.NAME_CHANGE",
      FAIL: "ACTION_TYPES.BUILD.ADD.FAIL",
      SUCCESS: "ACTION_TYPES.BUILD.ADD.SUCCESS",
      CLOSE_DIALOG: "ACTION_TYPES.BUILD.ADD.CLOSE_DIALOG",
    },
    SUCCESS:{
      OPEN_DIALOG: "ACTION_TYPES.BUILD.SUCCESS.OPEN_DIALOG",
      CLOSE_DIALOG: "ACTION_TYPES.BUILD.SUCCESS.CLOSE_DIALOG",
    },

    UPDATE: {
      INIT: "ACTION_TYPES.BUILD.UPDATE.INIT",
      SUCCESS: "ACTION_TYPES.BUILD.UPDATE.SUCCESS",
      FAIL: "ACTION_TYPES.BUILD.UPDATE.FAIL",
      DONE: "ACTION_TYPES.BUILD.UPDATE.DONE",
    },
    SELECT: {
      ACTION: "ACTION_TYPES.BUILD.SELECT.ACTION",
    },
    UNSELECT: {
      ACTION: "ACTION_TYPES.BUILD.UNSELECT.ACTION",
    },
  },

  SEARCH: {
    GETSEARCHSTRING: "ACTION_TYPES.SEARCH.GETSEARCHSTRING",
    GETSEARCHCATEGORY: "ACTION_TYPES.SEARCH.GETSEARCHCATEGORY",
    PARTS_SUCCESS:"ACTION_TYPES.SEARCH.PARTS_SUCCESS",
    PLATES_SUCCESS:"ACTION_TYPES.SEARCH.PLATES_SUCCESS",
    BUILDS_SUCCESS: "ACTION_TYPES.SEARCH.BUILDS_SUCCESS",
    RESET_RESULTS:"ACTION_TYPES.SEARCH.RESET_RESULTS",
    PARTS_SEARCH_COMPLETED: "ACTION_TYPES.SEARCH.PARTS_SEARCH_COMPLETED",
    PLATES_SEARCH_COMPLETED: "ACTION_TYPES.SEARCH.PLATES_SEARCH_COMPLETED",
    BUILDS_SEARCH_COMPLETED: "ACTION_TYPES.SEARCH.BUILDS_SEARCH_COMPLETED",
    IS_SEARCH_ENABLED:"ACTION_TYPES.SEARCH.IS_SEARCH_ENABLED",
    DISPLAY_LOADER: "ACTION_TYPES.SEARCH.DISPLAY_LOADER",


    HIGHLIGHT_BUILD: {
      INIT: "ACTION_TYPES.SEARCH.HIGHLIGHT_BUILD.INIT",
      SUCCESS: "ACTION_TYPES.SEARCH.HIGHLIGHT_BUILD.SUCCESS",
      FAIL: "ACTION_TYPES.SEARCH.HIGHLIGHT_BUILD.FAIL",
      DONE: "ACTION_TYPES.SEARCH.HIGHLIGHT_BUILD.DONE",
      RESET: "ACTION_TYPES.SEARCH.HIGHLIGHT_BUILD.RESET",
    },

    HIGHLIGHT_PLATE: {
      INIT: "ACTION_TYPES.SEARCH.HIGHLIGHT_PLATE.INIT",
      SUCCESS: "ACTION_TYPES.SEARCH.HIGHLIGHT_PLATE.SUCCESS",
      FAIL: "ACTION_TYPES.SEARCH.HIGHLIGHT_PLATE.FAIL",
      DONE: "ACTION_TYPES.SEARCH.HIGHLIGHT_PLATE.DONE",
      RESET: "ACTION_TYPES.SEARCH.HIGHLIGHT_PLATE.RESET",
    },
    HIGHLIGHT_PART: {
      INIT: "ACTION_TYPES.SEARCH.HIGHLIGHT_PART.INIT",
      SUCCESS: "ACTION_TYPES.SEARCH.HIGHLIGHT_PART.SUCCESS",
      FAIL: "ACTION_TYPES.SEARCH.HIGHLIGHT_PART.FAIL",
      DONE: "ACTION_TYPES.SEARCH.HIGHLIGHT_PART.DONE",
      RESET: "ACTION_TYPES.SEARCH.HIGHLIGHT_PART.RESET",
    }
  },

  SUBSCRIPTIONS: {
    SET: "ACTION_TYPES.SUBSCRIPTIONS.SET",
    SET_BUILDS: "ACTION_TYPES.SUBSCRIPTIONS.SET_BUILDS",
    SET_PARTS: "ACTION_TYPES.SUBSCRIPTIONS.SET_PARTS",
    SET_PRINTERS: "ACTION_TYPES.SUBSCRIPTIONS.SET_PRINTERS",
  },

  CONFIGURATIONS: {
    DELETE: {
      INIT: "ACTION_TYPES.CONFIGURATIONS.DELETE.INIT",
      SUCCESS: "ACTION_TYPES.CONFIGURATIONS.DELETE.SUCCESS",
      FAIL: "ACTION_TYPES.CONFIGURATIONS.DELETE.FAIL",
      DONE: "ACTION_TYPES.CONFIGURATIONS.DELETE.DONE",
    },
    UPDATE: {
      INIT: "ACTION_TYPES.CONFIGURATIONS.UPDATE.INIT",
      SUCCESS: "ACTION_TYPES.CONFIGURATIONS.UPDATE.SUCCESS",
      FAIL: "ACTION_TYPES.CONFIGURATIONS.UPDATE.FAIL",
      DONE: "ACTION_TYPES.CONFIGURATIONS.UPDATE.DONE",
    },
  },

  CONFIGURATION: {
    ADD: {
      OPEN_DIALOG: "ACTION_TYPES.CONFIGURATION.ADD.OPEN_DIALOG",
      NAME_CHANGE: "ACTION_TYPES.CONFIGURATION.ADD.NAME_CHANGE",
      TYPE_CHANGE: "ACTION_TYPES.CONFIGURATION.ADD.TYPE_CHANGE",
      DATA_CHANGE: "ACTION_TYPES.CONFIGURATION.ADD.DATA_CHANGE",
      FAIL: "ACTION_TYPES.CONFIGURATION.ADD.FAIL",
      SUCCESS: "ACTION_TYPES.CONFIGURATION.ADD.SUCCESS",
      CLOSE_DIALOG: "ACTION_TYPES.CONFIGURATION.ADD.CLOSE_DIALOG",
    },
    COPY: {
      OPEN_DIALOG: "ACTION_TYPES.CONFIGURATION.COPY.OPEN_DIALOG",
      NAME_CHANGE: "ACTION_TYPES.CONFIGURATION.COPY.NAME_CHANGE",
      INIT: "ACTION_TYPES.CONFIGURATION.COPY.INIT",
      SUCCESS: "ACTION_TYPES.CONFIGURATION.COPY.SUCCESS",
      FAIL: "ACTION_TYPES.CONFIGURATION.COPY.FAIL",
      DONE: "ACTION_TYPES.CONFIGURATION.COPY.DONE",
      CLOSE_DIALOG: "ACTION_TYPES.CONFIGURATION.COPY.CLOSE_DIALOG",
    },
    DELETE: {
      INIT: "ACTION_TYPES.CONFIGURATION.DELETE.INIT",
      SUCCESS: "ACTION_TYPES.CONFIGURATION.DELETE.SUCCESS",
      FAIL: "ACTION_TYPES.CONFIGURATION.DELETE.FAIL",
      DONE: "ACTION_TYPES.CONFIGURATION.DELETE.DONE",
    },
    EDIT: {
      ACTION: "ACTION_TYPES.CONFIGURATION.EDIT.ACTION",
    },
    RESTORE:{
      RESTORE_INIT :"ACTION_TYPES.CONFIGURATION.RESTORE.RESTORE_INIT",
      RESTORE_DONE :"ACTION_TYPES.CONFIGURATION.RESTORE.RESTORE_DONE",
      HIGHLIGHTEDCONFIGURATIONID :"ACTION_TYPES.CONFIGURATION.RESTORE.HIGHLIGHTEDCONFIGURATIONID",
    },
    HIGHLIGHT: {
      ACTION: "ACTION_TYPES.CONFIGURATION.HIGHLIGHT.ACTION",
      TYPE: {
        ACTION: "ACTION_TYPES.CONFIGURATION.HIGHLIGHT.TYPE.ACTION",
      },
    },
    RENAME: {
      OPEN_DIALOG: "ACTION_TYPES.CONFIGURATION.RENAME.OPEN_DIALOG",
      NAME_CHANGE: "ACTION_TYPES.CONFIGURATION.RENAME.NAME_CHANGE",
      INIT: "ACTION_TYPES.CONFIGURATION.RENAME.INIT",
      SUCCESS: "ACTION_TYPES.CONFIGURATION.RENAME.SUCCESS",
      FAIL: "ACTION_TYPES.CONFIGURATION.RENAME.FAIL",
      DONE: "ACTION_TYPES.CONFIGURATION.RENAME.DONE",
      CLOSE_DIALOG: "ACTION_TYPES.CONFIGURATION.RENAME.CLOSE_DIALOG",
    },
    RESET: {
      ACTION: "ACTION_TYPES.CONFIGURATION.RESET.ACTION",
    },
    UPDATE: {
      INIT: "ACTION_TYPES.CONFIGURATION.UPDATE.INIT",
      SUCCESS: "ACTION_TYPES.CONFIGURATION.UPDATE.SUCCESS",
      FAIL: "ACTION_TYPES.CONFIGURATION.UPDATE.FAIL",
      DONE: "ACTION_TYPES.CONFIGURATION.UPDATE.DONE",
    },
    DELETEDCONFIGS: {
      SUCCESS: "ACTION_TYPES.CONFIGURATION.FETCH.SUCCESS"
    }
  },
};

export default ACTION_TYPES;
