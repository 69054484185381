import { IState } from "../../../Interfaces";

const onShowOwnedByPartsReducer = (state: IState, action) => {

    const newState = { ...state } as IState;
    newState.data.info.showOwnedByParts = !state.data.info.showOwnedByParts;
    if(!state.data.info.showOwnedByParts)  newState.data.info.showOwnedByAllCategories =false

    return newState;
};

export default onShowOwnedByPartsReducer;