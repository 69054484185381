import { IState } from "../../../Interfaces";

const onSelectBuildReducer = (state: IState, action) => {
    const buildId = action.payload as string;
    
    const newState = { ...state } as IState;
    const isBuildAvaialble = newState.data.builds.loadedBuilds.some((build) => build.id === buildId)
    const selectedBuild = newState.data.builds.loadedBuilds.filter((build) => build.id === buildId)
    if(isBuildAvaialble){
        newState.data.builds.selectedBuilds = newState.data.builds.selectedBuilds.concat(selectedBuild)
    }
    
    newState.data.builds.selectedBuildIds = newState.data.builds.selectedBuildIds.concat(buildId);
    
    return newState;
};

export default onSelectBuildReducer;