import { IPart, IState } from '../../../Interfaces';

const onCopyPartOpenDialogReducer = (state: IState, action) => {
    const partIdToBeCopied = action.payload as string;
    const partTobeCopied = !state.data.search.isSearchEnabled ? (state.data.parts.loadedParts.find(
        (p) => p.id === state.data.parts.highlightedPartId)
      ) as IPart : state.data.search.highlightedPartData as IPart
      
    const partNameToBeCopied = `${partTobeCopied.name}_duplicate` as string;

    const newState = { ...state } as IState;
    newState.data.modals.copyPartModalStatus = {
        ...newState.data.modals.copyPartModalStatus,
        inputText: partNameToBeCopied,
        isOpen: true,
        isValid: false,
        hasError: false,
        errorMessage: '',
    };
    return newState;
};

export default onCopyPartOpenDialogReducer;